import config from '@config';
import { getRecoil } from 'recoil-nexus';

import dataClient from '@api/clients/data';
import workflowClient from '@api/clients/workflow';
import { getBuiltInTemplate, listBuiltInTemplates } from '@api/services/builtInTemplate';
import { createTemplate } from '@api/services/template';
import {
  transformWorkflowAxiosRequest,
  transformWorkflowAxiosResponse,
  transformWorkflowJobs,
} from '@api/transform/workflow';

import { stageAtom } from '@store/atoms/StageState';

import defaultWorkflow from '@data/workflow/default.json';

const ApiClient = () => {
  const stage = getRecoil(stageAtom);
  workflowClient.defaults.baseURL = `${config.workflow.url}${stage}/dashboard/workflows`;
  return workflowClient;
};

const getWorkflow = async (id, includeConfig = true, transformResponse = true) => {
  const apiClient = ApiClient();
  const requestConfig = {
    params: { includeConfig },
  };
  if (transformResponse) {
    requestConfig.transformResponse = transformWorkflowAxiosResponse;
  }
  const { data } = await apiClient.get(`/${id}`, requestConfig);
  return { workflow: data?.body?.data || {} };
};

const getWorkflows = async () => {
  const apiClient = ApiClient();
  const { data } = await apiClient.get(`/?order=descending`);
  return { workflows: data?.body?.data || [] };
};

/** @type {(workflow: { name: string, configuration: any }) => Promise<string>} */
const createWorkflow = async (workflow = defaultWorkflow) => {
  const apiClient = ApiClient();

  const builtInStudioTemplates = await listBuiltInTemplates('studio');
  const builtInStudioTemplateIds = Array.isArray(workflow.configuration?.steps)
    ? Array.from(
        new Set(
          workflow.configuration.steps
            .filter((step) => step.module === 'shotstack:render-template')
            .map((step) => step.options.templateId)
            .filter((templateId) => (templateId ?? '').startsWith('/'))
        )
      )
    : [];

  const studioTemplateMap = (
    await Promise.all(
      builtInStudioTemplateIds.map(async (builtInStudioTemplateId) => {
        try {
          const builtInStudioTemplate = builtInStudioTemplates.find(
            (builtInStudioTemplate) => builtInStudioTemplate.id === builtInStudioTemplateId.replace('/', '')
          );

          if (!builtInStudioTemplate) return { builtInStudioTemplateId, templateId: null };
          const template = await createTemplate({
            name: `${builtInStudioTemplate.heading} (${workflow.name})`,
            template: builtInStudioTemplate.data,
          });

          return { builtInStudioTemplateId, templateId: template.id };
        } catch (error) {
          return { builtInStudioTemplateId, templateId: null };
        }
      })
    )
  ).filter((item) => item.templateId);

  const updatedSteps = (workflow.configuration?.steps ?? []).map((step) => {
    if (
      step.module === 'shotstack:render-template' &&
      typeof step.options.templateId === 'string' &&
      step.options.templateId.startsWith('/')
    ) {
      return {
        ...step,
        options: {
          ...step.options,
          templateId: studioTemplateMap.find((item) => item.builtInStudioTemplateId === step.options.templateId)
            ?.templateId,
        },
      };
    }

    return { ...step };
  });

  const updatedConfiguration = { ...(workflow.configuration ?? {}), steps: updatedSteps };

  const { data } = await apiClient.post('/', {
    ...workflow,
    configuration: updatedConfiguration,
  });

  const { id } = data?.body?.data || {};
  return id;
};

const updateWorkflow = async (id, workflow) => {
  const apiClient = ApiClient();
  const { data } = await apiClient.put(`/${id}`, workflow, {
    transformRequest: transformWorkflowAxiosRequest,
  });
  return data?.body?.data;
};

const deleteWorkflow = async (id) => {
  const apiClient = ApiClient();
  const { data } = await apiClient.delete(`/${id}`);
  return data?.body?.data;
};

const deleteWorkflows = async (ids) => {
  const deleteIds = typeof ids === 'string' ? [ids] : ids;
  return Promise.allSettled(deleteIds.map((id) => deleteWorkflow(id)));
};

const deleteJob = async (id, jobId) => {
  const apiClient = ApiClient();
  const { data } = await apiClient.delete(`/${id}/jobs/${jobId}`);
  return data?.body?.data;
};

const deleteJobs = async (id, jobIds) => {
  const deleteIds = typeof ids === 'string' ? [jobIds] : jobIds;
  return Promise.allSettled(deleteIds.map((jobId) => deleteJob(id, jobId)));
};

const createJob = async (id, job) => {
  const apiClient = ApiClient();
  const { data } = await apiClient.post(`/${id}/jobs`, job);
  return { jobs: data?.body?.data || [] };
};

const createJobs = async (id, jobsData) => {
  return Promise.allSettled(jobsData.map((job) => createJob(id, job)));
};

const getJobTasks = async (id, jobId) => {
  const apiClient = ApiClient();
  const { data } = await apiClient.get(`/${id}/jobs/${jobId}/tasks?order=ascending`);
  return { tasks: data?.body?.data || [] };
};

const getJobTasksPolling = async ({ id, jobId }, config) => {
  const apiClient = ApiClient();
  const url = `/${id}/jobs/${jobId}/tasks?order=ascending`;
  const { data } = await apiClient.get(url, config);
  return data?.body?.data || [];
};

const getJob = async (id, jobId) => {
  const apiClient = ApiClient();
  const { data } = await apiClient.get(`/${id}/jobs/${jobId}`);
  return { job: data?.body?.data || {} };
};

const getJobPolling = async ({ id, jobId }, config) => {
  const apiClient = ApiClient();
  const url = `/${id}/jobs/${jobId}`;
  const { data } = await apiClient.get(url, config);
  return data?.body?.data || {};
};

const getJobs = async (id) => {
  const apiClient = ApiClient();
  const { data } = await apiClient.get(`/${id}/jobs`);
  return { jobs: data?.body?.data || [] };
};

const getJobsData = async (id, filterParams) => {
  const url = `workflow/${id}/jobs`;
  const { data } = await dataClient.get(url, { params: filterParams });
  return transformWorkflowJobs(data?.body);
};

/** @type {(id: string, name: string) => Promise<string>} */
const duplicateWorkflow = async (id) => {
  const builtInWorkflowTemplate = await getBuiltInTemplate('workflow', id);
  if (builtInWorkflowTemplate) {
    const newWorkflow = {
      name: `${builtInWorkflowTemplate.heading} (Copy)`,
      configuration: builtInWorkflowTemplate.data,
    };

    return createWorkflow(newWorkflow);
  }

  const { workflow } = await getWorkflow(id, true, false);
  const { configuration: referenceConfiguration, name: referenceName } = workflow || {};

  const newWorkflow = {
    name: `${referenceName} (Copy)`,
    configuration: referenceConfiguration,
  };

  return createWorkflow(newWorkflow);
};

export default {
  get: getWorkflow,
  create: createWorkflow,
  update: updateWorkflow,
  delete: deleteWorkflows,
  duplicate: duplicateWorkflow,
  list: getWorkflows,
  jobs: {
    get: getJob,
    create: createJob,
    run: createJobs,
    delete: deleteJobs,
    list: getJobs,
    tasks: getJobTasks,
  },
  polling: {
    jobs: {
      get: getJobPolling,
      tasks: getJobTasksPolling,
    },
  },
  data: {
    jobs: {
      list: getJobsData,
    },
  },
};
