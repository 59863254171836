import { useRecoilState } from 'recoil';

import { dismissibleSelector } from '@store/atoms/DismissibleState';

function Dismissible({ id, children }) {
  const [isDismissed, setDismissed] = useRecoilState(dismissibleSelector(id));

  return (
    <div className={`dismissible mt-4 ${isDismissed ? 'dismissed' : ''}`}>
      <div className="flex justify-end">
        <button className="dismissible__toggle unstyled" onClick={() => setDismissed(id)}>
          <small>{isDismissed ? 'Help' : 'Close'}</small>
        </button>
      </div>
      {!isDismissed && children}
    </div>
  );
}

export default Dismissible;
