import config from '@config';
import { debounce, isEqual } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';

import { transformTemplate } from '@api/transform/utils/template';

import CodeEditor from '@components/code/CodeEditor';

import { templateJsonEditState, useHydrateStoreFromTemplate } from '@store/atoms/EditState';
import { useSaveTemplate } from '@store/atoms/TemplateState';
import { derivedJsonState } from '@store/selectors/EditSelectors';

function JsonEditor() {
  const [isValidJson, setIsValidJson] = useState(true);
  const [editJson, setEditJson] = useState();
  const [debugMode, setDebug] = useState(false);
  const hydrateStore = useHydrateStoreFromTemplate();
  const saveTemplate = useSaveTemplate();
  const [readyState, setReadyState] = useRecoilState(templateJsonEditState);
  const derivedJson = useRecoilValue(derivedJsonState(debugMode));

  const updateTemplateJson = async (json) => {
    if (!isValidJson) return;

    try {
      await saveTemplate({ json });
      const transformedTemplate = await transformTemplate(json);
      await hydrateStore(transformedTemplate);
    } catch (error) {
      console.error(error);
    } finally {
      setReadyState(true);
    }
  };

  const debouncedUpdate = useMemo(() => {
    const options = { maxWait: 3000, leading: false, trailing: true };
    return debounce(updateTemplateJson, 1500, options);
  }, []);

  const handleEditorUpdate = (template) => {
    setReadyState(false);

    try {
      const parsedTemplate = JSON.parse(template);
      setIsValidJson(true);
      debouncedUpdate(parsedTemplate);
    } catch (error) {
      console.error(error);
      setIsValidJson(false);
      setReadyState(true);
    }
  };

  const handleDebugToggle = () => {
    setDebug((prevDebug) => !prevDebug);
  };

  useEffect(() => {
    if (!readyState || Boolean(editJson)) {
      return;
    }
    try {
      const initialJson = JSON.parse(derivedJson);
      if (!isEqual(initialJson, editJson)) {
        setEditJson(initialJson);
      }
    } catch (error) {
      console.error(error);
    }
  }, [derivedJson]);

  return (
    <div className="json-editor" style={{ position: 'relative' }}>
      {config.isDev && (
        <div style={{ position: 'absolute', top: '5px', right: '20px', zIndex: 10 }}>
          <Form.Check type="switch" label="Debug" checked={debugMode} onChange={handleDebugToggle} />
        </div>
      )}
      {!debugMode && <CodeEditor value={editJson} onChange={handleEditorUpdate} />}
      {debugMode && <CodeEditor value={JSON.parse(derivedJson)} />}
    </div>
  );
}

export default JsonEditor;
