import { useRecoilValue } from 'recoil';

import '@css/TimelineScrub.css';

import { useTimelinePlaybackContext } from '@context/TimelinePlaybackProvider';

import { timelineScaleState } from '@store/atoms/TimelineState';

import { TIMELINE_TRACK_OFFSET } from '@constants/Timeline';

import getPixelSeconds from '@utils/getPixelSeconds';

function TimelineScrub() {
  const { playhead, timelineDuration, isPlayable, isPlaying, handlePause, setPlayhead } = useTimelinePlaybackContext();
  const timelineScale = useRecoilValue(timelineScaleState);
  const pixelSeconds = getPixelSeconds(timelineScale);

  const handlePlayheadChange = (event) => {
    const { value } = event.target;
    if (isPlaying) handlePause();
    setPlayhead(!value ? 0 : parseFloat(value));
  };

  if (!isPlayable) {
    return null;
  }

  return (
    <div
      className="scrub"
      style={{ transform: `translate(${TIMELINE_TRACK_OFFSET}px, 0px)`, width: pixelSeconds(timelineDuration) }}
    >
      <input
        step="0.0001"
        min="0"
        max={timelineDuration}
        onChange={handlePlayheadChange}
        type="range"
        value={playhead}
      />
    </div>
  );
}

export default TimelineScrub;
