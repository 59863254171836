import { atom, useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import { activeClipState, activeTrackState } from '@store/atoms/ClipState';
import { assetIdsState, clipIdsState, clipsFamily, clipsTracksFamily, trackIdsState } from '@store/atoms/EditState';
import { playheadState } from '@store/atoms/PlayheadState';
import { clipState, trackClipIdsState } from '@store/selectors/EditSelectors';
import { copyPasteClipKeyframesCallback } from '@store/studio/Keyframes';
import { templateMediaSelectorFamily } from '@store/studio/Media';

import { ASSET_TYPES_MASK } from '@constants/AssetTypes';
import { TIMELINE_SCALE_DEFAULT } from '@constants/Timeline';

import roundToPrecision from '@utils/math/roundToPrecision';
import { getSnapshot } from '@utils/recoil';
import { getClipsGroupedByIntersect, multipleIntersectingClips, singleIntersectingClip } from '@utils/timeline';
import { getClipStartTime } from '@utils/tracks';

export const timelineScaleState = atom({
  key: 'timelineScaleState',
  default: TIMELINE_SCALE_DEFAULT,
});

export const playbackMuteAtom = atom({
  key: 'playbackMuteAtom',
  default: false,
});

export const addTrackState = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return () => {
    const trackId = uuid();
    const trackIds = snapshot.getLoadable(trackIdsState).contents;
    const newTrackIds = [trackId, ...trackIds];

    set(trackIdsState, newTrackIds);

    // update each clip's trackIndex to match new trackIds
    const clipIds = snapshot.getLoadable(clipIdsState).contents;
    clipIds.forEach((clipId) => {
      const clipTrackId = snapshot.getLoadable(clipsTracksFamily(clipId)).contents.trackId;
      const clipTrackIndex = newTrackIds.findIndex((id) => id === clipTrackId);
      set(clipsTracksFamily(clipId), (currentState) => ({ ...currentState, trackIndex: clipTrackIndex }));
    });

    return trackId;
  };
};

export const addClipState = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return ({ toTrackId, clip }) => {
    const newClipId = uuid();
    const newAssetId = uuid();

    const startTime = snapshot.getLoadable(playheadState).contents;
    const clipProperties = {
      ...clip,
      id: newClipId,
      start: startTime,
      length: clip.length || 3,
      meta: {
        ...clip.meta,
        start: 'number',
        length: clip.type === 'caption' ? 'end' : 'auto',
      },
    };

    set(clipIdsState, (currentState) => [...currentState, newClipId]);
    set(assetIdsState, (currentState) => [...currentState, newAssetId]);

    const newTrackIndex = snapshot.getLoadable(trackIdsState).contents.findIndex((id) => id === toTrackId);
    const toTrackIndex = Math.max(0, newTrackIndex);

    const toTrackClipIds = snapshot.getLoadable(trackClipIdsState(toTrackId)).contents;
    const toTrackClips = toTrackClipIds.map((clipId) => {
      // Todo: try clipState
      const clipSnapshot = getSnapshot({ snapshot, id: clipId, family: clipsFamily });
      return { ...clipSnapshot };
    });
    clipProperties.start = getClipStartTime(toTrackClips, clipProperties);

    set(clipsFamily(newClipId), clipProperties);
    set(clipsTracksFamily(newClipId), { trackId: toTrackId, trackIndex: toTrackIndex });

    return clipProperties;
  };
};

export const addElementClipState = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return ({ toTrackId, clips }) => {
    let startTime = snapshot.getLoadable(playheadState).contents;

    const newClipIds = clips.map((clip) => {
      const { asset, meta, ...restClip } = clip;
      const newClipId = uuid();
      const newAssetId = uuid();

      const clipProperties = {
        ...restClip,
        id: newClipId,
        start: startTime,
        length: restClip.length || 3,
        meta: {
          ...meta,
          start: 'number',
          length: 'number',
        },
      };

      startTime += clipProperties.length;

      set(clipIdsState, (currentState) => [...currentState, newClipId]);
      set(assetIdsState, (currentState) => [...currentState, newAssetId]);

      set(clipsFamily(newClipId), clipProperties);
      set(clipsTracksFamily(newClipId), { trackId: toTrackId, trackIndex: 0 });

      return newClipId;
    });

    return { ids: newClipIds };
  };
};

const deleteTrackState = (callbackArgs) => {
  const { set, reset, snapshot } = callbackArgs;
  return (trackId) => {
    const trackClipIds = snapshot.getLoadable(trackClipIdsState(trackId)).contents;

    set(clipIdsState, (currentState) => {
      const newClipIds = currentState.filter((clipId) => !trackClipIds.includes(clipId));
      return newClipIds;
    });

    trackClipIds.forEach((clipId) => {
      reset(clipsFamily(clipId));
      reset(clipsTracksFamily(clipId));
    });

    const trackIds = snapshot.getLoadable(trackIdsState).contents;
    const trackIndex = trackIds.indexOf(trackId);
    const newTrackIds = [...trackIds.slice(0, trackIndex), ...trackIds.slice(trackIndex + 1)];

    set(trackIdsState, newTrackIds);

    const shuffledTracks = newTrackIds.slice(trackIndex, newTrackIds.length);
    shuffledTracks.forEach((shuffledTrackId) => {
      const shuffledTrackIndex = newTrackIds.indexOf(shuffledTrackId);
      const shuffledTrackClipIds = snapshot.getLoadable(trackClipIdsState(shuffledTrackId)).contents;

      shuffledTrackClipIds.forEach((shuffledTrackClipId) => {
        set(clipsTracksFamily(shuffledTrackClipId), { trackId: shuffledTrackId, trackIndex: shuffledTrackIndex });
      });
    });
  };
};

const deleteClipState = (callbackArgs) => {
  const { set, snapshot, reset } = callbackArgs;
  return (id) => {
    const activeClipId = snapshot.getLoadable(activeClipState).contents;
    const clipId = id || activeClipId;

    set(clipIdsState, (currentState) => currentState.filter((id) => id !== clipId));

    reset(clipsFamily(clipId));
    reset(clipsTracksFamily(clipId));
    reset(activeClipState);
  };
};

const deleteClipOrTrackState = (callbackArgs) => {
  const { snapshot, reset, refresh } = callbackArgs;
  return () => {
    const activeClipId = snapshot.getLoadable(activeClipState).contents;
    if (activeClipId) {
      const deleteClip = deleteClipState(callbackArgs);
      deleteClip(activeClipId);
      reset(activeClipState);
      refresh(templateMediaSelectorFamily('overlay'));
      refresh(templateMediaSelectorFamily('mask'));
      refresh(templateMediaSelectorFamily('media'));
      return;
    }

    const activeTrackId = snapshot.getLoadable(activeTrackState).contents;
    if (activeTrackId) {
      const deleteTrack = deleteTrackState(callbackArgs);
      deleteTrack(activeTrackId);
      reset(activeTrackState);
      refresh(templateMediaSelectorFamily('overlay'));
      refresh(templateMediaSelectorFamily('mask'));
      refresh(templateMediaSelectorFamily('media'));
    }
  };
};

const copyClipIdState = atom({
  key: 'copyClipIdState',
  default: null,
});

const copyClipState = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return () => {
    const activeClipId = snapshot.getLoadable(activeClipState).contents;
    set(copyClipIdState, activeClipId);
  };
};

const pasteClipState = (callbackArgs) => {
  const { snapshot } = callbackArgs;
  const addClip = addClipState(callbackArgs);
  const copyPasteClipKeyframes = copyPasteClipKeyframesCallback(callbackArgs);
  return () => {
    const copyClipId = snapshot.getLoadable(copyClipIdState).contents;
    const clip = snapshot.getLoadable(clipState(copyClipId)).contents;
    const toTrackId = snapshot.getLoadable(activeTrackState).contents;
    const newClip = addClip({ toTrackId, clip });
    copyPasteClipKeyframes(copyClipId, newClip.id);
  };
};

export const useMoveClipState = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clipId, newStartTime, toTrackId, toTrackIndex) => {
        const { trackId: fromTrackId } = snapshot.getLoadable(clipsTracksFamily(clipId)).contents;
        const originalClip = snapshot.getLoadable(clipState(clipId)).contents;

        const newClip = {
          ...originalClip,
          start: newStartTime,
          meta: { ...originalClip.meta, start: 'number', length: 'number' },
        };

        // TODO: refactor this, maybe it's own callback?
        if (ASSET_TYPES_MASK.includes(newClip['asset:type'])) {
          set(clipsTracksFamily(clipId), { trackId: toTrackId, trackIndex: toTrackIndex });
          set(clipsFamily(clipId), newClip);
          return;
        }

        // get all clips in track we're moving clip to
        const toTrackClips = snapshot
          .getLoadable(trackClipIdsState(toTrackId))
          .contents.map((id) => ({
            id,
            ...snapshot.getLoadable(clipState(id)).contents,
          }))
          .filter((f) => !ASSET_TYPES_MASK.includes(f['asset:type']) && f.id !== clipId);

        // get all clips in track we're moving clip to
        const fromTrackClips = snapshot
          .getLoadable(trackClipIdsState(fromTrackId))
          .contents.map((id) => ({
            id,
            ...snapshot.getLoadable(clipState(id)).contents,
          }))
          .filter((f) => !ASSET_TYPES_MASK.includes(f['asset:type']) && f.id !== clipId);

        // update the new clip's start time and get the gap between any overlapping clips
        let diff = 0;
        const { intersecting, complements } = getClipsGroupedByIntersect(toTrackClips, newClip);
        if (intersecting.length > 1) {
          const { newStart, space } = multipleIntersectingClips({ intersecting, newClip });
          newClip.start = roundToPrecision(newStart);
          diff = space;
        } else if (intersecting.length === 1) {
          const { newStart, space } = singleIntersectingClip({ intersecting, complements, newClip });
          newClip.start = roundToPrecision(newStart);
          diff = space || diff;
        }

        // update toTrackClips with new start
        let prevClipEnd = newClip.start + newClip.length;
        toTrackClips
          .filter((clip) => clip.start >= newClip.start)
          .forEach((clip) => {
            set(clipsFamily(clip.id), (prevState) => {
              const update = {
                start: prevState?.meta?.start === 'auto' ? prevClipEnd : roundToPrecision(prevState.start + diff),
              };
              if (prevState?.meta?.start === 'auto') {
                prevClipEnd += prevState.length;
              }
              return {
                ...prevState,
                ...update,
              };
            });
          });

        set(clipsFamily(clipId), newClip);
        if (fromTrackId !== toTrackId) {
          set(clipsTracksFamily(clipId), { trackId: toTrackId, trackIndex: toTrackIndex });

          const [nextClipToTrack] = toTrackClips.filter((clip) => clip.start > newClip.start);
          const [nextClipFromTrack] = fromTrackClips.filter((clip) => clip.start > originalClip.start);
          const clipsToUpdate = [nextClipToTrack, nextClipFromTrack];

          clipsToUpdate.filter(Boolean).forEach((clip) => {
            set(clipsFamily(clip.id), (prevState) => ({
              ...prevState,
              meta: { ...prevState.meta, start: 'number' },
            }));
          });
        }

        return newClip;
      },
    []
  );
};

export const useResetClipState = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clipId, toTrackId) => {
        const toTrackIndex = snapshot.getLoadable(trackIdsState).contents.findIndex((id) => id === toTrackId);
        set(clipsTracksFamily(clipId), { trackId: -1, trackIndex: -1 });
        // hack to trick react into re-rendering the clip
        const resetClipTimeout = setTimeout(() => {
          clearTimeout(resetClipTimeout);
          set(clipsTracksFamily(clipId), { trackId: toTrackId, trackIndex: toTrackIndex });
        }, 10);
      },
    []
  );
};

export const useMoveTrackState = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (fromIndex, toIndex) => {
        // move tracks and cache new trackIds
        const trackIds = snapshot.getLoadable(trackIdsState).contents;
        const newTrackIds = [...trackIds];
        const [movedTrack] = newTrackIds.splice(fromIndex, 1);
        newTrackIds.splice(toIndex, 0, movedTrack);
        set(trackIdsState, newTrackIds);

        // update each clip's trackIndex to match new trackIds
        const clipIds = snapshot.getLoadable(clipIdsState).contents;
        clipIds.forEach((clipId) => {
          const { trackId: clipTrackId } = snapshot.getLoadable(clipsTracksFamily(clipId)).contents;
          const clipTrackIndex = newTrackIds.findIndex((id) => id === clipTrackId);
          set(clipsTracksFamily(clipId), (currentState) => ({ ...currentState, trackIndex: clipTrackIndex }));
        });
      },
    []
  );
};

export const useAddTrackState = () => useRecoilCallback(addTrackState);
export const useAddClipState = () => useRecoilCallback(addClipState);
export const useCopyClipState = () => useRecoilCallback(copyClipState);
export const usePasteClipState = () => useRecoilCallback(pasteClipState);
export const useDeleteClipState = () => useRecoilCallback(deleteClipState);
export const useDeleteTrackState = () => useRecoilCallback(deleteTrackState);
export const useDeleteClipOrTrackState = () => useRecoilCallback(deleteClipOrTrackState);
