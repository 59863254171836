import { Scrollbar } from 'react-scrollbars-custom';

import { IconImage, IconVoiceChat } from '@assets/icons';

import ButtonElement from '@components/atoms/ButtonElement';

import { useAddClipToTimelineCallback } from '@store/studio/Media';

function PanelTextToImageDefault() {
  const addClipToTimeline = useAddClipToTimelineCallback();

  const handleAddTextToImage = (properties) => () => {
    addClipToTimeline({ data: properties, type: 'text-to-image' });
  };

  const handleAddTextToSpeech = (properties) => () => {
    addClipToTimeline({ data: properties, type: 'text-to-speech' });
  };

  return (
    <div className="workspace__panel-inner">
      <Scrollbar noScrollX style={{ width: '100%', height: '100%' }}>
        <div className="mb-4">Add AI generated assets</div>
        <div className="d-flex flex-col gap-2 mb-10">
          <ButtonElement
            variant="light"
            className="panel-button w-100"
            handleEvent={handleAddTextToSpeech({
              text: `Hey {{ name }}, ready to see your words become visuals? Let's make it happen.`,
              voice: 'Amy',
            })}
          >
            <div className="flex align-items-center gap-2 p-4 text-lg">
              <IconVoiceChat size={28} />
              <span>Text to Speech</span>
            </div>
          </ButtonElement>
          <ButtonElement
            variant="light"
            className="panel-button w-100"
            handleEvent={handleAddTextToImage({
              text: 'Create a majestic {{ animal }} riding a skateboard on a rainbow',
              width: 1280,
              height: 720,
            })}
          >
            <div className="flex align-items-center gap-2 p-4 text-lg">
              <IconImage size={28} />
              <span>Text to Image</span>
            </div>
          </ButtonElement>
        </div>
      </Scrollbar>
    </div>
  );
}

export default PanelTextToImageDefault;
