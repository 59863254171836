import { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import { useTimelinePlaybackContext } from '@context/TimelinePlaybackProvider';

import { clipsFamily } from '@store/atoms/EditState';
import { activeClipKeyframeIdSelectorFamily, useAddClipKeyframe, useRemoveClipKeyframe } from '@store/studio/Keyframes';

function PanelClipKeyframesAdd({ id }) {
  const clip = useRecoilValue(clipsFamily(id));
  const activeKeyframeId = useRecoilValue(activeClipKeyframeIdSelectorFamily(id));
  const addKeyframe = useAddClipKeyframe(id);
  const removeKeyframe = useRemoveClipKeyframe(id);
  const { playhead } = useTimelinePlaybackContext();

  const canToggleKeyframe = useMemo(
    () => playhead >= clip.start && playhead <= clip.start + clip.length,
    [clip.start, clip.length, playhead]
  );

  const handleToggleKeyframe = () => {
    if (!canToggleKeyframe) {
      return;
    }

    if (activeKeyframeId) {
      removeKeyframe();
    } else {
      addKeyframe();
    }
  };

  return (
    <Button
      variant={activeKeyframeId ? 'danger' : 'primary'}
      onClick={handleToggleKeyframe}
      disabled={!canToggleKeyframe}
    >
      {activeKeyframeId ? 'Delete' : 'Add'}
    </Button>
  );
}

export default PanelClipKeyframesAdd;
