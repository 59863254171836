import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import '@css/SettingMergeFields.css';

import { IconPlusCircle } from '@assets/icons';

import SettingMergeField from '@feature/studio/setting/SettingMergeField';

import ButtonElement from '@components/atoms/ButtonElement';

import { mergeIdsState } from '@store/atoms/EditState';
import { useAddMergeFieldState, useDeleteMergeFieldState, useUpdateMergeFieldState } from '@store/atoms/MergeState';
import { idState } from '@store/atoms/TemplateState';

function SettingMergeFields() {
  const handleAddMergeField = useAddMergeFieldState();
  const handleUpdateMergeField = useUpdateMergeFieldState();
  const handleDeleteMergeField = useDeleteMergeFieldState();
  const mergeFieldIds = useRecoilValue(mergeIdsState);
  const templateId = useRecoilValue(idState);

  const tracking = {
    name: 'Select Add Merge Field',
    properties: {
      Id: templateId,
    },
  };

  useEffect(() => {
    if (mergeFieldIds.length === 0) {
      handleAddMergeField();
    }
  }, []);

  return (
    <div className="popover-controls__inner">
      {mergeFieldIds.length > 0 && (
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: '156px' }}>
            <span className="uppercase-label">Merge Field</span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <span className="uppercase-label">Placeholder Value</span>
          </div>
        </div>
      )}
      {mergeFieldIds.map((id) => (
        <SettingMergeField
          key={id}
          id={id}
          handleUpdateMergeField={handleUpdateMergeField}
          handleDeleteMergeField={handleDeleteMergeField}
        />
      ))}
      <div className="mb-2 my-2 d-flex align-items-center justify-content-between">
        <ButtonElement handleEvent={handleAddMergeField} size="sm" tracking={tracking} variant="light">
          <IconPlusCircle size="18" className="me-2" /> Add a merge field
        </ButtonElement>
      </div>
    </div>
  );
}

export default SettingMergeFields;
