import { useCallback } from 'react';
import { Col, InputGroup, Row } from 'react-bootstrap';
import { useRecoilState } from 'recoil';

import { IconFormatColorFill, IconHalo, IconThreshold } from '@assets/icons';

import ColorPicker from '@components/atoms/ColorPicker';
import InputNumber from '@components/controls/InputNumber';

import { clipState } from '@store/selectors/EditSelectors';

function ChromaKeyFields({ id, disabled }) {
  const [clip, setClip] = useRecoilState(clipState(id));

  const handleChromaKeyColorChange = useCallback(
    (event) => {
      const { value } = event.target;
      setClip({ ['asset:chromaKey:color']: value });
    },
    [setClip]
  );

  const handleChromaKeyThresholdChange = useCallback(
    (event) => {
      const { value } = event.target;
      const threshold = value.length > 0 ? parseInt(value, 10) : '';
      setClip({ ['asset:chromaKey:threshold']: threshold });
    },
    [setClip]
  );

  const handleChromaKeyHaloChange = useCallback(
    (event) => {
      const { value } = event.target;
      const halo = value.length > 0 ? parseInt(value, 10) : '';
      setClip({ ['asset:chromaKey:halo']: halo });
    },
    [setClip]
  );

  return (
    <Row className="mb-2">
      <Col sm={12} className="d-flex align-items-center justify-content-between gap-2 mb-2">
        <InputGroup>
          <InputGroup.Text className="prefix">
            <IconFormatColorFill size={18} title="Chroma Key Color - Select the color to be made transparent" />
          </InputGroup.Text>
          <ColorPicker
            name="chromaKey.color"
            className="background"
            value={clip['asset:chromaKey:color']}
            handleEvent={handleChromaKeyColorChange}
            disabled={disabled}
          />
        </InputGroup>
      </Col>
      <Col sm={6} className="d-flex align-items-center justify-content-between gap-2">
        <InputNumber
          id="threshold"
          title="Threshold - Adjust color sensitivity for transparency"
          value={clip['asset:chromaKey:threshold']}
          placeholder="150"
          min={0}
          max={255}
          step={1}
          handleChange={handleChromaKeyThresholdChange}
          prefix={<IconThreshold size={18} />}
          disabled={disabled}
        />
      </Col>
      <Col sm={6} className="d-flex align-items-center justify-content-between gap-2">
        <InputNumber
          id="halo"
          title="Halo - Fine-tune edge smoothness around transparent areas"
          value={clip['asset:chromaKey:halo']}
          placeholder="50"
          min={0}
          max={255}
          step={1}
          handleChange={handleChromaKeyHaloChange}
          prefix={<IconHalo size={18} />}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
}

export default ChromaKeyFields;
