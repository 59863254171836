import { useCallback, useEffect, useMemo, useState } from 'react';
import reactable from 'reactablejs';

import WorkspaceSidebarPanel from '@feature/studio/workspace/WorkspaceSidebarPanel';

const resizableOptions = {
  edges: { left: false, top: false, bottom: false, right: '.sidebar__resize-handle' },
  inertia: false,
};

const ReactableSidebarComponent = reactable(WorkspaceSidebarPanel);

const MIN_WIDTH = 0;
const MAX_WIDTH_DEFAULT = 360;
const MAX_WIDTH_LARGE = 600;

function WorkspaceSidebarPanelReactable({ activeClipId, activeTab }) {
  const defaultWidth = useMemo(() => (activeTab === 'json' ? MAX_WIDTH_LARGE : MAX_WIDTH_DEFAULT), [activeTab]);
  const [width, setWidth] = useState(defaultWidth);

  useEffect(() => {
    setWidth(defaultWidth);
  }, [activeClipId, activeTab]);

  const onResizeMove = useCallback((event) => {
    const { delta } = event;
    setWidth((prevWidth) => {
      const newWidth = Math.abs(prevWidth + delta.x);
      return Math.min(Math.max(newWidth, MIN_WIDTH), defaultWidth);
    });
  }, []);

  const onDoubleTap = useCallback(() => {
    setWidth((prevWidth) => (prevWidth === MIN_WIDTH ? defaultWidth : MIN_WIDTH));
  }, []);

  return (
    <ReactableSidebarComponent
      resizable={resizableOptions}
      onResizeMove={onResizeMove}
      onDoubleTap={onDoubleTap}
      width={width}
      activeClipId={activeClipId}
      activeTab={activeTab}
    />
  );
}

export default WorkspaceSidebarPanelReactable;
