import { memo, useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import '@css/TimelineMarkers.css';

import { timelineScaleState } from '@store/atoms/TimelineState';
import { fpsAtom } from '@store/studio/Output';

import { TIMELINE_TRACK_OFFSET } from '@constants/Timeline';

import getPixelSeconds from '@utils/getPixelSeconds';

const TIME_MARKER_TOTAL_SECONDS = 30 * 60; // 30 minutes

const generateTimeMarkers = (timelineScale) => {
  const pixelSeconds = getPixelSeconds(timelineScale);
  const totalSeconds = TIME_MARKER_TOTAL_SECONDS;
  const markers = [];

  for (let i = 0; i <= totalSeconds; i++) {
    const minutes = Math.floor(i / 60);
    const seconds = i % 60;

    markers.push({
      time: `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:00.00`,
      position: pixelSeconds(i),
    });
  }

  return markers;
};

function TimelineMarkers() {
  const timelineScale = useRecoilValue(timelineScaleState);
  const fps = useRecoilValue(fpsAtom);
  const scaleRef = useRef(null);

  const secondsMarkerWidth = useMemo(() => {
    return getPixelSeconds(timelineScale)(1);
  }, [timelineScale]);

  const timeMarkers = useMemo(() => generateTimeMarkers(timelineScale), [timelineScale]);

  return (
    <div
      ref={scaleRef}
      className="timeline-header__scale"
      style={{
        transform: `translateX(${TIMELINE_TRACK_OFFSET}px)`,
        '--fps': fps,
        '--seconds-marker-width': `${secondsMarkerWidth}px`,
      }}
    >
      {timeMarkers.map((marker, index) => (
        <div
          key={`${marker.time}-${timelineScale}`}
          data-index={index}
          className="timeline-header__scale-marker"
          style={{
            transform: `translateX(${marker.position}px)`,
          }}
        >
          <div className="timeline-header__scale-marker-time">{marker.time}</div>
        </div>
      ))}
    </div>
  );
}

export default memo(TimelineMarkers);
