import { clamp, throttle } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import '@css/Canvas.css';

import { canvasZoomAtom } from '@store/atoms/CanvasState';
import { sdkOptionsSelector } from '@store/atoms/SdkState';
import { canvasStageState } from '@store/selectors/CanvasSelectors';
import { outputDimensionsSelector } from '@store/studio/Output';

import { MAX_ZOOM, MIN_ZOOM } from '@constants/Canvas';
import { TIMELINE_HEIGHT } from '@constants/Timeline';

function useCanvasStage({ stageRef }) {
  const { isSdkEnabled, isSidebar, isTimeline } = useRecoilValue(sdkOptionsSelector);
  const output = useRecoilValue(outputDimensionsSelector);
  const setCanvas = useSetRecoilState(canvasStageState({}));

  const [cameraPosition, setCameraPosition] = useState({ x: 0, y: 0 });
  const [isPanning, setIsPanning] = useState(false);
  const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 });
  const [isPanningKeyPressed, setIsPanningKeyPressed] = useState(false);
  const setZoom = useSetRecoilState(canvasZoomAtom);

  const handleKeyDown = useCallback((e) => {
    if ((e.ctrlKey || e.metaKey) && (e.altKey || e.getModifierState('AltGraph'))) {
      setIsPanningKeyPressed(true);
    }
  }, []);

  const handleKeyUp = useCallback((e) => {
    if (!(e.ctrlKey || e.metaKey) || !(e.altKey || e.getModifierState('AltGraph'))) {
      setIsPanningKeyPressed(false);
      setIsPanning(false);
    }
  }, []);

  const handleMouseDown = useCallback(
    (e) => {
      if (isPanningKeyPressed) {
        e.preventDefault();
        setIsPanning(true);
        setLastMousePosition({ x: e.clientX, y: e.clientY });
      }
    },
    [isPanningKeyPressed]
  );

  const handleMouseUp = useCallback(() => {
    setIsPanning(false);
  }, []);

  const handleMouseMove = useCallback(
    (e) => {
      if (isPanning && isPanningKeyPressed) {
        e.preventDefault();
        const dx = e.clientX - lastMousePosition.x;
        const dy = e.clientY - lastMousePosition.y;

        if (!isNaN(dx) && !isNaN(dy)) {
          setCameraPosition((prev) => ({
            x: prev.x + dx,
            y: prev.y + dy,
          }));
        }
        setLastMousePosition({ x: e.clientX, y: e.clientY });
      }
    },
    [isPanning, isPanningKeyPressed, lastMousePosition]
  );

  const handleWheel = useCallback(
    (e) => {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        const delta = e.deltaY * -0.01;
        setZoom((prevZoom) => clamp(prevZoom + delta, MIN_ZOOM, MAX_ZOOM));
      }
    },
    [setZoom]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('wheel', handleWheel);
    };
  }, [handleKeyDown, handleKeyUp, handleMouseDown, handleMouseUp, handleMouseMove, handleWheel]);

  const updateCanvasSize = throttle(() => {
    if (stageRef.current) {
      let containerWidth = stageRef?.current?.clientWidth;
      let containerHeight = stageRef?.current?.clientHeight;

      if (isSdkEnabled) {
        if (!isSidebar && !isTimeline) {
          containerWidth = window.innerWidth;
          containerHeight = window.innerHeight;
        } else if (!isSidebar && isTimeline) {
          containerHeight = window.innerHeight - TIMELINE_HEIGHT;
        }
      }

      setCanvas({
        containerWidth,
        containerHeight,
      });

      setCameraPosition({ x: 0, y: 0 }); // Reset camera position to center the artboard
    }
  }, 200);

  useEffect(() => {
    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, [stageRef, output?.resolution, output?.size, setCanvas, isSidebar, isTimeline]);

  return {
    isPanningKeyPressed,
    cameraPosition,
  };
}

export default useCanvasStage;
