import { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  playableSelector,
  playheadIsPausedState,
  playheadIsStoppedState,
  playheadState,
} from '@store/atoms/PlayheadState';
import { timelineClipTimesState, timelineDurationState } from '@store/selectors/EditSelectors';
import { fpsAtom } from '@store/studio/Output';

import useSoundtrack from '@hooks/useSoundtrack';

export const TimelinePlaybackContext = createContext();
export const useTimelinePlaybackContext = () => useContext(TimelinePlaybackContext);

export function TimelinePlaybackProvider({ children }) {
  const [isPaused, setIsPaused] = useRecoilState(playheadIsPausedState);
  const [isStopped, setIsStopped] = useRecoilState(playheadIsStoppedState);
  const [playhead, setPlayhead] = useRecoilState(playheadState);
  const isPlayable = useRecoilValue(playableSelector);
  const timelineDuration = useRecoilValue(timelineDurationState);
  const timelinePoints = useRecoilValue(timelineClipTimesState);
  const outputFps = useRecoilValue(fpsAtom);

  const soundtrack = useSoundtrack();

  const requestAnimationFrameRef = useRef();
  const initialPlayheadTimeRef = useRef();
  const fpsThrottleRef = useRef();

  const fps = 60;
  const fpsInterval = 1000 / fps;

  const formatTimecode = (seconds) => {
    const clampedSeconds = Math.max(0, seconds);
    const totalFrames = Math.round(clampedSeconds * outputFps);
    const totalSeconds = Math.floor(totalFrames / outputFps);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;
    const frames = Math.round(totalFrames % outputFps);

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}.${String(frames).padStart(2, '0')}`;
  };

  const animate = () => {
    const now = Date.now();
    const playheadPosition = now - initialPlayheadTimeRef.current;
    const elapsed = now - fpsThrottleRef.current;

    if (elapsed > fpsInterval) {
      setPlayhead(playheadPosition / 1000);
      fpsThrottleRef.current = now - (elapsed % fpsInterval);
    }

    requestAnimationFrameRef.current = requestAnimationFrame(animate);
  };

  const handlePlay = () => {
    if (timelineDuration <= 0) {
      return;
    }

    if (isStopped) {
      initialPlayheadTimeRef.current = Date.now();
    }

    if (isPaused) {
      initialPlayheadTimeRef.current = Date.now() - playhead * 1000;
    }

    setIsStopped(false);
    setIsPaused(false);
    soundtrack?.play({ start: playhead, end: timelineDuration });
    fpsThrottleRef.current = Date.now();

    requestAnimationFrameRef.current = requestAnimationFrame(animate);
  };

  const handlePause = () => {
    setIsPaused(true);
    setIsStopped(false);
    soundtrack?.pause();
    cancelAnimationFrame(requestAnimationFrameRef.current);
  };

  const handlePlayPause = () => (isPaused || isStopped ? handlePlay() : handlePause());

  const handleStop = () => {
    setIsPaused(false);
    setIsStopped(true);
    cancelAnimationFrame(requestAnimationFrameRef.current);
    setPlayhead(0);
    soundtrack?.stop();

    // manipulating playheadPosition so it plays from the playhead position
    handlePause();
  };

  const setPlayheadStart = () => {
    handlePause();
    setPlayhead(0);
  };

  const setPlayheadEnd = () => {
    handlePause();
    setPlayhead(timelineDuration);
  };

  const seek = ({ dir, snap }) => {
    handlePause();

    const getSnapValue = (state) => {
      if (dir === 'back' && state < 0) {
        return 0;
      }

      if (dir === 'forward' && state > timelineDuration) {
        return timelineDuration;
      }

      const nearestPoints = timelinePoints.filter((point) => (dir === 'back' ? point < state : point > state));
      const nearestPoint = dir === 'back' ? nearestPoints.pop() : nearestPoints[0];

      const snapPointsMap = {
        back: nearestPoint || 0,
        forward: nearestPoint || timelineDuration,
      };

      const snapFpsMap = {
        back: state - 1 / outputFps,
        forward: state + 1 / outputFps,
      };

      const snapPercentageMap = {
        back: state - timelineDuration * 0.1,
        forward: state + timelineDuration * 0.1,
      };

      const snapMaps = {
        points: snapPointsMap[dir],
        fps: snapFpsMap[dir],
        percentage: snapPercentageMap[dir],
      };

      return snapMaps[snap] ?? state;
    };

    setPlayhead(getSnapValue);
  };

  useEffect(() => {
    if (timelineDuration > 0 && playhead >= timelineDuration) {
      setIsPaused(false);
      setIsStopped(true);
      cancelAnimationFrame(requestAnimationFrameRef.current);
      setPlayhead(timelineDuration);
      soundtrack?.stop();
    }
  }, [playhead, setIsPaused, setIsStopped, setPlayhead, timelineDuration]);

  useEffect(() => {
    cancelAnimationFrame(requestAnimationFrameRef.current);

    // manipulating playheadPosition so it plays from the playhead position
    handlePause();

    return () => handleStop();
  }, []);

  const contextState = useMemo(() => {
    return {
      isPaused,
      isStopped,
      playhead,
      isPlayable,
      isPlaying: !isPaused && !isStopped,
      timelineDuration,
      timecode: formatTimecode(playhead),
      duration: formatTimecode(timelineDuration),
      handlePlay,
      handlePause,
      handleStop,
      handlePlayPause,
      setPlayhead,
      setPlayheadStart,
      setPlayheadEnd,
      seek,
      formatTimecode,
    };
  }, [isPaused, isStopped, playhead, isPlayable, timelineDuration, timelinePoints]);

  return <TimelinePlaybackContext.Provider value={contextState}>{children}</TimelinePlaybackContext.Provider>;
}
