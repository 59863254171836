import { memo } from 'react';

import { IconAudio } from '@assets/icons';

import TimelineClipInfo from '@feature/studio/timeline/TimelineClipInfo';

function TimelineClipAudio({ id }) {
  return (
    <div className="clip__inner">
      <div className="clip__header">
        <span className="clip__icon">
          <IconAudio size={18} />
        </span>
        <TimelineClipInfo id={id} />
      </div>
    </div>
  );
}

export default memo(TimelineClipAudio);
