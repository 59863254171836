import { selector } from 'recoil';

import { authKeys } from '@store/atoms/AuthState';
import { snippetLangState, snippetTypeState } from '@store/atoms/SnippetState';
import { stageAtom } from '@store/atoms/StageState';
import { idState } from '@store/atoms/TemplateState';
import { derivedJsonState } from '@store/selectors/EditSelectors';

import { stageMap } from '@constants/Stage';

import { getSnippet } from '@utils/editor/getSnippet';
import { jsonParse } from '@utils/jsonParse';

export const snippetState = selector({
  key: 'snippetState',
  get: ({ get }) => {
    const id = get(idState);
    const language = get(snippetLangState);
    const type = get(snippetTypeState);
    const keys = get(authKeys);
    const currentStage = get(stageAtom);
    const derivedJson = get(derivedJsonState());
    const derivedJsonParsed = jsonParse(derivedJson);
    const { merge } = derivedJsonParsed;

    const jsonMap = {
      blob: () => derivedJsonParsed,
      id: () => ({
        id: get(idState),
        ...(merge && merge.length && { merge }),
      }),
    };

    const json = jsonMap[type]();
    const stage = currentStage || '';
    const key = keys[stageMap[stage]]?.key || '';
    const url = `https://api.shotstack.io/${stage}${type === 'id' ? '/templates' : ''}/render`;

    return getSnippet({
      data: {
        id,
        json,
        merge,
        stage,
        key,
        url,
      },
      language,
    });
  },
});
