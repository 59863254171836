import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import config from '@src/config';

import { getOwner } from '@api/services/sdk';
import { transformGalleryTemplate } from '@api/services/template';

import { useAnalytics } from '@components/analytics/Analytics';

import { assetLoaderIsLoading } from '@store/atoms/AssetsState';
import { activeClipState } from '@store/atoms/ClipState';
import { useHydrateStoreFromTemplate } from '@store/atoms/EditState';
import {
  sdkCustomerAtom,
  sdkEnabledAtom,
  sdkLoadingAtom,
  sdkOptionsAtom,
  sdkOptionsSelector,
  sdkOriginAtom,
} from '@store/atoms/SdkState';
import { templateLoadingState } from '@store/atoms/TemplateState';
import { errorState } from '@store/atoms/UIState';
import { derivedJsonState } from '@store/selectors/EditSelectors';

import useFingerprint from '@hooks/useFingerprint';

import { sendMessage } from '@utils/iframe';

const SDK_VERSION = config.sdkVersion;

const parseSdkOptions = (options) => {
  const parsedOptions = {
    isSidebar: Boolean(options.sidebar),
    isInteractive: Boolean(options.interactive),
    isTimeline: Boolean(options.timeline),
    isControls: Boolean(options.controls),
    isLogoUrl: options.style?.logo?.url,
    primaryColor: options.style?.colors?.primaryColor,
    secondaryColor: options.style?.colors?.secondaryColor,
  };

  return Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(parsedOptions).filter(([_, value]) => value !== undefined && value !== null)
  );
};

const useSdkIntegration = () => {
  const sdkOptions = useRecoilValue(sdkOptionsSelector);
  const [sdkLoading, setSdkLoading] = useRecoilState(sdkLoadingAtom);
  const setSdkEnabled = useSetRecoilState(sdkEnabledAtom);
  const setSdkCustomer = useSetRecoilState(sdkCustomerAtom);
  const setSdkOrigin = useSetRecoilState(sdkOriginAtom);
  const setSdkOptions = useSetRecoilState(sdkOptionsAtom);
  const templateJson = useRecoilValue(derivedJsonState());
  const setErrorMessage = useSetRecoilState(errorState);
  const hydrateStore = useHydrateStoreFromTemplate();
  const setTemplateLoading = useSetRecoilState(templateLoadingState);
  const setAssetsLoading = useSetRecoilState(assetLoaderIsLoading);
  const resetActiveClip = useResetRecoilState(activeClipState);
  const { identifyUser, trackEvent } = useAnalytics();
  const { visitorId } = useFingerprint();

  const fetchOwnerPlan = async (owner) => {
    const plan = await getOwner(owner);
    if (['basic', 'plus', 'enterprise'].includes(plan)) {
      setSdkCustomer(true);
    }
  };

  const rehydrateStudio = async (data) => {
    setTemplateLoading(true);
    setAssetsLoading(true);
    try {
      const template = await transformGalleryTemplate({ template: data });
      hydrateStore(template?.template);
      resetActiveClip();
      return template;
    } catch (error) {
      console.error(error);
      setErrorMessage({ message: error.message });
    } finally {
      setTemplateLoading(false);
    }
  };

  const handleSdkMessage = async (data) => {
    const { version, operationType, origin, template, options } = data;
    if (version && version !== SDK_VERSION && !operationType) {
      return;
    }

    if (origin !== undefined) {
      setSdkOrigin(origin);
    }

    switch (operationType) {
      case 'LOAD_TEMPLATE': {
        trackEvent('Load Template', template);
        if (template) {
          await rehydrateStudio(template);
          const parsedJson = JSON.parse(templateJson);
          sendMessage(parsedJson, sdkOptions.origin);
        }
        break;
      }

      case 'CREATE_STUDIO_INSTANCE': {
        identifyUser(visitorId, options);
        trackEvent('Create Studio Instance', options);

        if (options?.owner) {
          await fetchOwnerPlan(options.owner);
          setSdkEnabled(true);
          setSdkOptions((prevState) => ({ ...prevState, ...parseSdkOptions(options) }));
        }

        if (template && typeof template === 'object') {
          sendMessage(template, sdkOptions.origin);
          rehydrateStudio(template);
        }
        setSdkLoading(false);
        break;
      }

      case 'REFRESH_STUDIO_INSTANCE': {
        trackEvent('Refresh Studio Instance', options);
        if (Object.keys(options).length > 0) {
          await fetchOwnerPlan(options.owner);
          setSdkOptions((prevState) => ({ ...prevState, ...parseSdkOptions(options) }));
        }
        break;
      }

      default: {
        return;
      }
    }
  };

  useEffect(() => {
    if (sdkOptions.origin) {
      const parsedJson = JSON.parse(templateJson);
      sendMessage(parsedJson, sdkOptions.origin);
    }
  }, [templateJson, sdkOptions.origin]);

  useEffect(() => {
    document.documentElement.style.setProperty('--primary', sdkOptions.primaryColor);
    document.documentElement.style.setProperty('--secondary-colors-lilac', sdkOptions.secondaryColor);
    document.documentElement.style.setProperty('--asset-color-text', sdkOptions.primaryColor);
    document.documentElement.style.setProperty('--asset-color-image', sdkOptions.secondaryColor);
    document.documentElement.style.setProperty('--asset-color-video', sdkOptions.secondaryColor);
    document.documentElement.style.setProperty('--asset-color-audio', sdkOptions.secondaryColor);
    document.documentElement.style.setProperty('--asset-color-mask', sdkOptions.secondaryColor);
  }, [sdkOptions.primaryColor, sdkOptions.secondaryColor]);

  useEffect(() => {
    const handleMessage = async (event) => {
      try {
        await handleSdkMessage(event.data);
      } catch (error) {
        console.error('Error parsing message data:', error);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleSdkMessage]);

  return { sdkLoading };
};

export default useSdkIntegration;
