import { Container as PixiContainer, Graphics as PixiGraphics, Stage as PixiStage } from '@inlet/react-pixi';
import cn from 'classnames';
import * as PIXI from 'pixi.js';
import { useCallback, useRef } from 'react';
import { useRecoilBridgeAcrossReactRoots_UNSTABLE, useRecoilValue, useSetRecoilState } from 'recoil';

import '@css/Canvas.css';

import { TimelinePlaybackProvider } from '@context/TimelinePlaybackProvider';

import CanvasTrack from '@feature/studio/canvas/CanvasTrack';

import ErrorBoundary from '@components/error/ErrorBoundary';

import { canvasZoomAtom } from '@store/atoms/CanvasState';
import { activeClipState } from '@store/atoms/ClipState';
import { backgroundState, trackIdsState } from '@store/atoms/EditState';
import { sdkOptionsSelector } from '@store/atoms/SdkState';
import { canvasStageState } from '@store/selectors/CanvasSelectors';

import useCanvasStage from '@hooks/useCanvasStage';

if (window.__PIXI_INSPECTOR_GLOBAL_HOOK__) {
  window.__PIXI_INSPECTOR_GLOBAL_HOOK__.register({ PIXI });
}

PIXI.utils.skipHello();

const STUDIO_BACKGROUND_COLOR = '#F1F5F9';

function CanvasStage() {
  const stageRef = useRef(null);
  const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE();
  const trackIds = useRecoilValue(trackIdsState);
  const backgroundColor = useRecoilValue(backgroundState);
  const { isSdkEnabled } = useRecoilValue(sdkOptionsSelector);
  const canvas = useRecoilValue(canvasStageState({}));
  const zoom = useRecoilValue(canvasZoomAtom);
  const setActiveClip = useSetRecoilState(activeClipState);
  const tracks = (trackIds || []).slice().reverse();

  const { isPanningKeyPressed, cameraPosition } = useCanvasStage({ stageRef });

  const drawBackground = useCallback((g) => {
    g.clear().beginFill(PIXI.utils.string2hex(STUDIO_BACKGROUND_COLOR)).drawRect(-9999, -9999, 19998, 19998).endFill();
  }, []);

  const drawArtboard = useCallback(
    (g) => {
      g.clear();
      g.beginFill(PIXI.utils.string2hex(backgroundColor));
      g.drawRect(0, 0, canvas.stage.width / canvas.stage.scale, canvas.stage.height / canvas.stage.scale);
      g.endFill();
    },
    [backgroundColor, canvas.stage.width, canvas.stage.height, canvas.stage.scale]
  );

  const handleBackgroundClick = useCallback(() => {
    setActiveClip();
  }, []);

  return (
    <div ref={stageRef} className="canvas" data-testid="canvas-stage">
      <div
        className={cn('canvas__inner', { 'canvas__inner--sdk': isSdkEnabled })}
        style={{ pointerEvents: isPanningKeyPressed ? 'none' : 'auto' }}
      >
        {canvas.ready && (
          <ErrorBoundary message="Check your JSON is valid and correct and try again.">
            <PixiStage
              width={stageRef?.current?.clientWidth}
              height={stageRef?.current?.clientHeight}
              style={{ backgroundColor }}
              options={{ autoDensity: true, resolution: window.devicePixelRatio, backgroundAlpha: 0, antialias: false }}
            >
              <PixiContainer
                width={canvas.stage.width}
                height={canvas.stage.height}
                x={(stageRef?.current?.clientWidth - canvas.stage.width * zoom) / 2 + cameraPosition.x}
                y={(stageRef?.current?.clientHeight - canvas.stage.height * zoom) / 2 + cameraPosition.y}
                scale={canvas.stage.scale * zoom}
                sortableChildren
              >
                <PixiGraphics draw={drawBackground} interactive={true} pointerdown={handleBackgroundClick} />
                <PixiGraphics draw={drawArtboard} />
                <RecoilBridge>
                  <TimelinePlaybackProvider>
                    {tracks.map((id, index) => (
                      <CanvasTrack key={id} id={id} index={index} />
                    ))}
                  </TimelinePlaybackProvider>
                </RecoilBridge>
              </PixiContainer>
            </PixiStage>
          </ErrorBoundary>
        )}
      </div>
    </div>
  );
}

export default CanvasStage;
