import cn from 'classnames';
import { useRecoilState, useRecoilValue } from 'recoil';

import '@css/TimelineSdkControls.css';

import { IconPause, IconPlay, IconVolumeFull, IconVolumeMute } from '@assets/icons';
import LogoEmblem from '@assets/img/logo/logo.svg?react';

import { useTimelinePlaybackContext } from '@context/TimelinePlaybackProvider';

import { sdkOptionsSelector } from '@store/atoms/SdkState';
import { playbackMuteAtom } from '@store/atoms/TimelineState';

import TimelineSdkScrub from './TimelineSdkScrub';

function TimelineSdkControls() {
  const { isPlayable, isPlaying, handlePlayPause } = useTimelinePlaybackContext();
  const [isMuted, setIsMuted] = useRecoilState(playbackMuteAtom);
  const { isControls, isSdkCustomer } = useRecoilValue(sdkOptionsSelector);

  const handleMute = () => {
    setIsMuted((prev) => !prev);
  };

  if (!isPlayable) {
    return null;
  }

  return (
    <div className="sdk-controls__container">
      {isControls && (
        <>
          <div className="sdk-controls__playback">
            <div className="sdk-controls__buttons">
              <button type="button" className="sdk-controls__playpause" onClick={handlePlayPause}>
                {!isPlaying ? <IconPlay size={25} /> : <IconPause size={25} />}
              </button>
            </div>
          </div>
          <div className="sdk-controls__scrub">
            <TimelineSdkScrub />
          </div>
          <div className="sdk-controls__right">
            <button type="button" className={cn('sdk-controls__mute', { muted: isMuted })} onClick={handleMute}>
              {isMuted ? <IconVolumeMute size={12} /> : <IconVolumeFull size={12} />}
            </button>
          </div>
        </>
      )}
      {!isSdkCustomer && (
        <div className="sdk-controls__logo">
          <a href="https://shotstack.io" target="_blank" rel="noreferrer">
            <LogoEmblem />
          </a>
        </div>
      )}
    </div>
  );
}

export default TimelineSdkControls;
