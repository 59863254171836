import { useRecoilState } from 'recoil';

import { IconScale } from '@assets/icons';

import InputNumber from '@components/controls/InputNumber';

import { thumbnailAtomFamily } from '@store/studio/Output';

import { parseNumberOrEmptyString } from '@utils/numbers';

function ThumbnailScaleInput({}) {
  const [scale, setScale] = useRecoilState(thumbnailAtomFamily('scale'));

  const handleChange = (event) => {
    const { value } = event.target;
    setScale(parseNumberOrEmptyString(value));
  };

  return (
    <InputNumber
      id="thumbnail-scale"
      title="Scale"
      value={scale ?? ''}
      min={0}
      max={1}
      prefix={<IconScale size={18} />}
      step={0.01}
      handleChange={handleChange}
    />
  );
}

export default ThumbnailScaleInput;
