import { debounce } from 'lodash-es';
import { useCallback, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import '@assets/css/PanelMediaListing.css';

import MediaListingPreview from '@components/asset/MediaListingPreview';

import {
  mediaLoadingAtom,
  mediaMetaAtom,
  mediaSelectorFamily,
  useAddClipToTimelineCallback,
  useFetchMediaCallback,
} from '@store/studio/Media';

function PanelMediaListing({ type }) {
  const mediaList = useRecoilValue(mediaSelectorFamily(type));
  const mediaMeta = useRecoilValue(mediaMetaAtom);
  const mediaLoading = useRecoilValue(mediaLoadingAtom);
  const fetchMedia = useFetchMediaCallback();
  const addClipToTimeline = useAddClipToTimelineCallback();
  const endRef = useRef(null);

  const observerCallback = useCallback(
    debounce((entries) => {
      const [entry] = entries;
      if (entry?.isIntersecting && !mediaLoading && mediaMeta.next !== null) {
        fetchMedia(mediaMeta);
      }
    }, 500),
    [mediaLoading, fetchMedia, mediaMeta]
  );

  const fetchMediaButton = () => {
    if (mediaMeta.next !== null) {
      fetchMedia(mediaMeta);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, { threshold: 1 });
    if (endRef.current) observer.observe(endRef.current);

    return () => {
      if (endRef.current) observer.unobserve(endRef.current);
    };
  }, [observerCallback]);

  return (
    <div className="media-listing">
      <div>
        <h6>Library</h6>
      </div>
      <div className="media-listing__container">
        {(mediaList || []).map((media, idx) => (
          <MediaListingPreview
            key={media.id + idx}
            media={media}
            onClick={() => addClipToTimeline({ data: media, type: media.type })}
          />
        ))}
      </div>

      {!mediaList.length && !mediaLoading && (
        <div className="text-center">
          <small>No Items</small>
        </div>
      )}

      {mediaLoading && (
        <div className="text-center">
          <small>Loading...</small>
        </div>
      )}

      <div ref={endRef} style={{ height: '20px' }} />

      {mediaMeta.next !== null && (
        <div className="text-center">
          <button onClick={() => fetchMediaButton()} className="btn btn-light mx-auto">
            Load more media
          </button>
        </div>
      )}
    </div>
  );
}

export default PanelMediaListing;
