import '@css/Loader.css';

import Logo from '@assets/img/logo/emblem.png';

function Loader() {
  return (
    <div className="loader">
      <div className="spinner">
        <img src={Logo} alt="Shotstack" />
      </div>
    </div>
  );
}

export default Loader;
