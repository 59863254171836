import config from '@config';
import axios from 'axios';
import { useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import ButtonElement from '@components/atoms/ButtonElement';

import { authToken } from '@store/atoms/AuthState';
import { stageAtom } from '@store/atoms/StageState';

import useAxiosPoll from '@hooks/useAxiosPoll';

import { RENDITION_WIDTH } from '@constants/Uploads';

const UPLOAD_STATUS_READY = 'ready';
const UPLOAD_STATUS_FAILED = 'failed';
const UPLOAD_FILE_ENDPOINT = 'upload';
const UPLOAD_REQUEST_URL = `${config.ingest.url}${UPLOAD_FILE_ENDPOINT}`;
const UPLOAD_POLLING_INTERVAL = 2000;

const requestOptions = {
  outputs: {
    renditions: [
      {
        format: 'webm',
        filename: 'shotstack-overlay',
        keyframeInterval: 25,
        size: {
          width: RENDITION_WIDTH,
        },
      },
    ],
  },
  destinations: [
    {
      provider: 'shotstack',
    },
  ],
};

function OverlaySourceInput({ onUploadComplete }) {
  const fileInput = useRef(null);
  const token = useRecoilValue(authToken);
  const stage = useRecoilValue(stageAtom);
  const { startPolling } = useAxiosPoll();

  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState();
  const [uploadStatus, setUploadStatus] = useState('Upload');

  const requestHeaders = {
    headers: {
      Authorization: `Bearer ${token}`,
      stage,
    },
  };

  const onPollCheck = (response) => {
    const {
      attributes: {
        status,
        outputs: {
          renditions: [proxy],
        },
      },
    } = response.data.data;
    return [status, proxy?.status].every((s) => [UPLOAD_STATUS_READY, UPLOAD_STATUS_FAILED].includes(s));
  };

  const getStatus = async ({ sourceId }) => {
    const url = `${config.ingest.url}sources/${sourceId}`;
    return startPolling({
      url,
      config: requestHeaders,
      interval: UPLOAD_POLLING_INTERVAL,
      onPollCheck,
    });
  };

  const getFileUploadAttrs = async ({ data }) => {
    const response = await axios.post(UPLOAD_REQUEST_URL, data, requestHeaders);
    const { attributes } = response.data.data;
    if (!attributes?.id) {
      throw new Error('Upload request failed. No attribute id returned.');
    }
    return attributes;
  };

  const uploadFile = ({ sourceUrl, file }) =>
    axios.put(sourceUrl, file, {
      headers: {
        'Content-Type': '',
      },
    });

  const handleBrowseFiles = (e) => {
    e.stopPropagation();
    fileInput.current.click();
  };

  const handleFileChange = async () => {
    const [file] = fileInput.current?.files || [];
    if (!file) return;

    setUploadError();
    setIsUploading(true);
    setUploadStatus('Uploading...');

    try {
      const { id: sourceId, url: sourceUrl } = await getFileUploadAttrs({ data: requestOptions });
      await uploadFile({ sourceUrl, file });

      setUploadStatus('Processing...');

      const response = await getStatus({ sourceId });
      const {
        attributes: {
          status,
          outputs: {
            renditions: [proxy],
          },
        },
      } = response.data.data;
      if ([status, proxy?.status].includes(UPLOAD_STATUS_FAILED)) throw new Error('Upload failed.');

      onUploadComplete({ ...response.data.data.attributes, type: 'overlay' });
    } catch (error) {
      console.error(error);
      setUploadError('Sorry, there was a problem uploading your file.');
    } finally {
      setUploadStatus('Upload');
      setIsUploading(false);
    }
  };

  return (
    <>
      <ButtonElement className="w-100" handleEvent={handleBrowseFiles} variant="primary" disabled={isUploading}>
        {uploadStatus}
      </ButtonElement>
      <input
        ref={fileInput}
        type="file"
        accept="video/quicktime"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      {uploadError && <p className="input-error">{uploadError}</p>}
    </>
  );
}
export default OverlaySourceInput;
