import { Container } from '@inlet/react-pixi';
import { Texture } from 'pixi.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import CanvasVideoPlayer from '@feature/studio/canvas/CanvasVideoPlayer';

import { clipErrorsFamily, overridesFamily } from '@store/atoms/EditState';
import { clipState } from '@store/selectors/EditSelectors';

const DEFAULT_TRIM_TIME = 0;
const DEFAULT_VOLUME = 1;

function CanvasVideo({ id, index }) {
  const { ['asset:src']: src, ['asset:trim']: trim, ['asset:volume']: volume } = useRecoilValue(clipState(id));
  const assetOverrides = useRecoilValue(overridesFamily(id));
  const [textureLoadError, setTextureLoadError] = useState(false);
  const [texture, setTexture] = useState(null);
  const setClipErrors = useSetRecoilState(clipErrorsFamily(id));

  const videoRef = useRef(null);

  const loadVideo = useCallback(async () => {
    if (!src) {
      setTexture(null);
      return;
    }

    setTextureLoadError(false);
    try {
      const videoUrl = new URL(src);
      videoUrl.searchParams.append('assetId', id);
      const loadedTexture = await Texture.fromURL(videoUrl.toString());
      if (!loadedTexture) throw new Error('Invalid video');
      setTexture(loadedTexture);

      videoRef.current = loadedTexture.baseTexture.resource.source;
      videoRef.current.onseeked = () => {
        loadedTexture.baseTexture.resource.source.pause();
        loadedTexture.baseTexture.update();
      };

      loadedTexture.baseTexture.on('error', () => {
        console.error('Failed to load video: ', src);
      });

      loadedTexture.baseTexture.on('loaded', () => {
        loadedTexture.baseTexture.resource.autoPlay = false;
        loadedTexture.baseTexture.resource.source.autoplay = false;
        loadedTexture.baseTexture.resource.source.preload = 'auto';
        loadedTexture.baseTexture.resource.source.autoload = true;
        loadedTexture.baseTexture.resource.source.currentTime = trim || DEFAULT_TRIM_TIME;
        loadedTexture.baseTexture.resource.source.volume = volume >= 0 ? volume : DEFAULT_VOLUME;

        loadedTexture.baseTexture.resource.source.pause();
        loadedTexture.baseTexture.update();
      });
    } catch (error) {
      console.error(error);
      setTextureLoadError(true);
    }
  }, [src, trim, volume]);

  useEffect(() => {
    loadVideo();
  }, [loadVideo]);

  useEffect(() => {
    const errors = [];

    if (textureLoadError) {
      errors.push({
        title: 'Preview Unavailable',
        detail: `Asset source is inaccessible.${
          assetOverrides.src ? ` The merge field ${assetOverrides.src} cannot be displayed.` : ''
        }`,
      });
    }

    setClipErrors(errors);
  }, [textureLoadError]);

  if (textureLoadError || !texture) {
    return <Container />;
  }

  return <CanvasVideoPlayer key={texture} id={id} index={index} resource={texture} />;
}

export default CanvasVideo;
