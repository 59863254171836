import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import CanvasStage from '@feature/studio/canvas/CanvasStage';
import TimelineControls from '@feature/studio/timeline/TimelineControls';
import TimelineSdkControls from '@feature/studio/timeline/TimelineSdkControls';

import { sdkOptionsSelector } from '@store/atoms/SdkState';

function WorkspaceCanvas() {
  const { isSdkEnabled } = useRecoilValue(sdkOptionsSelector);
  return (
    <div className="workspace__canvas">
      <CanvasStage />
      <div className="workspace__controls">{isSdkEnabled ? <TimelineSdkControls /> : <TimelineControls />}</div>
    </div>
  );
}

export default memo(WorkspaceCanvas);
