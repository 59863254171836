import config from '@config';
import { useEffect, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE, useRecoilState, useResetRecoilState } from 'recoil';

import { IconServer } from '@assets/icons';

import SelectElement from '@components/atoms/SelectElement';

import { allWorkflowsListSelector } from '@store/Workflows';
import { showStageConfirmationModalAtom, stageAtom } from '@store/atoms/StageState';
import { bulkTemplateDeleteState } from '@store/atoms/TemplateState';
import { allTemplatesState } from '@store/selectors/TemplateSelectors';

const isEditor = (path) => {
  return path.startsWith('/studio/editor');
};

function StageSelect() {
  const [stage, setStage] = useRecoilState(stageAtom);
  const [showStageConfirmationModal, setShowStageConfirmationModal] = useRecoilState(showStageConfirmationModalAtom);
  const resetBulkDeleteTemplateState = useResetRecoilState(bulkTemplateDeleteState);
  const refreshAllTemplateState = useRecoilRefresher_UNSTABLE(allTemplatesState);
  const refreshAllWorkflowsState = useRecoilRefresher_UNSTABLE(allWorkflowsListSelector);

  const location = useLocation();
  const [tracking, setTracking] = useState({
    name: 'Select Environment',
    properties: {
      Location: location.pathname,
    },
  });

  useEffect(() => {
    const updatedTracking = { ...tracking };
    updatedTracking.properties = {
      Location: location.pathname,
    };

    setTracking({ ...updatedTracking });

    if (showStageConfirmationModal && !isEditor(location.pathname)) {
      setShowStageConfirmationModal(false);
    }
  }, [location.pathname, showStageConfirmationModal]);

  const options = config.edit.stages.map((item) => {
    return {
      value: item.stage,
      text: item.name,
    };
  });

  const handleSelect = (event) => {
    if (isEditor(location.pathname)) {
      setShowStageConfirmationModal(event.target.value);
    } else {
      setStage(event.target.value);
      refreshAllTemplateState();
      resetBulkDeleteTemplateState();
      refreshAllWorkflowsState();
    }
  };

  return (
    <InputGroup className="toolbar-select-stage">
      <InputGroup.Text variant="light">
        <IconServer size="16" />
      </InputGroup.Text>
      <SelectElement
        data-testid="select-stage"
        handleEvent={handleSelect}
        options={options}
        selected={stage}
        tracking={tracking}
      />
    </InputGroup>
  );
}

export default StageSelect;
