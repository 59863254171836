import { createClipAnimation } from '@animation/animate';
import { useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import CanvasEditFrame from '@feature/studio/canvas/CanvasEditFrame';
import CanvasMask from '@feature/studio/canvas/CanvasMask';
import CenterLines from '@feature/studio/canvas/CanvasSnapLines';

import { activeClipState } from '@store/atoms/ClipState';
import { playheadState } from '@store/atoms/PlayheadState';
import { canvasStageState } from '@store/selectors/CanvasSelectors';
import { clipState, clipVisibilityState } from '@store/selectors/EditSelectors';
import { clipKeyframesForAnimationSelectorFamily } from '@store/studio/Keyframes';

import useMovableAsset from '@hooks/useMovableAsset';

import { BOUNDING_BOX_HEIGHT_PIXELS, BOUNDING_BOX_WIDTH_PIXELS } from '@constants/TextAssetDefaults';

const getAssetDimensions = ({ clip, resource }) => {
  switch (clip['asset:type']) {
    case 'image': {
      return {
        assetWidth: clip['asset:meta']?.width || resource?.width,
        assetHeight: clip['asset:meta']?.height || resource?.height,
      };
    }
    case 'overlay':
    case 'video': {
      const videoResource = resource?.baseTexture?.resource?.source;
      return {
        assetWidth: clip['asset:meta']?.width || videoResource.videoWidth,
        assetHeight: clip['asset:meta']?.height || videoResource.videoHeight,
      };
    }
    case 'caption': {
      return {
        assetWidth: undefined,
        assetHeight: undefined,
      };
    }
    default: {
      return {
        assetWidth: clip['asset:width'] || BOUNDING_BOX_WIDTH_PIXELS,
        assetHeight: clip['asset:height'] || BOUNDING_BOX_HEIGHT_PIXELS,
      };
    }
  }
};

function withCanvasMovable(CanvasComponent) {
  function WithCanvasMovable(props) {
    const { id, index, resource } = props;
    const spriteRef = useRef();
    const canvas = useRecoilValue(canvasStageState());
    const clip = useRecoilValue(clipState(id));
    const activeClipId = useRecoilValue(activeClipState);
    const visible = useRecoilValue(clipVisibilityState(id));
    const playhead = useRecoilValue(playheadState);
    const keyframes = useRecoilValue(clipKeyframesForAnimationSelectorFamily(id));
    const { assetWidth, assetHeight } = getAssetDimensions({ clip, resource });

    const calculatedClip = createClipAnimation({
      playhead,
      clip,
      keyframes,
      asset: { width: assetWidth, height: assetHeight }, // not sure if this is needed
    });

    const movableAssetConfig = {
      id,
      assetWidth,
      assetHeight,
      canvasWidth: canvas.width,
      canvasHeight: canvas.height,
      calculatedClip,
      spriteRef,
    };

    const movableAsset = useMovableAsset(movableAssetConfig);

    const {
      bounds,
      dimensions: { width = 1, height = 1, aspectRatio },
      isMoving,
      position: { x, y },
      scale,
      angle,
      snappingLines,
      handlePositionGrab,
      handlePositionDrop,
      handlePositionDrag,
      handleRotateGrab,
      handleRotateDrag,
      handleRotateDrop,
      handleResizeDrag,
      handleResizeDrop,
      handleResizeGrab,
      handleScaleGrab,
      handleScaleDrag,
      handleScaleDrop,
    } = movableAsset;

    const canScale = useMemo(() => ['image', 'video', 'overlay'].includes(clip['asset:type']), [clip['asset:type']]);
    const canResize = useMemo(() => ['text', 'html'].includes(clip['asset:type']), [clip['asset:type']]);
    const hasFrame = useMemo(() => !['caption'].includes(clip['asset:type']), [clip['asset:type']]);

    const isSelected = activeClipId === id;
    const opacity = calculatedClip.opacity ?? 1;

    return (
      <>
        <CanvasMask x={x} y={y} width={width + 1} height={height + 1} angle={angle} clipId={id}>
          <CanvasComponent
            ref={spriteRef}
            clip={calculatedClip}
            x={x}
            y={y}
            width={width}
            height={height}
            alpha={opacity}
            zIndex={index}
            angle={angle}
            scale={scale}
            visible={visible}
            aspectRatio={aspectRatio}
            resource={resource}
          />
        </CanvasMask>

        {hasFrame && (
          <CanvasEditFrame
            bounds={bounds}
            width={width}
            height={height}
            isDragging={isMoving}
            scale={scale}
            selected={isSelected}
            inView={visible}
            zIndex={index}
            angle={angle}
            handlePositionGrab={handlePositionGrab}
            handlePositionDrag={handlePositionDrag}
            handlePositionDrop={handlePositionDrop}
            handleRotateGrab={handleRotateGrab}
            handleRotateDrag={handleRotateDrag}
            handleRotateDrop={handleRotateDrop}
            handleResizeGrab={canResize && handleResizeGrab}
            handleResizeDrag={canResize && handleResizeDrag}
            handleResizeDrop={canResize && handleResizeDrop}
            handleScaleGrab={canScale && handleScaleGrab}
            handleScaleDrag={canScale && handleScaleDrag}
            handleScaleDrop={canScale && handleScaleDrop}
          />
        )}
        {snappingLines && <CenterLines width={canvas.width} height={canvas.height} snappingLines={snappingLines} />}
      </>
    );
  }

  return WithCanvasMovable;
}

export default withCanvasMovable;
