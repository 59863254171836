import config from '@config';
import axios from 'axios';
import { Deserializer } from 'jsonapi-serializer';
import { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import { authToken } from '@store/atoms/AuthState';

const INTEGRATION_ENDPOINT = 'integration/google-drive';
const SAVE_ERROR =
  'There was an error connecting to your Google account. Please try again or contact us if the issue continues.';
const LOAD_ERROR =
  'There was an error retrieving your Google Drive credentials. Please try again or contact us if the issue continues.';

const GOOGLE_AUTH_ENDPOINT = 'https://accounts.google.com/o/oauth2/v2/auth';
const REDIRECT_URI = config.oauth.google.redirectUri;
const CLIENT_ID = config.oauth.google.clientId;
const SCOPE = config.oauth.google.scope;

function GoogleDriveForm({ stage, stageName, index }) {
  const bearerToken = useRecoilValue(authToken);
  const [setAuthorizationToken] = useState('');
  const [setAccessToken] = useState('');
  const [isBusy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [isPopulated, setIsPopulated] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const generateAuthUrl = () => {
    const params = new URLSearchParams({
      response_type: 'code',
      client_id: CLIENT_ID,
      redirect_uri: `${REDIRECT_URI}?stage=${stage}`,
      scope: SCOPE,
      access_type: 'offline',
      prompt: 'consent',
    });

    return `${GOOGLE_AUTH_ENDPOINT}?${params.toString()}`;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setBusy(true);

    window.location.href = generateAuthUrl();
  };

  useEffect(() => {
    const extractCodeFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('code');
    };

    const extractStageFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('stage');
    };

    const code = extractCodeFromUrl();
    const integrationStage = extractStageFromUrl();

    if (integrationStage !== stage) return;

    const fetchAndSetAccessToken = async () => {
      try {
        if (!code) return;
        if (!bearerToken) throw new Error('Not authorized');

        setBusy(true);

        const authorizationCode = code;

        const payload = {
          credentials: {
            authorizationCode,
          },
          stage,
        };

        const response = await axios.put(config.integration.url + INTEGRATION_ENDPOINT, payload, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });

        new Deserializer({ keyForAttribute: 'camelCase' }).deserialize(response.data, (err, data) => {
          if (err) throw err;
          setAccessToken(data.credentials.accessToken);
        });

        setIsPopulated(true);
        setIsEditing(false);
      } catch (error) {
        console.error(error);
        setErrorMessage(SAVE_ERROR);
      } finally {
        setBusy(false);
      }
    };

    fetchAndSetAccessToken();
  }, []);

  useEffect(() => {
    async function getCredentials() {
      if (bearerToken && bearerToken.length) {
        try {
          const response = await axios.get(config.integration.url + INTEGRATION_ENDPOINT, {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          });

          const data = await new Deserializer({ keyForAttribute: 'camelCase' }).deserialize(response.data);

          const integration = Array.isArray(data) ? data.find((integration) => integration.stage === stage) : null;
          setIsPopulated(Boolean(integration));
          setIsReady(true);

          if (!integration) {
            setIsEditing(true);
          } else {
            setAuthorizationToken(data[index].credentials.serviceAccountJson);
          }
        } catch (error) {
          setErrorMessage(LOAD_ERROR);
          setBusy(false);
        }
      }
    }

    getCredentials();
  }, [index, bearerToken]);

  return (
    <Row className="mb-4">
      <Col>
        <Card>
          <Card.Body>
            {!isReady && <Spinner animation="border" variant="secondary" />}
            {isReady && isPopulated && !isEditing && (
              <>
                <Row>
                  <Col className="mb-2">
                    <b>{stageName}</b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" disabled onClick={() => setIsEditing(true)}>
                      Connected
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {isReady && isEditing && (
              <Form onSubmit={handleSubmit} id="googleCloudStorageCredentialsForm">
                <Row>
                  <Col className="mb-2">
                    <b>{stageName}</b>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group controlId="serviceAccountJson">
                      <Button variant="primary" type="submit" disabled={isBusy} className="fixed">
                        {isBusy ? (
                          <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
                        ) : (
                          'Connect to Google Drive'
                        )}
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
                {errorMessage && (
                  <Alert variant="danger" onClose={() => setErrorMessage(false)} dismissible>
                    {errorMessage}
                  </Alert>
                )}
              </Form>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default GoogleDriveForm;
