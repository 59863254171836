import MaskSourceInput from '@components/controls/MaskSourceInput';

import { useAddMediaCallback } from '@store/studio/Media';

function PanelMaskUpload() {
  const addMedia = useAddMediaCallback();

  const onUploadComplete = (media) => {
    addMedia(media);
  };

  return (
    <>
      <MaskSourceInput onUploadComplete={onUploadComplete} />
      <p className="helper-text centered">
        <span>
          Create track matte mask effects and{' '}
          <a target="_blank" rel="noreferrer" href="https://shotstack.io/learn/luma-matte-transitions/">
            transitions using luma mattes
          </a>
          . Upload a
          <a target="_blank" rel="noreferrer" href="https://shotstack.io/learn/create-luma-matte-after-effects/">
            {' '}
            luma video
          </a>{' '}
          or image to apply a mask to the track. Check our gallery of{' '}
          <a target="_blank" rel="noreferrer" href="https://luma-gallery.netlify.app/">
            free to use luma mattes
          </a>
          .
        </span>
      </p>
    </>
  );
}

export default PanelMaskUpload;
