import { memo } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import { useAnalytics } from '@components/analytics/Analytics';

const defaultTrackingProperties = {
  Context: 'Button',
};

function ButtonElement({
  ref,
  children,
  className,
  disabled,
  handleEvent = () => {},
  bsPrefix,
  size = 'md',
  style,
  type,
  name,
  tracking,
  value,
  variant,
  loading,
  title,
}) {
  const { trackEvent } = useAnalytics();

  const handleClick = (event) => {
    if (disabled) {
      event.preventDefault();
      return;
    }

    if (tracking) {
      trackEvent(tracking.name, { ...defaultTrackingProperties, ...tracking.properties });
    }
    handleEvent(event);
  };

  return (
    <Button
      ref={ref}
      bsPrefix={bsPrefix}
      disabled={disabled}
      onClick={handleClick}
      variant={variant}
      className={className}
      size={size}
      style={style}
      type={type}
      name={name}
      value={value}
      title={title || ''}
    >
      {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : children}
    </Button>
  );
}

export default memo(ButtonElement);
