import { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useRecoilState } from 'recoil';

import '@css/PanelClipProperties.css';

import Transitions from '@components/transitions/Transitions';

import { clipState } from '@store/selectors/EditSelectors';

const getFullTransitionValue = ({ value, speed }) => {
  if (!value) {
    return undefined;
  }
  return [value, speed].join('');
};

const getBaseTranstionValue = (clip, dir) => {
  const transition = clip[`transition:${dir}`];
  if (!transition) {
    return undefined;
  }
  return transition?.replace(/Fast|Slow/, '');
};

const getBaseTranstionSpeed = (clip, dir) => {
  const transition = clip[`transition:${dir}`];
  if (!transition) {
    return '';
  }
  if (transition?.includes('Fast')) {
    return 'Fast';
  }
  if (transition?.includes('Slow')) {
    return 'Slow';
  }
  return '';
};

const transitionDirectionOptions = [
  { value: 'in', text: 'In' },
  { value: 'out', text: 'Out' },
];

const transitionSpeedOptions = [
  { value: '', text: 'Normal' },
  { value: 'Slow', text: 'Slow' },
  { value: 'Fast', text: 'Fast' },
];

function PanelClipTransitions({ id }) {
  const [clip, setClip] = useRecoilState(clipState(id));

  const [dir, setDir] = useState('in');
  const [speed, setSpeed] = useState(getBaseTranstionSpeed(clip, dir));
  const [baseTransition, setBaseTransition] = useState(getBaseTranstionValue(clip, dir));

  useEffect(() => {
    setBaseTransition(getBaseTranstionValue(clip, dir));
    setSpeed(getBaseTranstionSpeed(clip, dir));
  }, [clip, dir]);

  const handleTransitionChange = (value) => {
    const newBaseTransition = baseTransition !== value ? value : undefined;
    setBaseTransition(newBaseTransition);

    setClip({
      [`transition:${dir}`]: getFullTransitionValue({ value: newBaseTransition, speed }),
    });
  };

  const handleSpeedChange = (newSpeed) => {
    setSpeed(newSpeed);

    setClip({
      [`transition:${dir}`]: getFullTransitionValue({ value: baseTransition, speed: newSpeed }),
    });
  };

  return (
    <>
      <div className="transition-options mb-4">
        <ToggleButtonGroup type="radio" name="direction" value={dir} onChange={setDir}>
          {transitionDirectionOptions.map(({ value, text }) => (
            <ToggleButton
              key={`dir-${text}`}
              id={`dir-${text}`}
              value={value}
              className={dir === value ? 'active' : ''}
            >
              {text}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>

        <ToggleButtonGroup type="radio" name="speed" value={speed} onChange={handleSpeedChange}>
          {transitionSpeedOptions.map(({ value, text }) => (
            <ToggleButton
              key={`speed-${text}`}
              id={`speed-${text}`}
              value={value}
              className={speed === value ? 'active' : ''}
              disabled={baseTransition === 'zoom' || !baseTransition}
            >
              {text}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
      <Transitions
        selected={baseTransition ?? ''}
        speed={speed}
        dir={dir}
        onTransitionChange={handleTransitionChange}
      />
    </>
  );
}

export default PanelClipTransitions;
