import { Button, ButtonGroup } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import { IconZoomIn, IconZoomOut } from '@assets/icons';

import { canvasZoomAtom, useZoomIn, useZoomOut } from '@store/atoms/CanvasState';

import { MAX_ZOOM, MIN_ZOOM } from '@constants/Canvas';

function ZoomButtons() {
  const zoom = useRecoilValue(canvasZoomAtom);
  const zoomIn = useZoomIn();
  const zoomOut = useZoomOut();

  return (
    <ButtonGroup className="workspace__actionbuttons">
      <Button title="Zoom Out" variant="light" onClick={zoomOut} disabled={zoom <= MIN_ZOOM}>
        <IconZoomOut />
      </Button>
      <span className="zoom-level">{Math.round(zoom * 100)}%</span>
      <Button title="Zoom In" variant="light" onClick={zoomIn} disabled={zoom >= MAX_ZOOM}>
        <IconZoomIn />
      </Button>
    </ButtonGroup>
  );
}

export default ZoomButtons;
