import { useRecoilValue } from 'recoil';

import { IconCameraVideo } from '@assets/icons';

import Asset from '@components/asset/Asset';

import { clipState } from '@store/selectors/EditSelectors';

function AssetVideo({ id }) {
  const { ['asset:src']: src } = useRecoilValue(clipState(id));

  return (
    <Asset icon={<IconCameraVideo />}>
      <video key={src} style={{ width: '100%', height: 'auto' }}>
        <source src={src ? `${src}#t=1?x-request=html` : ''} />
      </video>
    </Asset>
  );
}

export default AssetVideo;
