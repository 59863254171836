import { useState } from 'react';
import { useRecoilRefresher_UNSTABLE, useResetRecoilState, useSetRecoilState } from 'recoil';

import WorkflowService from '@api/services/workflow';

import { allWorkflowsSelector } from '@store/Workflows';
import { errorState } from '@store/atoms/UIState';

const useDuplicateWorkflow = ({ onSuccess } = {}) => {
  const [loading, setLoading] = useState(false);
  const setErrorMessage = useSetRecoilState(errorState);
  const resetErrorMessage = useResetRecoilState(errorState);
  const refreshAllWorkflowsState = useRecoilRefresher_UNSTABLE(allWorkflowsSelector);

  const handleDuplicate = async (workflowId) => {
    setLoading(true);
    try {
      const newWorkflowId = await WorkflowService.duplicate(workflowId);
      refreshAllWorkflowsState();
      onSuccess(newWorkflowId);
    } catch (error) {
      setErrorMessage({ message: error.message });
      setTimeout(() => {
        resetErrorMessage();
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  return { loading, handleDuplicate };
};

export default useDuplicateWorkflow;
