import { useCallback, useMemo } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { renderTemplate } from '@api/services/template';

import { isValidJson } from '@store/atoms/EditState';
import { renderSubmittedState, rendersPanelState, rendersState } from '@store/atoms/RendersState';
import { stageAtom } from '@store/atoms/StageState';
import { errorState } from '@store/atoms/UIState';
import { useRenderTemplateState } from '@store/selectors/TemplateSelectors';

import { getRenderErrorMessage } from '@utils/errors/render';

const useRender = () => {
  const stage = useRecoilValue(stageAtom);
  const isValid = useRecoilValue(isValidJson);
  const setRenders = useSetRecoilState(rendersState);
  const setDisplayPanel = useSetRecoilState(rendersPanelState);
  const setRenderSubmitted = useSetRecoilState(renderSubmittedState);
  const resetRenderSubmitted = useResetRecoilState(renderSubmittedState);
  const setErrorMessage = useSetRecoilState(errorState);
  const resetErrorMessage = useResetRecoilState(errorState);
  const getTemplate = useRenderTemplateState();

  const submitRender = useCallback(async () => {
    resetErrorMessage();

    try {
      if (!isValid) {
        throw new Error('Invalid JSON');
      }

      setRenderSubmitted(true);
      setDisplayPanel(true);

      const render = await renderTemplate(getTemplate());

      if (!render?.id) {
        throw new Error('Render request failed');
      }

      setRenders((currentRenders) => [
        {
          id: render.id,
          stage,
        },
        ...currentRenders,
      ]);

      resetRenderSubmitted();
      return render.id;
    } catch (error) {
      console.error({ error });
      setErrorMessage(getRenderErrorMessage(error));
      resetRenderSubmitted();
      return undefined;
    }
  }, [
    isValid,
    resetErrorMessage,
    resetRenderSubmitted,
    setDisplayPanel,
    setErrorMessage,
    setRenderSubmitted,
    setRenders,
    getTemplate,
    stage,
  ]);

  return useMemo(() => ({ submitRender }), [submitRender]);
};

export default useRender;
