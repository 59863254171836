import { Badge, Card, Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import '@css/CardData.css';

import AlertBox from '@components/alert/AlertBox';
import ButtonElement from '@components/atoms/ButtonElement';
import Skeleton from '@components/skeletons/Skeleton';

function formatUsageCycleDate(date) {
  return new Date(date).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
}

const SubscriptionDetails = ({ planVersion, subscription }) => (
  <div className="mb-2">
    <p className="uppercase-label">CURRENT PLAN</p>
    <div className="text-lg font-bold d-flex align-items-center gap-2">{subscription?.name}</div>
    {subscription.deprecated && (
      <div>
        <Badge bg="warning" className="text-xs" style={{ position: 'relative', top: '-3px' }}>
          Legacy Plan
        </Badge>
      </div>
    )}
    {subscription.code === 'free' && planVersion && (
      <div>
        <Badge bg="warning" className="text-xs" style={{ position: 'relative', top: '-3px' }}>
          Limited Access
        </Badge>
      </div>
    )}
  </div>
);

const UsageCycleDetails = ({ subscription, usageCycle }) => {
  const freeTrialExpired = subscription.code === 'freeTrial' && !usageCycle;
  const creditsResetDate = usageCycle ? formatUsageCycleDate(usageCycle?.end) : null;

  return (
    <>
      <Col>
        <p className="uppercase-label">CREDITS REMAINING</p>
        <p className="text-lg font-bold">
          {!freeTrialExpired && usageCycle?.remainingCredits ? usageCycle?.remainingCredits.toFixed(2) : '—'}
        </p>
      </Col>
      <Col>
        <p className="uppercase-label">CREDITS EXPIRE ON</p>
        <p className="text-lg font-bold">{!freeTrialExpired && creditsResetDate ? creditsResetDate : '—'}</p>
      </Col>
      <Col>
        <p className="uppercase-label">OVERAGE</p>
        <p className="text-lg font-bold">
          {!freeTrialExpired && usageCycle?.overage ? usageCycle?.overage.toFixed(2) : '—'}
        </p>
      </Col>
    </>
  );
};

const RenderButtons = ({ showManageSubscriptionButton, handleRoute }) => (
  <div className="mt-2">
    {showManageSubscriptionButton && (
      <ButtonElement
        className="w-full mb-2"
        handleEvent={() => handleRoute('/subscription')}
        variant="light"
        tracking={{ name: 'Select Manage Subscription' }}
      >
        Manage Subscription
      </ButtonElement>
    )}
    <ButtonElement
      className="w-full mb-2"
      handleEvent={() => handleRoute('/usage')}
      variant={showManageSubscriptionButton ? 'link' : 'light'}
      tracking={{ name: 'Select View Usage' }}
    >
      View Usage
    </ButtonElement>
  </div>
);

function CreditsData({ planVersion, usageCycle, subscription }) {
  const location = useLocation();
  const history = useNavigate();

  const showManageSubscriptionButton = location.pathname !== '/subscription';

  const handleRoute = (path) => {
    history(path);
  };

  if (!subscription) {
    return <Skeleton />;
  }

  return (
    <>
      {subscription.code === 'freeTrial' && !usageCycle && (
        <AlertBox
          type="warning"
          message="You have no credits remaining. Purchase more credits or use the sandbox environment to continue accessing the API"
          className="mb-4"
        />
      )}

      <Card>
        <Card.Body>
          <Row>
            <Col xs={12} lg={3}>
              <SubscriptionDetails planVersion={planVersion} subscription={subscription} />
            </Col>
            {subscription.code !== 'payg' && <UsageCycleDetails subscription={subscription} usageCycle={usageCycle} />}
            <Col xs={12} lg={3}>
              <RenderButtons showManageSubscriptionButton={showManageSubscriptionButton} handleRoute={handleRoute} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default CreditsData;
