import { selector } from 'recoil';

import { mergeFamily, mergeIdsState } from '@store/atoms/EditState';

export const mergeReplacementsState = selector({
  key: 'mergeReplacementsState',
  get: ({ get }) => {
    const mergeIds = get(mergeIdsState);
    const replacements = mergeIds.map((mergeId) => get(mergeFamily(mergeId)));
    return replacements.reduce((acc, { find, replace }) => {
      acc[find] = replace;
      return acc;
    }, {});
  },
});
