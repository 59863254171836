import { Container } from '@inlet/react-pixi';
import { ImageResource } from 'pixi.js';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import CanvasImagePlayer from '@feature/studio/canvas/CanvasImagePlayer';

import { clipErrorsFamily } from '@store/atoms/EditState';
import { clipState } from '@store/selectors/EditSelectors';

function CanvasTextToImage({ id, index }) {
  const { ['asset:width']: width, ['asset:height']: height, ['asset:meta']: meta } = useRecoilValue(clipState(id));
  const setClipErrors = useSetRecoilState(clipErrorsFamily(id));
  const [resourceLoadError, setResourceLoadError] = useState(null);
  const [resource, setResource] = useState(null);

  const formatPromptForUrl = useCallback((text) => {
    if (text) {
      return text.replace(/\s+/g, '+');
    }
    return '';
  }, []);

  const fixedPreviewImageSrc = `https://placehold.co/${width}x${height}?text=${formatPromptForUrl(meta?.text)}`;

  const loadImage = useCallback(async () => {
    setResourceLoadError(false);

    try {
      const loadedResource = await new ImageResource(fixedPreviewImageSrc).load();
      if (!loadedResource) throw new Error(`Failed to load image. ${fixedPreviewImageSrc}`);

      setResource(loadedResource);
    } catch (error) {
      console.error(error);
      setResourceLoadError(true);
      setResource(null);
    }
  }, [fixedPreviewImageSrc]);

  useEffect(() => {
    loadImage();
  }, [loadImage]);

  useEffect(() => {
    const errors = [];

    if (resourceLoadError) {
      errors.push({
        title: 'Preview Unavailable',
        detail: 'Fixed preview image could not be loaded.',
      });
    }

    setClipErrors(errors);
  }, [resourceLoadError, setClipErrors]);

  if (!resource) {
    return <Container />;
  }

  return <CanvasImagePlayer id={id} index={index} resource={resource} />;
}

export default CanvasTextToImage;
