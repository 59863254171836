import { useRecoilState } from 'recoil';

import { IconFile } from '@assets/icons';

import InputSelect from '@components/controls/InputSelect';

import { formatAtom } from '@store/studio/Output';

import Formats from '@constants/Formats';

const DEFAULT_FORMAT = 'mp4';

function FormatSelect() {
  const [format, setFormat] = useRecoilState(formatAtom);

  const handleChange = (event) => {
    const { value } = event.target;
    setFormat(value.length > 0 ? value : DEFAULT_FORMAT);
  };

  return (
    <InputSelect
      id="format"
      title="Format"
      selected={format || DEFAULT_FORMAT}
      values={Formats}
      prefix={<IconFile size={18} />}
      handleChange={handleChange}
    />
  );
}

export default FormatSelect;
