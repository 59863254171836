import { Dropdown } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import { IconOverrideItem, IconOverrideItemSelected } from '@assets/icons';

import { mergeFamily } from '@store/atoms/EditState';

function OverrideMenuItem({ id, activeMergeKey, handleSelectOverride }) {
  const mergeField = useRecoilValue(mergeFamily(id));
  const isActive = activeMergeKey === mergeField.find;
  const isEmpty = mergeField.find === '';

  if (isEmpty) {
    return null;
  }

  return (
    <Dropdown.Item key={id} active={isActive} onClick={() => handleSelectOverride(mergeField)}>
      <span className="me-2">{isActive ? <IconOverrideItemSelected size={12} /> : <IconOverrideItem size={12} />}</span>
      <span>{mergeField.find}</span>
    </Dropdown.Item>
  );
}

export default OverrideMenuItem;
