import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import {
  IconAddMedia,
  IconCaptions,
  IconCode,
  IconElements,
  IconGear,
  IconMagicWand,
  IconMask,
  IconOverlay,
  IconText,
} from '@assets/icons';
import LogoEmblem from '@assets/img/logo/emblem.svg?react';

import WorkspaceSidebarPanelReactable from '@feature/studio/workspace/WorkspaceSidebarPanelReactable';

import { activeClipState } from '@store/atoms/ClipState';
import { sdkOptionsSelector } from '@store/atoms/SdkState';
import { studioActiveTabState } from '@store/atoms/UIState';

function getLogoComponent(isSdkCustomer, isLogoUrl, isSdkEnabled) {
  if (!isSdkCustomer || (isSdkCustomer && !isLogoUrl) || !isSdkEnabled) {
    return (
      <a href="https://shotstack.io" target="_blank" rel="noreferrer">
        <LogoEmblem />
      </a>
    );
  }
  return <img src={isLogoUrl} alt="Logo" />;
}

function getTopButtons(isSdkEnabled, logo) {
  const defaultButtons = [
    // { tabId: 'back', Icon: <LogoEmblem /> },
    { tabId: 'text', label: 'Text', Icon: <IconText size={20} /> },
    { tabId: 'media', label: 'Media', Icon: <IconAddMedia size={20} /> },
    { tabId: 'overlay', label: 'Overlay', Icon: <IconOverlay size={20} /> },
    { tabId: 'mask', label: 'Mask', Icon: <IconMask size={20} /> },
    { tabId: 'elements', label: 'Elements', Icon: <IconElements size={20} /> },
    { tabId: 'caption', label: 'Caption', Icon: <IconCaptions size={20} /> },
    { tabId: 'ai', label: 'AI', Icon: <IconMagicWand size={20} /> },
  ];

  const sdkButtons = [
    { tabId: 'back', Icon: logo },
    { tabId: 'text', label: 'Text', Icon: <IconText size={20} /> },
  ];

  return isSdkEnabled ? sdkButtons : defaultButtons;
}

function getBottomButtons(isSdkEnabled) {
  const defaultButtons = [{ tabId: 'json', label: 'JSON', Icon: <IconCode size={20} /> }];

  const commonButton = { tabId: 'setting', label: 'Settings', Icon: <IconGear size={20} /> };
  return isSdkEnabled ? [commonButton] : [...defaultButtons, commonButton];
}

function TabGroup({ buttons, activeTab, onTabClick }) {
  return (
    <div className="workspace__tabs">
      {buttons.map(({ tabId, label, Icon }) => (
        <button
          key={tabId}
          name={tabId}
          type="button"
          className={cn('workspace__tab', tabId, { active: activeTab === tabId })}
          onClick={onTabClick}
        >
          {Icon}
          {label && <span>{label}</span>}
        </button>
      ))}
    </div>
  );
}

function WorkspaceSidebar() {
  const navigate = useNavigate();
  const activeClipId = useRecoilValue(activeClipState);
  const [activeTab, setActiveTab] = useRecoilState(studioActiveTabState);
  const resetActiveClip = useResetRecoilState(activeClipState);
  const { isSdkEnabled, isSdkCustomer, isLogoUrl } = useRecoilValue(sdkOptionsSelector);

  const logo = getLogoComponent(isSdkCustomer, isLogoUrl, isSdkEnabled);
  const buttonsTop = getTopButtons(isSdkEnabled, logo);
  const buttonsBottom = getBottomButtons(isSdkEnabled);

  const handleTabClick = (event) => {
    const clickedTabId = event.currentTarget.name;
    if (clickedTabId === 'back') {
      if (!isSdkEnabled) {
        navigate('/studio/overview');
      }
      return;
    }
    const isJsonActiveClick = [clickedTabId, activeTab].every((t) => t === 'json');
    setActiveTab(isJsonActiveClick ? 'text' : clickedTabId);
    resetActiveClip(null);
  };

  return (
    <div className="workspace__sidebar">
      <div className={cn('workspace__tabs-container', { 'workspace__tabs-container--sdk': isSdkEnabled })}>
        <TabGroup buttons={buttonsTop} activeTab={activeTab} onTabClick={handleTabClick} />
        <TabGroup buttons={buttonsBottom} activeTab={activeTab} onTabClick={handleTabClick} />
      </div>
      <WorkspaceSidebarPanelReactable activeClipId={activeClipId} activeTab={activeTab} />
    </div>
  );
}
export default WorkspaceSidebar;
