import MediaSourceInput from '@components/controls/MediaSourceInput';

import { useAddMediaCallback } from '@store/studio/Media';

function PanelMediaUpload() {
  const addMedia = useAddMediaCallback();

  const onUploadComplete = (media) => {
    addMedia(media);
  };

  return <MediaSourceInput onUploadComplete={onUploadComplete} />;
}

export default PanelMediaUpload;
