import { Container, Graphics, Text } from '@inlet/react-pixi';
import { isNumber } from 'lodash-es';
import { TextStyle } from 'pixi.js';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import withCanvasMovable from '@feature/studio/canvas/CanvasMovable';

import ArtboardMask from '@components/masks/ArtboardMask';

import useFontCheck from '@hooks/useFontCheck';

import { COLOR, FONT_FAMILY, FONT_LINE_HEIGHT, TEXT_ALIGN } from '@constants/TextAssetDefaults';

import formatWebGlColor from '@utils/formatWebGlColor';

const MIN_FONT_SIZE = 1;

const MIN_LINE_HEIGHT = 0.1;

const CanvasTextPlayer = forwardRef((props, ref) => {
  const { clip, visible, x, y, width, height, alpha, scale, zIndex, angle } = props;
  const isFontLoaded = useFontCheck(clip['asset:font:family']);
  const maskRef = useRef(null);

  /** @type {ReturnType<typeof import('react').useState<{ [key: string]: any }>>} */
  const [textAnchorX, setTextAnchorX] = useState(0.5);
  const [textAnchorY, setTextAnchorY] = useState(0.5);
  const [textOffsetX, setTextOffsetX] = useState(0);
  const [textOffsetY, setTextOffsetY] = useState(0);

  const {
    ['asset:text']: text,
    ['asset:alignment:horizontal']: horizontalAlign = TEXT_ALIGN,
    ['asset:alignment:vertical']: verticalAlign = TEXT_ALIGN,
    ['asset:font:color']: color = COLOR,
    ['asset:font:family']: fontFamily = FONT_FAMILY,
    ['asset:font:size']: fontSize = MIN_FONT_SIZE,
    ['asset:font:lineHeight']: lineHeight = FONT_LINE_HEIGHT,
    // ['asset:font:opacity']: fontOpacity = 1,
    ['asset:background:color']: backgroundColor,
    // ['asset:background:opacity']: backgroundOpacity = 1,
    // ['asset:background:padding']: backgroundPadding = 0,
    // ['asset:background:borderRadius']: backgroundBorderRadius,
  } = clip;

  // Todo: Remove when backend is ready
  const fontOpacity = 1;
  const backgroundOpacity = 1;
  const backgroundPadding = 0;
  const backgroundBorderRadius = 0;

  let bgColor = null;
  let bgOpacity = 0.0001; // if bgOpacity is 0, element is not interactive, can not be dragged.

  if (backgroundColor && backgroundColor !== 'transparent') {
    bgColor = formatWebGlColor(backgroundColor);
    bgOpacity = backgroundOpacity;
  }

  const boundingBox = useCallback(
    (graphics) => {
      graphics.clear();
      graphics.beginFill(bgColor, bgOpacity);
      graphics.drawRoundedRect(-width / 2, -height / 2, width, height, backgroundBorderRadius);
      graphics.endFill();
    },
    [bgColor, bgOpacity, width, height, backgroundBorderRadius]
  );

  const boundingBoxMaskRef = useRef();
  useEffect(() => {
    switch (horizontalAlign) {
      case 'left':
        setTextAnchorX(0);
        setTextOffsetX(-width / 2);
        break;
      case 'right':
        setTextAnchorX(1);
        setTextOffsetX(width / 2);
        break;
      default:
        setTextAnchorX(0.5);
        setTextOffsetX(0);
        break;
    }
  }, [horizontalAlign, width]);

  useEffect(() => {
    switch (verticalAlign) {
      case 'center':
        setTextAnchorY(0.5);
        setTextOffsetY(0);
        break;
      case 'top':
        setTextAnchorY(0);
        setTextOffsetY(-height / 2);
        break;
      case 'bottom':
        setTextAnchorY(1);
        setTextOffsetY(height / 2);
        break;
      default:
        setTextAnchorY(0.5);
        setTextOffsetY(0);
        break;
    }
  }, [verticalAlign, height]);

  useEffect(() => {
    if (ref.current && maskRef.current) {
      ref.current.mask = maskRef.current;
    }
  }, [ref]);

  const textStyle = new TextStyle({
    align: horizontalAlign || TEXT_ALIGN,
    fontFamily: fontFamily || FONT_FAMILY,
    fontSize: `${fontSize}px`,
    fill: color || COLOR,
    lineHeight: lineHeight === 0 ? MIN_LINE_HEIGHT : lineHeight ? fontSize * lineHeight : fontSize * FONT_LINE_HEIGHT,
    wordWrap: true,
    wordWrapWidth: width - 2 * backgroundPadding || 1,
    padding: backgroundPadding,
    textBaseline: 'alphabetic',
  });

  return (
    <>
      <Container
        ref={ref}
        anchor={0.5}
        visible={visible}
        x={x}
        y={y}
        width={width}
        height={height}
        alpha={alpha}
        scale={isNumber(scale) ? scale : 1}
        zIndex={zIndex}
        angle={angle}
      >
        <Graphics draw={boundingBox} />
        <Graphics name="mask" draw={boundingBox} ref={boundingBoxMaskRef} />
        {text && isFontLoaded ? (
          <Text
            text={text}
            anchor={[textAnchorX, textAnchorY]}
            x={textOffsetX}
            y={textOffsetY}
            style={textStyle}
            alpha={fontOpacity}
          />
        ) : null}
      </Container>
      <ArtboardMask ref={maskRef} />
    </>
  );
});

export default withCanvasMovable(CanvasTextPlayer);
