import { Suspense, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import '@css/GettingStarted.css';

import { useAuth0 } from '@context/Auth0ReactSPA';

import GettingStartedBody from '@feature/welcome/GettingStartedBody';
import GettingStartedProgress from '@feature/welcome/GettingStartedProgress';

import { useAnalytics } from '@components/analytics/Analytics';
import ErrorBoundary from '@components/error/ErrorBoundary';
import Layout from '@components/layout/Layout';
import Loader from '@components/loader/Loader';

import { useHydrateStoreFromTemplate } from '@store/atoms/EditState';
import { stageAtom } from '@store/atoms/StageState';
import { allTemplatesState, gettingStartedTemplateState, templateState } from '@store/selectors/TemplateSelectors';

const gettingStartedSteps = {
  1: {
    label: 'Template',
    title: 'Render your first template',
    text: 'Execute the code below in your terminal',
  },
  2: {
    label: 'Merge data',
    title: `Now, let's merge some data`,
    text: 'Enter a name in the field, then execute the code below in your terminal',
  },
  3: {
    label: 'Finish 🎉',
    title: 'All done!',
    text: 'Here are your keys to get started with the API. You can also find them at <code>/keys</code>.',
  },
};

function GettingStarted() {
  const { loading, user } = useAuth0();
  const history = useNavigate();
  const { trackEvent } = useAnalytics();

  const setTemplateState = useSetRecoilState(templateState);
  const setStageState = useSetRecoilState(stageAtom);
  const refreshAllTemplateState = useRecoilRefresher_UNSTABLE(allTemplatesState);
  const hydrateStore = useHydrateStoreFromTemplate();
  const { state, contents } = useRecoilValueLoadable(gettingStartedTemplateState);

  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    trackEvent('View Getting Started');
    setStageState('v1');
  }, []);

  useEffect(() => {
    if (state !== 'hasValue') {
      return;
    }

    const { data, isExisting } = contents;
    const { id, name, template } = data || {};
    setTemplateState({ id, name });
    hydrateStore(template);

    if (!isExisting) {
      refreshAllTemplateState();
    }
  }, [contents, hydrateStore, refreshAllTemplateState, setTemplateState, state]);

  const handleNext = () => {
    window.scrollTo(0, 0);
    setActiveStep(activeStep + 1);
  };

  if (loading || !user || state === 'loading') {
    return <Loader />;
  }

  if (state === 'hasError') {
    console.error({ contents });
    return null;
  }

  const handleComplete = () => {
    trackEvent('Select Complete - Getting Started', {
      Context: 'Button',
      properties: {
        Version: 1,
        'Step Number': activeStep,
      },
    });

    history('/');
  };

  return (
    <Layout>
      <ErrorBoundary>
        <Suspense fallback={<div />}>
          <div className="welcome">
            <div className="welcome__message">
              <h1>Hi {user.name?.split(' ')[0] || user.nickname} 👋</h1>
              <p className="mt-2 lead">Let&apos;s get started with Shotstack!</p>
            </div>
            <GettingStartedProgress steps={gettingStartedSteps} activeStep={activeStep} />
            <GettingStartedBody
              steps={gettingStartedSteps}
              activeStep={activeStep}
              onNext={handleNext}
              onComplete={handleComplete}
            />
          </div>
        </Suspense>
      </ErrorBoundary>
    </Layout>
  );
}

export default GettingStarted;
