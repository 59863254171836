import { memo, useCallback, useRef } from 'react';
import reactable from 'reactablejs';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import Track from '@feature/studio/timeline/TimelineTrack';

import { activeClipState, activeTrackState } from '@store/atoms/ClipState';
import { idState } from '@store/atoms/TemplateState';
import { timelineScaleState } from '@store/atoms/TimelineState';

import { TRACK_HEIGHT, TRACK_PADDING } from '@constants/Timeline';

import getTransformStyleValues from '@utils/getTransformStyleValues';
import { isNumber } from '@utils/isNumber';
import roundToPrecision from '@utils/math/roundToPrecision';

const ReactableTrackComponent = reactable(Track);

const dropzoneOptions = {
  accept: '.clip, .track',
};
const position = { y: 0 };
const draggableOptions = {
  inertia: false,
  autoScroll: true,
  startAxis: 'y',
  lockAxis: 'y',
  allowFrom: '.track__drag-handle',
  listeners: {
    move(event) {
      const { target } = event;
      position.y += event.dy;
      target.style.transform = `translate(0px, ${position.y}px)`;
    },
  },
};

function TrackReactable({ id, index, moveTrack, moveClip, resetClip }) {
  const timelineScale = useRecoilValue(timelineScaleState);
  const templateId = useRecoilValue(idState);
  const [activeTrackId, setActiveTrackId] = useRecoilState(activeTrackState);
  const resetActiveClip = useResetRecoilState(activeClipState);
  const cloneRef = useRef(null);

  const tracking = {
    properties: {
      Id: templateId,
      'Track Id': id,
    },
  };

  const onDropzoneActivate = useCallback((event) => {
    const { target: dropzoneElement } = event;
    dropzoneElement.classList.add('dropzone');
  }, []);

  const onDragEnter = useCallback((event) => {
    const { relatedTarget: draggableElement, target: dropzoneElement } = event;
    // feedback the possibility of a drop
    dropzoneElement.classList.add('dropzone-active');
    draggableElement.classList.add('dragging');
  }, []);

  const onDragLeave = useCallback((event) => {
    const { target: dropzoneElement } = event;
    dropzoneElement.classList.remove('dropzone-active');
  }, []);

  const onDropClip = useCallback(
    (event) => {
      const { relatedTarget: draggableElement, target: dropzoneElement } = event;
      const clipId = draggableElement.getAttribute('data-clip-id');
      const { x: clipX } = getTransformStyleValues(draggableElement.style.transform);
      if (!clipId || !isNumber(clipX)) {
        resetClip(clipId, id);
        return;
      }

      const toTrackIndex = +dropzoneElement.getAttribute('data-track-index');
      const newStartTime = roundToPrecision(parseFloat(clipX) / timelineScale);
      const updatedClip = moveClip(clipId, newStartTime, id, toTrackIndex);

      if (!updatedClip) {
        resetClip(clipId, id);
        return;
      }
      const clipOffsetTop = toTrackIndex * TRACK_HEIGHT + TRACK_PADDING;
      const clipOffsetLeft = roundToPrecision(updatedClip.start * timelineScale);

      draggableElement.style.transform = `translate(${clipOffsetLeft}px, ${clipOffsetTop}px)`;

      // remove active dropzone feedback
      draggableElement.classList.remove('dragging');
      dropzoneElement.classList.remove('dropzone-active');
    },
    [id, moveClip, resetClip, timelineScale]
  );

  // fires for all tracks
  const onDropzoneDeactivate = useCallback(
    (event) => {
      const { relatedTarget: draggableElement, target: dropzoneElement, currentTarget } = event;
      const clipId = draggableElement.getAttribute('data-clip-id');
      const trackId = draggableElement.getAttribute('data-track-id');

      // if dropped outside a dropzone, reset the clip
      if (clipId && trackId === id && !currentTarget) {
        resetClip(clipId, id);
      }

      // remove active dropzone feedback
      draggableElement.classList.remove('dragging');
      dropzoneElement.classList.remove('dropzone-active');
    },
    [id, resetClip]
  );

  const onTrackDown = useCallback((event) => {
    event.preventDefault();
    const {
      currentTarget,
      interaction,
      _interaction,
      interactable: {
        target: { parentElement },
      },
    } = event;

    const { pointerIsDown, interacting, start } = interaction;
    const { downEvent } = _interaction;

    const isDragHandle = downEvent?.target.classList.contains('track__drag-handle');

    setActiveTrackId(id);
    resetActiveClip();

    if (pointerIsDown && isDragHandle && !interacting()) {
      cloneRef.current = {
        node: currentTarget.cloneNode(true),
        original: currentTarget,
      };

      // Add absolute positioning so that cloned track is right on top of the original track
      cloneRef.current.node.style.position = 'absolute';
      cloneRef.current.node.style.left = 0;
      cloneRef.current.node.style.top = 0;
      cloneRef.current.node.classList.add('track-clone');

      cloneRef.current.original.classList.add('track-placeholder');

      const { offsetTop } = currentTarget;
      position.y = offsetTop;
      cloneRef.current.node.style.transform = `translate(0px, ${position.y}px)`;

      // Add the cloned track to the document
      parentElement.appendChild(cloneRef.current.node);

      // Start the drag event on the cloned track
      start({ name: 'drag' }, event.interactable, cloneRef.current.node);
    }
  }, []);

  const onTrackDragEnd = useCallback(
    (event) => {
      const { target: draggableElement, relatedTarget: dropzoneElement } = event;
      const fromIndex = parseInt(draggableElement?.getAttribute('data-track-index'), 10);
      const toIndex = parseInt(dropzoneElement?.getAttribute('data-track-index'), 10);

      if (fromIndex !== toIndex) {
        moveTrack(fromIndex, toIndex);
      }

      cloneRef.current.node.remove();
      cloneRef.current.original.classList.remove('track-placeholder');
      cloneRef.current = null;
    },
    [moveTrack]
  );

  return (
    <ReactableTrackComponent
      dropzone={{
        ...dropzoneOptions,
        // Bug in reactableJs. This only works in the options object
        ondropdeactivate: onDropzoneDeactivate,
      }}
      onDropActivate={onDropzoneActivate}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDropClip}
      draggable={draggableOptions}
      onDown={onTrackDown}
      onDragEnd={onTrackDragEnd}
      id={id}
      active={id === activeTrackId}
      index={index}
      tracking={tracking}
    />
  );
}

export default memo(TrackReactable);
