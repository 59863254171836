import { Card } from 'react-bootstrap';

function RenderPreviewVideo({ render }) {
  const previewUrl = `${render.url}#t=1.5`;

  return (
    <Card className="mb-2 editor-thumbnail-container">
      <div className="editor-thumbnail d-flex justify-content-center align-items-center">
        <video key={render.url} preload="metadata" controls>
          <source src={previewUrl} type="video/mp4" />
        </video>
      </div>
    </Card>
  );
}

export default RenderPreviewVideo;
