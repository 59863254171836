export default [
  { value: 'Hala', text: 'Hala (Arabic, Gulf)' },
  { value: 'Lisa', text: 'Lisa (Dutch, Belgian)' },
  { value: 'Arlet', text: 'Arlet (Catalan)' },
  { value: 'Hiujin', text: 'Hiujin (Chinese, Cantonese)' },
  { value: 'Zhiyu', text: 'Zhiyu (Chinese, Mandarin)' },
  { value: 'Sofie', text: 'Sofie (Danish)' },
  { value: 'Laura', text: 'Laura (Dutch)' },
  { value: 'Olivia', text: 'Olivia (English, Australian)' },
  { value: 'Amy', text: 'Amy (English, British)' },
  { value: 'Emma', text: 'Emma (English, British)' },
  { value: 'Brian', text: 'Brian (English, British)' },
  { value: 'Arthur', text: 'Arthur (English, British)' },
  { value: 'Kajal', text: 'Kajal (English, Indian)' },
  { value: 'Niamh', text: 'Niamh (English, Ireland)' },
  { value: 'Aria', text: 'Aria (English, New Zealand)' },
  { value: 'Ayanda', text: 'Ayanda (English, South African)' },
  { value: 'Ivy', text: 'Ivy (English, US)' },
  { value: 'Joanna', text: 'Joanna (English, US)' },
  { value: 'Kendra', text: 'Kendra (English, US)' },
  { value: 'Kimberly', text: 'Kimberly (English, US)' },
  { value: 'Salli', text: 'Salli (English, US)' },
  { value: 'Joey', text: 'Joey (English, US)' },
  { value: 'Justin', text: 'Justin (English, US)' },
  { value: 'Kevin', text: 'Kevin (English, US)' },
  { value: 'Matthew', text: 'Matthew (English, US)' },
  { value: 'Ruth', text: 'Ruth (English, US)' },
  { value: 'Stephen', text: 'Stephen (English, US)' },
  { value: 'Suvi', text: 'Suvi (Finnish)' },
  { value: 'Léa', text: 'Léa (French)' },
  { value: 'Rémi', text: 'Rémi (French)' },
  { value: 'Gabrielle', text: 'Gabrielle (French, Canadian)' },
  { value: 'Liam', text: 'Liam (French, Canadian)' },
  { value: 'Vicki', text: 'Vicki (German)' },
  { value: 'Daniel', text: 'Daniel (German)' },
  { value: 'Hannah', text: 'Hannah (German, Austrian)' },
  { value: 'Bianca', text: 'Bianca (Italian)' },
  { value: 'Adriano', text: 'Adriano (Italian)' },
  { value: 'Takumi', text: 'Takumi (Japanese)' },
  { value: 'Kazuha', text: 'Kazuha (Japanese)' },
  { value: 'Tomoko', text: 'Tomoko (Japanese)' },
  { value: 'Seoyeon', text: 'Seoyeon (Korean)' },
  { value: 'Ida', text: 'Ida (Norwegian)' },
  { value: 'Ola', text: 'Ola (Polish)' },
  { value: 'Camila', text: 'Camila (Portuguese, Brazilian)' },
  { value: 'Vitória/Vitoria', text: 'Vitória/Vitoria (Portuguese, Brazilian)' },
  { value: 'Thiago', text: 'Thiago (Portuguese, Brazilian)' },
  { value: 'Inês/Ines', text: 'Inês/Ines (Portuguese, European)' },
  { value: 'Lucia', text: 'Lucia (Spanish, European)' },
  { value: 'Sergio', text: 'Sergio (Spanish, European)' },
  { value: 'Mia', text: 'Mia (Spanish, Mexican)' },
  { value: 'Andrés', text: 'Andrés (Spanish, Mexican)' },
  { value: 'Lupe', text: 'Lupe (Spanish, US)' },
  { value: 'Pedro', text: 'Pedro (Spanish, US)' },
  { value: 'Elin', text: 'Elin (Swedish)' },
];
