import { Badge } from 'react-bootstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { IconCollectionPlay } from '@assets/icons';

import ButtonElement from '@components/atoms/ButtonElement';

import { templateJsonEditState } from '@store/atoms/EditState';
import { rendersPanelState, rendersState } from '@store/atoms/RendersState';

function RenderStatus() {
  const renders = useRecoilValue(rendersState);
  const setDisplayPanel = useSetRecoilState(rendersPanelState);
  const templateJsonReady = useRecoilValue(templateJsonEditState);

  const handleClick = () => {
    setDisplayPanel(true);
  };

  return (
    <ButtonElement handleEvent={handleClick} variant="light" className="render-status" disabled={!templateJsonReady}>
      <IconCollectionPlay size={18} className="mr-1" />
      <Badge pill bg="primary" className="render-status-badge">
        {renders.length}
      </Badge>
    </ButtonElement>
  );
}

export default RenderStatus;
