import config from '@config';
import axios from 'axios';
import { Deserializer } from 'jsonapi-serializer';
import { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import { authToken } from '@store/atoms/AuthState';

const INTEGRATION_ENDPOINT = 'integration/vimeo';
const SAVE_ERROR =
  'There was an error connecting to your Vimeo account. Please try again or contact us if the issue continues.';
const LOAD_ERROR =
  'There was an error retrieving your Vimeo credentials. Please try again or contact us if the issue continues.';
const DELETE_ERROR =
  'There was an error deleting your Vimeo integration. Please try again or contact us if the issue continues.';

const VIMEO_AUTH_ENDPOINT = 'https://api.vimeo.com/oauth/authorize';
const REDIRECT_URI = config.oauth.vimeo.redirectUri;
const CLIENT_ID = config.oauth.vimeo.clientId;
const SCOPE = config.oauth.vimeo.scope;

function VimeoForm({ stage, stageName, index }) {
  const bearerToken = useRecoilValue(authToken);
  const [isConnected, setIsConnected] = useState(false);
  const [isBusy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const generateAuthUrl = () => {
    const params = new URLSearchParams({
      response_type: 'code',
      client_id: CLIENT_ID,
      redirect_uri: `${REDIRECT_URI}/${stage}/`,
      scope: SCOPE,
    });

    return `${VIMEO_AUTH_ENDPOINT}?${params.toString()}`;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setBusy(true);

    window.location.href = generateAuthUrl();
  };

  const handleDelete = async () => {
    if (!bearerToken) {
      setErrorMessage('Not authorized');
      return;
    }

    setIsDeleting(true);
    setErrorMessage('');

    try {
      await axios.delete(`${config.integration.url}${INTEGRATION_ENDPOINT}`, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
        data: { stage },
      });

      setIsConnected(false);
    } catch (error) {
      console.error(error);
      setErrorMessage(DELETE_ERROR);
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    const extractCodeFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('code');
    };

    const extractStageFromUrl = () => {
      const url = new URL(window.location.href);
      const pathSegments = url.pathname.split('/');
      const stage = pathSegments[pathSegments.length - 2];
      return ['dev', 'stage', 'v1'].includes(stage) ? stage : null;
    };

    const code = extractCodeFromUrl();
    const stageFromUrl = extractStageFromUrl();

    const fetchAndSetAccessToken = async () => {
      if (!code || !stageFromUrl) return;
      if (!bearerToken) throw new Error('Not authorized');
      if (stageFromUrl !== stage) return;

      setBusy(true);

      const payload = {
        credentials: {
          authorizationCode: code,
        },
        stage: stageFromUrl,
      };

      try {
        const response = await axios.put(`${config.integration.url}${INTEGRATION_ENDPOINT}`, payload, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });

        if (response.data === '') {
          setErrorMessage(SAVE_ERROR);
          return;
        }

        setIsConnected(true);
      } catch (error) {
        console.error(error);
        setErrorMessage(SAVE_ERROR);
      } finally {
        setBusy(false);
      }
    };

    fetchAndSetAccessToken();
  }, []);

  useEffect(() => {
    async function getCredentials() {
      if (bearerToken && bearerToken.length) {
        setBusy(true);
        try {
          const response = await axios.get(config.integration.url + INTEGRATION_ENDPOINT, {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          });

          new Deserializer({ keyForAttribute: 'camelCase' }).deserialize(response.data, (error, data) => {
            if (error) throw error;

            const integration = Array.isArray(data) ? data.find((integration) => integration.stage === stage) : null;
            setIsConnected(Boolean(integration));
            setIsReady(true);
          });
        } catch (error) {
          console.error(error);
          setErrorMessage(LOAD_ERROR);
        } finally {
          setBusy(false);
        }
      }
    }

    getCredentials();
  }, [index, bearerToken, stage]);

  return (
    <Row className="mb-4">
      <Col>
        <Card>
          <Card.Body>
            {!isReady && <Spinner animation="border" variant="secondary" />}
            {isReady && (
              <>
                <Row>
                  <Col className="mb-2">
                    <b>{stageName}</b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {isConnected ? (
                      <>
                        <Button variant="primary" disabled className="me-2">
                          Connected
                        </Button>
                        <Button variant="danger" onClick={handleDelete} disabled={isDeleting}>
                          {isDeleting ? (
                            <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
                          ) : (
                            'Delete Connection'
                          )}
                        </Button>
                      </>
                    ) : (
                      <Form onSubmit={(event) => handleSubmit(event, stage)} id="vimeoCredentialsForm">
                        <Button variant="primary" type="submit" disabled={isBusy}>
                          {isBusy ? (
                            <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
                          ) : (
                            'Connect to Vimeo'
                          )}
                        </Button>
                      </Form>
                    )}
                  </Col>
                </Row>
                {errorMessage && (
                  <Alert variant="danger" onClose={() => setErrorMessage('')} dismissible>
                    {errorMessage}
                  </Alert>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default VimeoForm;
