import { useRecoilState } from 'recoil';

import { IconScale } from '@assets/icons';

import InputSelect from '@components/controls/InputSelect';

import { scaleToAtom } from '@store/studio/Output';

import Resolutions from '@constants/Resolutions';

const SCALE_TO_OPTIONS = [
  {
    value: '',
    text: 'No Scaling',
  },
  ...Resolutions,
];

const DEFAULT_SCALE_TO = '';

function ScaleToSelect({}) {
  const [scaleTo, setScaleTo] = useRecoilState(scaleToAtom);

  const handleScaleToChange = (event) => {
    const { value } = event.target;
    setScaleTo(value || DEFAULT_SCALE_TO);
  };

  return (
    <InputSelect
      id="scale-to"
      title="Scale To"
      selected={scaleTo || DEFAULT_SCALE_TO}
      values={SCALE_TO_OPTIONS}
      prefix={<IconScale size={18} />}
      handleChange={handleScaleToChange}
    />
  );
}

export default ScaleToSelect;
