import { debounce } from 'lodash-es';
import { useEffect, useRef } from 'react';
import { Badge } from 'react-bootstrap';
import { Scrollbar } from 'react-scrollbars-custom';
import { useRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import { IconEnd, IconStart } from '@assets/icons';

import { playheadState } from '@store/atoms/PlayheadState';

import formatTimecode from '@utils/formatTimeCode';

function findCurrentCaptionIndex(captions, playhead, trim) {
  return (captions || []).findIndex((caption) => {
    if (caption.start === undefined || caption.length === undefined) return false;

    const adjustedStart = caption.start - (trim || 0);
    return playhead >= adjustedStart && playhead < adjustedStart + caption.length;
  });
}

function CaptionItem({ caption, playhead, onCaptionClick }) {
  const isActive = playhead >= caption.start && playhead < caption.start + caption.length;

  return (
    <div className="mb-4">
      <div className="caption__times">
        <CaptionTimeButton
          time={caption.start}
          icon={<IconStart size={12} className="mr-2" />}
          isActive={isActive}
          onClick={onCaptionClick}
        />
        <CaptionTimeButton
          time={caption.start + caption.length}
          icon={<IconEnd size={12} className="mr-2" />}
          onClick={onCaptionClick}
        />
      </div>
      <div className="caption__text text-sm">{caption.text}</div>
    </div>
  );
}

function CaptionTimeButton({ time, icon, isActive = false, onClick }) {
  return (
    <button type="button" className="unstyled" onClick={() => onClick(time)}>
      <Badge className={`bg-subtle mr-2 ${isActive ? 'active' : ''}`}>
        {icon} {formatTimecode(time)}
      </Badge>
    </button>
  );
}

function scrollToElement(scrollbar, index) {
  const { contentElement, scrollerElement } = scrollbar;
  const element = contentElement?.children[index];

  if (!element) return;

  scrollerElement.scrollTo({
    behavior: 'smooth',
    top: element.offsetTop,
  });
}

function CaptionList({ captions, trim }) {
  const [playhead, setPlayhead] = useRecoilState(playheadState);
  const currentCaptionIndexRef = useRef(null);
  const scrollbarRef = useRef(null);

  const handleCaptionClick = (time) => {
    if (time) setPlayhead(time);
  };

  const scrollToCaptionElement = () => {
    if (!scrollbarRef.current) return;

    const newCaptionIndex = findCurrentCaptionIndex(captions, playhead, trim);
    if (newCaptionIndex === currentCaptionIndexRef.current || newCaptionIndex === -1) return;

    currentCaptionIndexRef.current = newCaptionIndex;
    scrollToElement(scrollbarRef.current, newCaptionIndex);
  };

  const debouncedScrollToCaptionElement = debounce(scrollToCaptionElement, 200, {
    maxWait: 3000,
    leading: false,
    trailing: true,
  });

  useEffect(() => {
    debouncedScrollToCaptionElement();
  }, [playhead, captions]);

  return (
    <div className="captions-list">
      <Scrollbar ref={scrollbarRef} noScrollX style={{ width: '100%', height: '200px' }}>
        {(captions || []).map((caption) => (
          <CaptionItem key={uuid()} caption={caption} playhead={playhead} onCaptionClick={handleCaptionClick} />
        ))}
      </Scrollbar>
    </div>
  );
}

export default CaptionList;
