import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import { getAllIntegrations } from '@api/services/integration';

import SettingDestination from '@feature/studio/setting/SettingDestination';

import Skeleton from '@components/skeletons/Skeleton';

import { destinationIdsAtom } from '@store/studio/Output';

function Connect() {
  const destinationIds = useRecoilValue(destinationIdsAtom);
  const [integrations, setIntegrations] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getIntegrationsData = async () => {
      const integrations = await getAllIntegrations();
      setIntegrations(integrations);
      setIsLoading(false);
    };

    getIntegrationsData();
  }, []);

  return (
    <div className="max-w-screen-lg mx-auto p-10">
      <p className="text-lg text-center font-bold mb-8">
        Connect to hosting providers and social media platforms to distribute your videos automatically.
      </p>

      <div className="d-flex flex-col gap-4">
        {isLoading ? (
          <Skeleton length={6} />
        ) : (
          destinationIds.map((id) => (
            <Card key={id}>
              <Card.Body>
                <SettingDestination id={id} integrations={integrations} />
              </Card.Body>
            </Card>
          ))
        )}
      </div>
    </div>
  );
}

export default Connect;
