import { atom } from 'recoil';

export const activeClipState = atom({
  key: 'activeClipState',
  default: null,
});

export const activeTrackState = atom({
  key: 'activeTrackState',
  default: null,
});
