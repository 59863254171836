import { useRef } from 'react';
import { Dropdown } from 'react-bootstrap';

import '@css/FontsMenu.css';

function FontsMenu({ fonts, selected, onSelect }) {
  const menuToggleRef = useRef(null);
  const fontEntries = Object.entries(fonts);

  return (
    <Dropdown className="fonts-menu">
      <Dropdown.Toggle ref={menuToggleRef} variant="light">
        <span style={{ fontFamily: selected }}>{selected}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu align="end">
        {fontEntries.map(([groupLabel, groupOptions], index) => (
          <div key={groupLabel}>
            <Dropdown.Header className="uppercase-label">{groupLabel.toUpperCase()}</Dropdown.Header>
            {groupLabel === 'uploaded' && groupOptions.length === 0 && (
              <Dropdown.Item disabled>
                Uploaded custom fonts <br />
                will appear here
              </Dropdown.Item>
            )}
            {groupOptions.map((option) => (
              <Dropdown.Item
                key={option.family}
                active={selected === option.family}
                onClick={() => onSelect(option.family)}
                style={{ fontFamily: option.family }}
              >
                {option.label}
              </Dropdown.Item>
            ))}
            {index < fontEntries.length - 1 && <Dropdown.Divider />}
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default FontsMenu;
