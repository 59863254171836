import { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import ButtonElement from '@components/atoms/ButtonElement';

import { showStageConfirmationModalAtom } from '@store/atoms/StageState';
import { useSaveTemplate } from '@store/atoms/TemplateState';
import { templateState } from '@store/selectors/TemplateSelectors';

import capitaliseWords from '@utils/text/capitaliseWords';

const stages = {
  dev: 'Development',
  stage: 'Sandbox',
  v1: 'Production',
};

function StageConfirmation() {
  const navigate = useNavigate();
  const [selectedStage, setSelectedStage] = useRecoilState(showStageConfirmationModalAtom);
  const { id, name } = useRecoilValue(templateState);
  const saveTemplate = useSaveTemplate();
  const [saving, setSaving] = useState(false);

  const selectedStageName = stages[selectedStage];

  const tracking = {
    name: 'Select Copy Template',
    properties: {
      Id: id,
      'Template Name': name,
      'Copy To': capitaliseWords(selectedStage),
    },
  };

  const onCancel = () => {
    setSelectedStage(false);
    setSaving(false);
  };

  const onConfirmation = async () => {
    if (saving) {
      return;
    }

    setSaving(true);
    const res = await saveTemplate({ stage: selectedStage });
    navigate(`/studio/editor/${res.id}`);

    setSelectedStage(false);
    setSaving(false);
  };

  return (
    <Row className="my-5">
      <Col md={{ span: 6, offset: 3 }}>
        <Card>
          <Card.Body className="px-5">
            <Card.Title className="fw-bold mt-3 mb-4">
              Copy template to your {selectedStageName} environment?
            </Card.Title>
            <Card.Text>
              Switching environments will copy the {`"${name}"`} template to your {selectedStageName} environment.
            </Card.Text>
            <Row className="mt-4 mb-3">
              <Col className="float-left text-start">
                <ButtonElement
                  className="me-3"
                  handleEvent={onConfirmation}
                  loading={saving}
                  disabled={saving}
                  variant="primary"
                  style={{ minWidth: 100 }}
                  tracking={tracking}
                >
                  Continue
                </ButtonElement>
                <ButtonElement disabled={saving} handleEvent={onCancel} variant="light">
                  Cancel
                </ButtonElement>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default StageConfirmation;
