import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { deleteTemplates } from '@api/services/template';

import ButtonElement from '@components/atoms/ButtonElement';

import { bulkTemplateDeleteState } from '@store/atoms/TemplateState';
import { useRemoveTemplatesFromListState } from '@store/selectors/TemplateSelectors';

function TemplateDeleteBulk() {
  const [loading, setLoading] = useState(false);
  const bulkTemplateDelete = useRecoilValue(bulkTemplateDeleteState);
  const removeTemplatesFromList = useRemoveTemplatesFromListState();

  const handleDeleteTemplates = async () => {
    setLoading(true);
    await deleteTemplates(bulkTemplateDelete);
    // todo: handle failed delete
    removeTemplatesFromList();
    setLoading(false);
  };

  return (
    <ButtonElement
      handleEvent={handleDeleteTemplates}
      variant="danger"
      loading={loading}
      disabled={loading || bulkTemplateDelete.length === 0}
    >
      {bulkTemplateDelete.length === 0
        ? 'Bulk Delete Templates'
        : `Bulk Delete ${bulkTemplateDelete.length} Template${bulkTemplateDelete.length > 1 ? 's' : ''}`}
    </ButtonElement>
  );
}

export default TemplateDeleteBulk;
