import cn from 'classnames';

import '@css/TimelineTrack.css';

import { IconDrag } from '@assets/icons';

function TimelineTrack({ id, active, index, getRef }) {
  return (
    <div ref={getRef} className={cn('track', { active })} data-track-id={id} data-track-index={index}>
      <div className="track__header">
        <div className="track__drag-handle">
          <IconDrag size={26} />
        </div>
      </div>

      <div className="track__subtracks">
        <div className="track__subtrack track__subtrack--mask" />
        <div className="track__subtrack" />
      </div>
    </div>
  );
}

export default TimelineTrack;
