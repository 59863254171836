import { memo } from 'react';

import { IconImage, IconMagicWand } from '@assets/icons';

import TimelineClipInfo from '@feature/studio/timeline/TimelineClipInfo';

function TimelineClipTextToImage({ id }) {
  return (
    <div className="clip__inner">
      <div className="clip__header">
        <span className="clip__icon">
          <IconMagicWand size={18} />
        </span>
        <IconImage size={18} />
        <TimelineClipInfo id={id} />
      </div>
    </div>
  );
}

export default memo(TimelineClipTextToImage);
