import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { getBalance } from '@api/services/balance';

import Skeleton from '@components/skeletons/Skeleton';

import { authenticatedUser, userDetailsSelector } from '@store/atoms/AuthState';
import { subscriberSelector } from '@store/atoms/SubscriptionsState';

const getDaysRemaining = ({ tier, usageCycle }) => {
  if (tier === 'freeTrial' && usageCycle?.end) {
    const remaining = dayjs(usageCycle.end).diff(dayjs(), 'day');
    return remaining > 0 ? { expired: false, remaining } : { expired: true };
  }
  return null;
};

const FreeTrialIndicator = ({ subscriptionName }) => (
  <div className="text-right">
    <span className="font-bold">{subscriptionName} </span>
  </div>
);

const PlanIndicator = ({ subscriptionName }) => (
  <div className="text-right">
    <span className="font-bold">Your Plan:</span> {subscriptionName}
  </div>
);

const CreditsIndicator = ({ creditBalance }) => (
  <div className="d-flex gap-1 justify-content-end">
    <span className="font-bold">Credits Remaining</span>
    <span>{creditBalance}</span>
  </div>
);

function PlanCreditsIndicator() {
  const navigate = useNavigate();
  const authUser = useRecoilValue(authenticatedUser);
  const userDetails = useRecoilValueLoadable(userDetailsSelector);
  const subscriber = useRecoilValueLoadable(subscriberSelector);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [creditRatio, setCreditRatio] = useState(1);
  const [loading, setLoading] = useState(true);

  const { tier, usageCycle } = userDetails.contents || {};
  const { subscription } = subscriber.contents || {};
  const isFreeTrial = tier === 'freeTrial';
  const daysRemaining = getDaysRemaining({ tier, usageCycle });

  const creditLevelIndicator = creditRatio > 20 ? 'success' : creditRatio > 5 ? 'warning' : 'danger';

  useEffect(() => {
    const fetchBalanceData = async () => {
      const response = await getBalance();
      const totalCurrentBalance = response.reduce((acc, { current }) => acc + current, 0);
      const totalInitialBalance = response.reduce((acc, { initial }) => acc + initial, 0);

      setCurrentBalance(totalCurrentBalance.toFixed(2));
      setCreditRatio(totalInitialBalance ? ((totalCurrentBalance / totalInitialBalance) * 100).toFixed(2) : 0);
      setLoading(false);
    };
    fetchBalanceData();
  }, []);

  if (userDetails.state === 'loading' || subscriber.state === 'loading') {
    return <Skeleton height="33px" width="200px" />;
  }

  if (userDetails.state === 'hasError' || subscriber.state === 'hasError') {
    console.error('Error loading user details or subscription');
    return null;
  }

  if (authUser?.planVersion && tier === 'free') {
    return <div className="text-right text-sm">Free — limited access</div>;
  }

  if (!authUser?.usageCycle || subscription?.deprecated) {
    return null;
  }

  return (
    <div
      className="d-flex flex-column justify-content-center text-xs mx-2"
      style={{ cursor: 'pointer' }}
      onClick={() => navigate('/subscription')}
    >
      {isFreeTrial ? (
        <FreeTrialIndicator subscriptionName={subscription?.name} daysRemaining={daysRemaining} />
      ) : (
        <PlanIndicator subscriptionName={subscription?.name} />
      )}
      {!loading && (
        <>
          <div className="navbar-usage-progress">
            <ProgressBar variant={creditLevelIndicator} now={creditRatio} />
          </div>
          <CreditsIndicator creditBalance={currentBalance} />
        </>
      )}
    </div>
  );
}

export default PlanCreditsIndicator;
