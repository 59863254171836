import { Sound } from '@pixi/sound';
import { useCallback, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { soundtrackState } from '@store/atoms/EditState';
import { playbackMuteAtom } from '@store/atoms/TimelineState';

function useSoundtrack() {
  const soundRef = useRef();
  const soundtrack = useRecoilValue(soundtrackState);
  const isMuted = useRecoilValue(playbackMuteAtom);

  useEffect(() => {
    if (soundtrack?.src) {
      soundRef.current = Sound.from({
        url: soundtrack?.src,
        preload: true,
        singleInstance: true,
      });
    }

    return () => {
      if (soundRef.current?.isLoaded) {
        soundRef.current?.stop();
      }
      soundRef.current = undefined;
    };
  }, [soundtrack?.src]);

  // Handle volume change
  useEffect(() => {
    if (soundRef.current) {
      soundRef.current.volume = isMuted ? 0 : soundtrack?.volume ?? 1;
    }
  }, [isMuted, soundtrack?.volume]);

  const mute = useCallback((muted) => {
    if (!soundRef.current?.isLoaded) return;
    soundRef.current.mute(!muted);
  }, []);

  const pause = useCallback(() => {
    if (!soundRef.current?.isLoaded) return;
    soundRef.current.pause();
  }, []);

  const play = useCallback(({ start, end }) => {
    if (!soundRef.current?.isLoaded) return;
    soundRef.current.play({
      start: start < end ? start : 0,
      end,
    });
  }, []);

  const stop = useCallback(() => {
    if (!soundRef.current?.isLoaded) return;
    soundRef.current.stop();
  }, []);

  return {
    mute,
    pause,
    play,
    stop,
  };
}

export default useSoundtrack;
