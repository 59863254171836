import { atom } from 'recoil';

export const assetLoaderIsLoading = atom({
  key: 'assetLoaderIsLoading',
  default: false,
});

export const assetLoaderError = atom({
  key: 'assetLoaderError',
  default: false,
});

export const assetLoaderProgress = atom({
  key: 'assetLoaderProgress',
  default: 0,
});

export const assetLoaderRegistry = atom({
  key: 'assetLoaderRegistry',
  default: [],
});
