import { Button, Col, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useIntercom } from 'react-use-intercom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { v4 as uuid } from 'uuid';

import {
  IconBorderRadius,
  IconFontSize,
  IconFormatColorFill,
  IconFormatColorReset,
  IconFormatColorText,
  IconLineHeight,
  IconPadding,
  IconTransparent,
  IconTrimStart,
} from '@assets/icons';

import TimingFields from '@feature/studio/fields/TimingFields';
import CaptionList from '@feature/studio/setting/caption/CaptionList';

import ColorPicker from '@components/atoms/ColorPicker';
import FontsMenu from '@components/atoms/FontsMenu';
import AssetSourceInput from '@components/controls/AssetSourceInput';
import InputNumber from '@components/controls/InputNumber';
import OverrideMenu from '@components/mergeFields/OverrideMenu';

import { clipState, fontListByCategoryState } from '@store/selectors/EditSelectors';

import { CAPTION_COLOR, CAPTION_FONT_SIZE, FONT_FAMILY } from '@constants/TextAssetDefaults';

const MIN_FONT_SIZE = 1;

const assetPropertyTransform = (prop, type, value) => {
  if (type === 'number' && !prop.includes('font:size')) {
    return value ? parseFloat(value) : undefined;
  }
  return value || undefined;
};

function SettingAssetCaption({ id }) {
  const fontsListByCategory = useRecoilValue(fontListByCategoryState);
  const [clip, setClip] = useRecoilState(clipState(id));
  const { showArticle } = useIntercom();

  const handleAssetUpdate =
    ({ prop, value }) =>
    (event) => {
      const { type, value: eventValue } = event.target;
      setClip({ [prop]: assetPropertyTransform(prop, type, value ?? eventValue) });
    };

  const handleFontFamilyUpdate = (value) => {
    setClip({ 'asset:font:family': value });
  };

  const handleEmptyFontSize = (event) => {
    const { value } = event.target;
    if (!value) {
      setClip({ 'asset:font:size': MIN_FONT_SIZE });
    }
  };

  return (
    <>
      <AssetSourceInput
        type="caption"
        accepts=".srt,.vtt"
        clip={clip}
        uploadEnabled={false}
        aliasEnabled={true}
        linkUploadEnabled={false}
        requestOptions={{
          filename: `${uuid()}.srt`,
        }}
      />
      <Button variant="dark" className="mt-5" onClick={() => showArticle(9875442)}>
        How do I create automated captions?
      </Button>
      <div className="mb-10">
        {!clip['asset:meta']?.placeholder && (
          <CaptionList captions={clip['asset:meta']?.captions} trim={clip['asset:trim']} />
        )}
      </div>
      <h6>Font</h6>
      <div className="mb-10">
        <div className="d-flex align-items-center justify-content-between gap-2 mb-2">
          <FontsMenu
            fonts={fontsListByCategory}
            selected={clip['asset:font:family'] || FONT_FAMILY}
            onSelect={handleFontFamilyUpdate}
          />
          <OverrideMenu path="asset:font:family" />
        </div>

        <Row className="mb-2">
          <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="fontSize"
              title="Font Size"
              handleBlur={handleEmptyFontSize}
              handleChange={handleAssetUpdate({ prop: 'asset:font:size' })}
              prefix={<IconFontSize />}
              placeholder={CAPTION_FONT_SIZE}
              min={1}
              suffix="px"
              value={clip['asset:font:size']}
            />
            <OverrideMenu path="asset:font:size" />
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="lineHeight"
              title="Line Height"
              placeholder="1"
              min={0}
              max={10}
              step={0.1}
              handleChange={handleAssetUpdate({ prop: 'asset:font:lineHeight' })}
              prefix={<IconLineHeight size={18} />}
              value={clip['asset:font:lineHeight']}
            />
            <OverrideMenu path="asset:font:lineHeight" />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col sm={12} className="d-flex align-items-center justify-content-between gap-2">
            <InputGroup>
              <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-font-color`}>Font Color</Tooltip>}>
                <InputGroup.Text className="prefix">
                  <IconFormatColorText size={18} />
                </InputGroup.Text>
              </OverlayTrigger>
              <ColorPicker
                className="color"
                value={clip['asset:font:color'] || CAPTION_COLOR}
                handleEvent={handleAssetUpdate({ prop: 'asset:font:color' })}
              />
            </InputGroup>
            <OverrideMenu path="asset:font:color" />
          </Col>
        </Row>

        <Row className="mb-6">
          <Col sm={12} className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="fontOpacity"
              title="Font Opacity"
              value={clip['asset:font:opacity']}
              placeholder="1"
              min={0}
              max={1}
              step={0.01}
              prefix={<IconTransparent size={18} />}
              handleChange={handleAssetUpdate({ prop: 'asset:font:opacity' })}
            />
            <OverrideMenu path="asset:font:opacity" />
          </Col>
        </Row>
      </div>
      <h6>Background</h6>
      <div className="mb-10">
        <Row className="mb-2">
          <Col sm={12} className="d-flex align-items-center justify-content-between gap-2">
            <InputGroup>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-background-color`}>Background Color</Tooltip>}
              >
                <InputGroup.Text className="prefix">
                  <button
                    type="button"
                    name="asset:background:color"
                    className="unstyled"
                    disabled={!clip['asset:background:color']}
                    onClick={handleAssetUpdate({ prop: 'asset:background:color' })}
                  >
                    {clip['asset:background:color'] ? (
                      <IconFormatColorReset size={18} title="Transparent Background Color" />
                    ) : (
                      <IconFormatColorFill size={18} title="Background Color" />
                    )}
                  </button>
                </InputGroup.Text>
              </OverlayTrigger>

              <ColorPicker
                name="asset:background:color"
                className="background"
                value={clip['asset:background:color']}
                handleEvent={handleAssetUpdate({ prop: 'asset:background:color' })}
              />
            </InputGroup>
            <OverrideMenu path="asset:background:color" />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col sm={12} className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="backgroundOpacity"
              title="Background Opacity"
              value={clip['asset:background:opacity']}
              placeholder="1"
              min={0}
              max={1}
              step={0.01}
              prefix={<IconTransparent size={18} />}
              handleChange={handleAssetUpdate({ prop: 'asset:background:opacity' })}
            />
            <OverrideMenu path="asset:background:opacity" />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="backgroundPadding"
              title="Background Padding"
              value={clip['asset:background:padding']}
              placeholder={0}
              min={0}
              step={1}
              prefix={<IconPadding size={18} />}
              handleChange={handleAssetUpdate({ prop: 'asset:background:padding' })}
            />
            <OverrideMenu path="asset:background:padding" />
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="borderRadius"
              title="Background Border Radius"
              value={clip['asset:background:borderRadius']}
              placeholder={0}
              min={0}
              step={1}
              prefix={<IconBorderRadius size={18} />}
              handleChange={handleAssetUpdate({ prop: 'asset:background:borderRadius' })}
            />
            <OverrideMenu path="asset:background:borderRadius" />
          </Col>
        </Row>
      </div>
      <h6>Trim</h6>
      <div className="mb-10">
        <Row className="mb-2">
          <Col md={12} className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="trim"
              title="Trim (seconds)"
              placeholder={0}
              handleChange={handleAssetUpdate({ prop: 'asset:trim' })}
              prefix={<IconTrimStart size={18} />}
              min={0}
              value={clip['asset:trim']}
            />
            <OverrideMenu path="asset:trim" />
          </Col>
        </Row>
      </div>
      <h6>Timing</h6>
      <TimingFields id={id} />
    </>
  );
}

export default SettingAssetCaption;
