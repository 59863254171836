import { Sprite } from '@inlet/react-pixi';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useTimelinePlaybackContext } from '@context/TimelinePlaybackProvider';

import withCanvasMovable from '@feature/studio/canvas/CanvasMovable';

import ArtboardMask from '@components/masks/ArtboardMask';

import { playbackMuteAtom } from '@store/atoms/TimelineState';

const CanvasVideoPlayer = forwardRef((props, ref) => {
  const { clip, resource, visible, x, y, width, height, alpha, zIndex, angle } = props;
  const isMuted = useRecoilValue(playbackMuteAtom);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const { isPlaying: timelineIsPlaying, playhead: timelinePlayhead } = useTimelinePlaybackContext();
  const maskRef = useRef(null);

  const videoResource = resource?.baseTexture?.resource?.source;

  const { start, length, ['asset:trim']: trim = 0, ['asset:volume']: volume = 1 } = clip;

  useEffect(() => {
    if (ref.current && maskRef.current) {
      ref.current.mask = maskRef.current;
    }
  }, [ref]);

  useEffect(() => {
    // TODO: Can the playhead be paused if data or connection to WebGL Is delayed or lost?
    // TODO: currentTime can take half a second to seek, how to control this when we leave and return to the preview tab?
    if (!videoResource) {
      return;
    }

    if (!visible && videoResource.currentTime !== trim) {
      setVideoIsPlaying(false);
      videoResource.currentTime = trim;
      return;
    }

    if (visible && !timelineIsPlaying) {
      setVideoIsPlaying(false);
      try {
        videoResource.currentTime = timelinePlayhead - start + trim;
      } catch (error) {
        console.error(error);
      }
      return;
    }

    if (visible && timelineIsPlaying && !videoIsPlaying) {
      setVideoIsPlaying(true);
      videoResource.play().catch(() => {
        videoResource.pause();
        setVideoIsPlaying(false);

        // todo handle error
        // error not being caught by the promise is being caused by the video being paused before it can play
        // it's thrown when the timeline ends on a video. It's not causing any issues, but it's not ideal
        // console.error(error);
      });
    }
  }, [timelinePlayhead, timelineIsPlaying, visible, start, length, trim, videoIsPlaying, videoResource]);

  useEffect(() => {
    if (!videoResource) {
      return;
    }

    videoResource.volume = isMuted ? 0 : Math.max(0, Math.min(volume || 0, 1));
  }, [videoResource, volume, isMuted]);

  if (!resource) {
    return null;
  }

  return (
    <>
      <Sprite
        ref={ref}
        anchor={0.5}
        texture={resource}
        visible={visible}
        x={x}
        y={y}
        width={width}
        height={height}
        alpha={alpha}
        zIndex={zIndex}
        angle={angle}
      />
      <ArtboardMask ref={maskRef} />
    </>
  );
});

export default withCanvasMovable(CanvasVideoPlayer);
