import cn from 'classnames';
import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import TimelineClipAudio from '@feature/studio/timeline/TimelineClipAudio';
import TimelineClipCaption from '@feature/studio/timeline/TimelineClipCaption';
import TimelineClipImage from '@feature/studio/timeline/TimelineClipImage';
import TimelineClipMask from '@feature/studio/timeline/TimelineClipMask';
import TimelineClipOverlay from '@feature/studio/timeline/TimelineClipOverlay';
import TimelineClipText from '@feature/studio/timeline/TimelineClipText';
import TimelineClipTextToImage from '@feature/studio/timeline/TimelineClipTextToImage';
import TimelineClipTextToSpeech from '@feature/studio/timeline/TimelineClipTextToSpeech';
import TimelineClipVideo from '@feature/studio/timeline/TimelineClipVideo';

import { clipErrorsFamily } from '@store/atoms/EditState';

const clipComponentMap = {
  video: TimelineClipVideo,
  audio: TimelineClipAudio,
  image: TimelineClipImage,
  html: TimelineClipText,
  text: TimelineClipText,
  title: TimelineClipText,
  overlay: TimelineClipOverlay,
  mask: TimelineClipMask,
  caption: TimelineClipCaption,
  'text-to-speech': TimelineClipTextToSpeech,
  'text-to-image': TimelineClipTextToImage,
};

function TimelineClip({ id, x, y, clip, active, width, maxWidth, trackId, showKeyframesUI, getRef }) {
  const clipErrors = useRecoilValue(clipErrorsFamily(id));

  const clipStyles = {
    transform: `translate(${x}px, ${y}px)`,
    width: `${width}px`,
  };

  const TimelineClipComponent = clipComponentMap[clip['asset:type']];
  if (!TimelineClipComponent) {
    return null;
  }

  return (
    <div
      ref={getRef}
      className={cn('clip', {
        [clip['asset:type']]: true,
        active,
        error: Array.isArray(clipErrors) && clipErrors.length,
      })}
      style={clipStyles}
      data-clip-id={id}
      data-track-id={trackId}
    >
      <span className="clip__resize clip__resize--left" />
      <TimelineClipComponent id={id} clip={clip} maxWidth={maxWidth} showKeyframesUI={showKeyframesUI} />
      <span className="clip__resize clip__resize--right" />
    </div>
  );
}

export default memo(TimelineClip);
