import '@css/TimelinePlayheadLine.css';

import TimelinePlayheadReactable from '@feature/studio/timeline/TimelinePlayheadReactable';

function TimelinePlayheadLine() {
  return (
    <TimelinePlayheadReactable>
      <div className="timeline-playhead-line" />
    </TimelinePlayheadReactable>
  );
}

export default TimelinePlayheadLine;
