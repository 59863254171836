import { pickBy } from 'lodash-es';
import { atom, selectorFamily, useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import {
  assetIdsState,
  clipIdsState,
  clipsFamily,
  mergeFamily,
  mergeIdsState,
  overridesFamily,
} from '@store/atoms/EditState';

import { formatMergeReplaceToImplicitType } from '@utils/merge';
import { removeMetaDataRecursive } from '@utils/template';

export const mergePopoverState = atom({
  key: 'mergePopoverState',
  default: false,
});

export const mergeDataJsonSelectorFamily = selectorFamily({
  key: 'mergeDataJsonSelectorFamily',
  get:
    (debug) =>
    ({ get }) => {
      return get(mergeIdsState)
        .map((mergeId) => {
          const mergeField = get(mergeFamily(mergeId));
          const replace = formatMergeReplaceToImplicitType(mergeField.replace, mergeField?.meta?.type);
          const newField = { ...mergeField, replace: replace ?? '' };
          return !debug ? removeMetaDataRecursive(newField) : newField;
        })
        .filter(({ find }) => Boolean(find));
    },
});

export const useAddMergeFieldState = () => {
  return useRecoilCallback(
    ({ set }) =>
      () => {
        const id = uuid();
        set(mergeIdsState, (currentState) => {
          return [...currentState, id];
        });
        set(mergeFamily(id), { find: '', replace: '', meta: { type: 'string' } });
      },
    []
  );
};

export const useUpdateMergeFieldState = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (id, update) => {
        const clips = snapshot.getLoadable(clipIdsState).contents;
        const { find } = snapshot.getLoadable(mergeFamily(id)).contents;

        // Iterate over the collection of clips and assets
        clips.forEach((clipAssetId) => {
          // Get the overrides for the current clip or asset
          const overrides = snapshot.getLoadable(overridesFamily(clipAssetId)).contents;
          // Reverse the overrides object
          const overridesReversed = Object.entries(overrides).reduce((acc, [key, value]) => {
            acc[value] = key;
            return acc;
          }, {});

          // Find the property based on the reversed overrides
          const property = overridesReversed[find];

          // Update the property value for clips
          if (property) {
            set(clipsFamily(clipAssetId), (prevState) => ({ ...prevState, [property]: update.replace }));
          }
        });

        set(mergeFamily(id), (prevState) => ({ ...prevState, ...update }));
      },
    []
  );
};

export const useDeleteMergeFieldState = () => {
  return useRecoilCallback(
    ({ set, reset, snapshot }) =>
      (id) => {
        const collection = [
          snapshot.getLoadable(clipIdsState).contents,
          snapshot.getLoadable(assetIdsState).contents,
        ].flat();
        const { find } = snapshot.getLoadable(mergeFamily(id)).contents;

        collection.forEach((clipAssetId) => {
          const overrides = snapshot.getLoadable(overridesFamily(clipAssetId)).contents;
          const pickedOverrides = pickBy(overrides, (val) => val !== find);
          set(overridesFamily(clipAssetId), pickedOverrides);
        });

        set(mergeIdsState, (currentState) => currentState.filter((mergeId) => mergeId !== id));
        reset(mergeFamily(id));
      },
    []
  );
};
