import { Spinner } from 'react-bootstrap';

import '@css/Editor.css';

import { RecoilUndoRoot } from '@context/RecoilUndo';
import { TimelinePlaybackProvider } from '@context/TimelinePlaybackProvider';

import WorkspaceRoot from '@feature/studio/workspace/WorkspaceRoot';

import useSdkIntegration from '@hooks/useSdkIntegration';

const allowedKeys = ['edit/timeline/**'];

function EditorSdk() {
  const { sdkLoading } = useSdkIntegration();

  if (sdkLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner />
      </div>
    );
  }

  return (
    <RecoilUndoRoot allowedKeys={allowedKeys}>
      <TimelinePlaybackProvider>
        <WorkspaceRoot />
      </TimelinePlaybackProvider>
    </RecoilUndoRoot>
  );
}

export default EditorSdk;
