import config from '@config';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const stageAtom = atom({
  key: 'stage/state',
  default: config.headers.stage,
  effects_UNSTABLE: [persistAtom],
});

export const showStageConfirmationModalAtom = atom({
  key: 'stage/warning',
  default: '',
});
