import googleTagManager from '@analytics/google-tag-manager';
import Segment from '@analytics/segment';
import config from '@config';
import Analytics from 'analytics';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { authenticatedUser } from '@store/atoms/AuthState';
import { stageAtom } from '@store/atoms/StageState';

import capitaliseWords from '@utils/text/capitaliseWords';

const createAnalytics = (plugins) => Analytics({ app: 'shotstack', plugins });

const dashboardAnalytics = createAnalytics([
  googleTagManager({
    containerId: config.analytics.gtm.id,
    preview: config.analytics.gtm.preview,
    auth: config.analytics.gtm.auth,
  }),
  Segment({ writeKey: config.analytics.segment.writeKey }),
]);

const sdkAnalytics = createAnalytics([Segment({ writeKey: config.analytics.segment.writeKey })]);

const AnalyticsContext = createContext();

const defaultParameters = (stage) => ({
  Environment: capitaliseWords(stage),
  Platform: 'Dashboard',
});

export function AnalyticsProvider({ children, isSdk }) {
  const authUser = useRecoilValue(authenticatedUser);
  const [user, setUser] = useState();
  const stage = useRecoilValue(stageAtom);

  const identifyUser = isSdk
    ? (id, data) => sdkAnalytics.identify(id, data)
    : (id, data) => {
        dashboardAnalytics.identify(id, { ...data });
      };

  useEffect(() => {
    if (!isSdk && authUser && !user) {
      identifyUser(authUser.auth0_id);
      setUser(authUser);
    }
  }, [authUser, user, isSdk, identifyUser]);

  const trackPage = () =>
    isSdk
      ? () => {
          sdkAnalytics.page();
        }
      : () => {
          dashboardAnalytics.page();
        };

  const trackEvent = isSdk
    ? (name, data) => {
        sdkAnalytics.track(name, { Platform: 'Studio SDK', ...data });
      }
    : (name, data) => {
        dashboardAnalytics.track(name, {
          ...defaultParameters(stage),
          ...data,
        });
      };

  const contextState = useMemo(
    () => ({
      identifyUser,
      trackEvent,
      trackPage,
    }),
    [stage, identifyUser, trackEvent]
  );

  return <AnalyticsContext.Provider value={contextState}>{children}</AnalyticsContext.Provider>;
}

export const useAnalytics = () => useContext(AnalyticsContext);
