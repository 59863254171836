import { atom, useRecoilCallback } from 'recoil';

import { createTemplate, saveTemplate } from '@api/services/template';

import { isValidJson } from '@store/atoms/EditState';
import { stageAtom } from '@store/atoms/StageState';
import { errorState } from '@store/atoms/UIState';
import { templateState } from '@store/selectors/TemplateSelectors';

export const templateLoadingState = atom({
  key: 'template/loading',
  default: false,
});

export const templateSavingState = atom({
  key: 'template/saving',
  default: false,
});

export const nameState = atom({
  key: 'template/name',
  default: 'Untitled',
});

export const idState = atom({
  key: 'template/id',
  default: null,
});

export const bulkTemplateDeleteState = atom({
  key: 'bulkTemplateDeleteState',
  default: [],
});

export const useSaveTemplate = () => {
  return useRecoilCallback(({ set, reset, snapshot }) => async ({ json, stage } = {}) => {
    const templateIsSaving = snapshot.getLoadable(templateSavingState).contents;
    if (templateIsSaving) {
      return;
    }

    const templateId = snapshot.getLoadable(idState).contents;
    const templateData = snapshot.getLoadable(templateState).contents;
    const validJson = snapshot.getLoadable(isValidJson).contents;

    const saveTemplateData = {
      ...templateData,
      template: json || templateData?.template,
    };

    if (!validJson || !saveTemplateData?.template) {
      set(errorState, { message: 'Template contains invalid JSON and could not be saved' });
      return;
    }

    reset(errorState);
    set(templateSavingState, true);

    try {
      let saveResponse;
      // when users Save and copy to another stage
      if (stage) {
        // switch stage
        set(stageAtom, stage.toLowerCase());
        // create template
        saveResponse = await createTemplate(saveTemplateData);
      } else {
        // otherwise, save the template
        saveResponse = await saveTemplate(templateId, saveTemplateData);
      }
      set(templateSavingState, false);
      return saveResponse;
    } catch (error) {
      set(errorState, { message: 'Template could not be saved' });
    } finally {
      set(templateSavingState, false);
    }
  });
};
