import { memo, useRef } from 'react';
import { Dropdown, NavLink, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Scrollbar } from 'react-scrollbars-custom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IconAlias } from '@assets/icons';

import OverrideMenuToggle from '@components/mergeFields/OverrideMenuToggle';

import { activeClipState } from '@store/atoms/ClipState';
import { sdkOptionsSelector } from '@store/atoms/SdkState';
import { aliasedClipsSelectorFamily, clipState } from '@store/selectors/EditSelectors';

const EmptyMenuContent = () => (
  <div className="overrideMenu__empty">
    <span>
      Add an alias <IconAlias size={20} /> to the clip you would like to reference for automated transcription.
    </span>
  </div>
);

function AliasMenu({ path, isButton = false }) {
  const clipId = useRecoilValue(activeClipState);
  const { isSdkEnabled } = useRecoilValue(sdkOptionsSelector);

  const [clip, setClip] = useRecoilState(clipState(clipId));

  const aliasedClips = useRecoilValue(aliasedClipsSelectorFamily(clipId));
  const isAliased = clip['asset:src']?.includes('alias://');

  const dropdownToggleRef = useRef();

  const handleSelectAlias = (alias) => {
    setClip({ ['asset:src']: `alias://${alias}` });
  };

  const handleClearAlias = () => {
    setClip({ ['asset:src']: '' });
  };

  const isAliasActive = (alias) => {
    return clip['asset:src']?.includes(alias);
  };

  if (isSdkEnabled) {
    return null;
  }

  return (
    <Dropdown className="overrideMenu">
      <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${path}`}>Select Alias</Tooltip>}>
        <Dropdown.Toggle as={NavLink} align="end" ref={dropdownToggleRef}>
          <OverrideMenuToggle
            path={path}
            isButton={isButton}
            isActive={isAliased}
            Icon={IconAlias}
            IconActive={IconAlias}
          />
        </Dropdown.Toggle>
      </OverlayTrigger>

      <Dropdown.Menu align="end">
        <Scrollbar className="overrideMenu__scrollbar" noScrollX>
          {isAliased || aliasedClips.length > 0 ? (
            <>
              <Dropdown.Header>Aliases</Dropdown.Header>
              {aliasedClips.length > 0 &&
                aliasedClips.map(({ id, alias }) => (
                  <Dropdown.Item key={id} active={isAliasActive(alias)} onClick={() => handleSelectAlias(alias)}>
                    {alias}
                  </Dropdown.Item>
                ))}
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleClearAlias}>Clear</Dropdown.Item>
            </>
          ) : (
            <EmptyMenuContent />
          )}
        </Scrollbar>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default memo(AliasMenu);
