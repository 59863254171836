import { Graphics } from '@inlet/react-pixi';
import React, { forwardRef } from 'react';
import { useRecoilValue } from 'recoil';

import { canvasStageState } from '@store/selectors/CanvasSelectors';

const ArtboardMask = forwardRef(({}, ref) => {
  const canvas = useRecoilValue(canvasStageState());

  return (
    <Graphics
      ref={ref}
      draw={(g) => {
        g.clear();
        g.beginFill(0xffffff);
        g.drawRect(0, 0, canvas.stage.width / canvas.stage.scale, canvas.stage.height / canvas.stage.scale);
        g.endFill();
      }}
    />
  );
});

export default ArtboardMask;
