import { atom } from 'recoil';

export const renderSubmittedState = atom({
  key: 'renderSubmittedState',
  default: false,
});

export const rendersState = atom({
  key: 'rendersState',
  default: [],
});

export const rendersPanelState = atom({
  key: 'rendersPanelState',
  default: false,
});

export const renderFastPreviewState = atom({
  key: 'renderFastPreviewState',
  default: false,
});

export const renderPreviewStartState = atom({
  key: 'renderPreviewStartState',
  default: '',
});

export const renderPreviewLengthState = atom({
  key: 'renderPreviewLengthState',
  default: '',
});
