import { memo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  IconEase,
  IconKeyframeNext,
  IconKeyframePrevious,
  IconRotate,
  IconScale,
  IconTransparent,
} from '@assets/icons';

import { useTimelinePlaybackContext } from '@context/TimelinePlaybackProvider';

import InputNumber from '@components/controls/InputNumber';
import InputRange from '@components/controls/InputRange';
import InputSelect from '@components/controls/InputSelect';

import {
  activeClipKeyframeIdSelectorFamily,
  keyframeEasingAtomFamily,
  keyframeInterpolationAtomFamily,
  keyframeOffsetXAtomFamily,
  keyframeOffsetYAtomFamily,
  keyframeOpacityAtomFamily,
  keyframeRotateAtomFamily,
  keyframeScaleAtomFamily,
  useCycleClipKeyframe,
} from '@store/studio/Keyframes';

import { easeOptions } from '@constants/Keyframes';

function KeyframeControl({ id, property, title, icon, propertyKey, min, max, useRange = false }) {
  const [value, setValue] = useRecoilState(property(id));
  const setInterpolation = useSetRecoilState(keyframeInterpolationAtomFamily(id));
  const [easing, setEasing] = useRecoilState(keyframeEasingAtomFamily(id));

  const handleInputValueChange = (event) => {
    const inputValue = event.target.value;
    setValue(inputValue === '' ? '' : Number(inputValue));
  };

  const handleRangeValueChange = (event) => {
    const inputValue = event.target.value;
    setValue(inputValue === '' ? '' : parseFloat(inputValue));
  };

  const handleEaseChange = (event) => {
    const { value } = event.target;
    if (value === 'linear') {
      setInterpolation((prevState) => ({ ...prevState, [propertyKey]: value }));
      setEasing((prevState) => ({ ...prevState, [propertyKey]: undefined }));
      return;
    }

    setInterpolation((prevState) => ({ ...prevState, [propertyKey]: 'bezier' }));
    setEasing((prevState) => {
      return { ...prevState, [propertyKey]: value };
    });
  };

  return (
    <Row className="mb-2">
      <Col>
        {useRange ? (
          <InputRange
            id={title.toLowerCase()}
            title={title}
            value={value ?? 0}
            disabled={!id}
            min={min}
            max={max}
            step={0.05}
            prefix={icon}
            handleChange={handleRangeValueChange}
          />
        ) : (
          <InputNumber
            id={title.toLowerCase()}
            title={title}
            value={value ?? ''}
            disabled={!id}
            placeholder="0"
            min={min}
            max={max}
            step={0.001}
            prefix={icon}
            handleChange={handleInputValueChange}
          />
        )}
      </Col>
      <Col>
        <InputSelect
          id={`${title.toLowerCase()}-easing`}
          title={`${title} Easing`}
          disabled={!id || value === undefined}
          prefix={<IconEase />}
          values={easeOptions}
          selected={easing[propertyKey] ?? 'linear'}
          handleChange={handleEaseChange}
        />
      </Col>
    </Row>
  );
}

function KeyframesAdvancedControls({ keyframeId }) {
  return (
    <div className="mb-10">
      <Row className="mb-2">
        <Col>
          <span className="uppercase-label">Property</span>
        </Col>
        <Col>
          <span className="uppercase-label">Easing</span>
        </Col>
      </Row>

      <div className="d-flex flex-column">
        <KeyframeControl
          id={keyframeId}
          property={keyframeOffsetXAtomFamily}
          title="Horizontal Offset"
          propertyKey="offset:x"
          icon="X"
          min={-1}
          max={1}
        />
        <KeyframeControl
          id={keyframeId}
          property={keyframeOffsetYAtomFamily}
          title="Vertical Offset"
          propertyKey="offset:y"
          icon="Y"
          min={-1}
          max={1}
        />
        <KeyframeControl
          id={keyframeId}
          property={keyframeOpacityAtomFamily}
          title="Opacity"
          propertyKey="opacity"
          icon={<IconTransparent />}
          min={0}
          max={1}
          useRange={true}
        />
        <KeyframeControl
          id={keyframeId}
          property={keyframeScaleAtomFamily}
          title="Scale"
          propertyKey="scale"
          icon={<IconScale />}
          min={0}
        />
        <KeyframeControl
          id={keyframeId}
          property={keyframeRotateAtomFamily}
          title="Rotate"
          propertyKey="rotate"
          icon={<IconRotate />}
          min={0}
        />
      </div>
    </div>
  );
}

const KeyframeTimecode = () => {
  const { timecode } = useTimelinePlaybackContext();

  return (
    <div className="flex flex-col items-center mx-4">
      <span className="text-2xl font-bold">{timecode}</span>
    </div>
  );
};

const KeyframeTimingControls = ({ id, keyframeId }) => {
  const cycleKeyframe = useCycleClipKeyframe(id);

  const handleCycleKeyframe = (direction) => () => {
    cycleKeyframe(direction);
  };
  return (
    <div className="keyframe-cycle mb-10">
      <Button variant="light" className="cycle__button" onClick={handleCycleKeyframe('previous')}>
        <IconKeyframePrevious size={32} />
      </Button>
      <KeyframeTimecode keyframeId={keyframeId} />
      <Button variant="light" className="cycle__button" onClick={handleCycleKeyframe('next')}>
        <IconKeyframeNext size={32} />
      </Button>
    </div>
  );
};

function PanelClipKeyframes({ id }) {
  const keyframeId = useRecoilValue(activeClipKeyframeIdSelectorFamily(id));

  return (
    <div className="keyframe-controls">
      <KeyframeTimingControls id={id} keyframeId={keyframeId} />
      <KeyframesAdvancedControls keyframeId={keyframeId} />
    </div>
  );
}

export default memo(PanelClipKeyframes);
