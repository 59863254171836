import { useRecoilValue } from 'recoil';

import InputCheckbox from '@components/controls/InputCheckbox';
import InputText from '@components/controls/InputText';

import { idState } from '@store/atoms/TemplateState';

function Mux({ destination, setDestination }) {
  const templateId = useRecoilValue(idState);

  const tracking = {
    properties: {
      Id: templateId,
    },
  };

  const handlePlaybackPolicyChange = (event) => {
    const { name, checked } = event.target;
    const { options } = destination;
    const { playbackPolicy = [] } = options || {};
    const newPlaybackPolicy = checked
      ? [...(playbackPolicy || []), name]
      : playbackPolicy.filter((policy) => policy !== name);

    setDestination((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options,
        playbackPolicy: newPlaybackPolicy,
      },
    }));
  };

  const handlePassthroughChange = (event) => {
    const { value } = event.target;
    setDestination((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options,
        passthrough: value,
      },
    }));
  };

  return (
    <div className="d-flex flex-column gap-2">
      <div>
        <p className="uppercase-label">Playback Policy</p>
        <InputCheckbox
          name="public"
          label="Public"
          checked={(destination?.options?.playbackPolicy || []).includes('public')}
          handleChange={handlePlaybackPolicyChange}
          tracking={{ name: 'Check Mux PlaybackPolicy Public', ...tracking }}
        />
        <InputCheckbox
          name="signed"
          label="Signed"
          checked={(destination?.options?.playbackPolicy || []).includes('signed')}
          handleChange={handlePlaybackPolicyChange}
          tracking={{ name: 'Check Mux PlaybackPolicy Signed', ...tracking }}
        />
      </div>
      <InputText
        label="Passthrough"
        value={destination?.options?.passthrough}
        handleChange={handlePassthroughChange}
        tracking={{ name: 'Update Mux Passthrough', ...tracking }}
        maxLength={255}
      />
    </div>
  );
}

export default Mux;
