import { useNavigate } from 'react-router-dom';

import CombinationMark from '@assets/img/logo/combination-mark.svg';

import TemplateNameField from '@feature/studio/template/TemplateName';

function WorkspaceActionsLeft() {
  const navigate = useNavigate();
  const handleLogoClick = (event) => {
    event.preventDefault();
    navigate('/studio/overview');
  };

  return (
    <div className="d-flex gap-2">
      <button type="button" className="unstyled" onClick={handleLogoClick}>
        <img src={CombinationMark} alt="Shotstack" style={{ height: '33px' }} />
      </button>
      <div className="ms-3 me-3 vr" />
      <TemplateNameField />
    </div>
  );
}

export default WorkspaceActionsLeft;
