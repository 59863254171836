import { useRecoilState, useResetRecoilState } from 'recoil';

import { IconReset } from '@assets/icons';

import ButtonElement from '@components/atoms/ButtonElement';
import InputNumber from '@components/controls/InputNumber';

import { renderPreviewLengthState, renderPreviewStartState } from '@store/atoms/RendersState';

function PreviewRangeFields(tracking) {
  const [rangeStart, setRangeStart] = useRecoilState(renderPreviewStartState);
  const [rangeLength, setRangeLength] = useRecoilState(renderPreviewLengthState);
  const resetRangeStart = useResetRecoilState(renderPreviewStartState);
  const resetRangeLength = useResetRecoilState(renderPreviewLengthState);

  const handleStartChange = (e) => {
    const { value } = e.target;
    setRangeStart(value !== '' ? parseFloat(value) : '');
  };

  const handleLengthChange = (e) => {
    const { value } = e.target;
    setRangeLength(value !== '' ? parseFloat(value) : '');
  };

  const handleResetRange = () => {
    resetRangeStart();
    resetRangeLength();
  };

  return (
    <div className="popover-controls__inner">
      <div className="popover-controls__row">
        <InputNumber
          label="Start"
          value={rangeStart}
          min={0}
          step={0.01}
          tracking={{
            ...tracking,
            name: 'Change Preview Range Start',
          }}
          handleChange={handleStartChange}
        />
        <InputNumber
          label="Length"
          value={rangeLength}
          min={0}
          step={0.01}
          tracking={{
            ...tracking,
            name: 'Change Preview Range Length',
          }}
          handleChange={handleLengthChange}
        />
        <ButtonElement
          variant="light"
          className="btn-reset"
          disabled={rangeStart === '' && rangeLength === ''}
          handleEvent={handleResetRange}
          tracking={{
            ...tracking,
            name: 'Reset Preview Range',
          }}
        >
          <IconReset />
        </ButtonElement>
      </div>
    </div>
  );
}

export default PreviewRangeFields;
