import _ from 'lodash-es';
import { selector, selectorFamily, useRecoilCallback } from 'recoil';

import { transformClipOutgoing } from '@api/transform/utils/clips';
import { processKeyframesOutgoing } from '@api/transform/utils/keyframes';

import {
  backgroundState,
  cacheState,
  callbackState,
  clipIdsState,
  clipsFamily,
  clipsTracksFamily,
  diskState,
  fontIdsState,
  fontsFamily,
  mergeFamily,
  mergeIdsState,
  overridesFamily,
  soundtrackState,
  trackIdsState,
} from '@store/atoms/EditState';
import { mergeDataJsonSelectorFamily } from '@store/atoms/MergeState';
import { FIRST_FRAME_TIME, playheadState } from '@store/atoms/PlayheadState';
import { mergeReplacementsState } from '@store/selectors/MergeSelectors';
import { clipKeyframesSelectorFamily } from '@store/studio/Keyframes';
import { outputSelectorFamily } from '@store/studio/Output';

import DefaultFonts from '@utils/fonts';
import {
  addHandleBars,
  jsonStringifyTemplate,
  removeMetaDataRecursive,
  replaceTextOverrideValues,
} from '@utils/template';

const urlRegex = /^(https?):\/\/[^\s/$.?#].[^\s]*$|^\/\/[^\s/$.?#].[^\s]*$/i;
const filterURL = (url) => urlRegex.test(url);

const clipDefaults = {
  text: { fit: 'none', scale: 1 },
};

export const clipState = selectorFamily({
  key: 'clipState',
  get:
    (clipId) =>
    ({ get }) => {
      const clip = get(clipsFamily(clipId));

      const assetTypes = ['html', 'text', 'text-to-speech', 'text-to-image'];
      if (assetTypes.includes(clip['asset:type'])) {
        const replacements = get(mergeReplacementsState);
        const textKey = clip['asset:type'] === 'text-to-image' ? 'asset:prompt' : 'asset:text';
        return {
          ...clipDefaults.text,
          ...clip,
          [textKey]: replaceTextOverrideValues(clip['asset:meta']?.text, replacements),
        };
      }

      const newClip = { ...clip };

      if (clip['asset:src'] && !clip['asset:src'].startsWith('alias://')) {
        try {
          const versionedSrc = new URL(clip['asset:src']);
          versionedSrc.searchParams.append('sscache', 2);
          newClip['asset:src'] = versionedSrc.href;
        } catch (error) {
          console.error('Error updating clip src:', error);
        }
      }

      return newClip;
    },

  set:
    (clipId) =>
    ({ get, set }, update) => {
      const clip = clipsFamily(clipId);
      const overrides = get(overridesFamily(clipId));
      const mergeIds = get(mergeIdsState);
      const mergeFields = mergeIds.map((mergeId) => get(mergeFamily(mergeId)));

      if (_.size(overrides) > 0 && _.size(mergeFields) > 0) {
        // Update merge field value
        _.chain(overrides)
          .pickBy((value, key) => _.has(update, key))
          .forEach((overrideKey, key) => {
            const mergeField = _.find(mergeFields, ({ find }) => find === overrideKey);
            if (mergeField) {
              set(mergeFamily(mergeField.id), (prevState) => ({
                ...prevState,
                replace: update[key],
              }));
            }
          })
          .value();
      }

      set(clip, (prevState) => {
        const { meta: { source, proxied } = {} } = prevState || {};
        const newClip = { ...prevState, ...update };

        if (update?.src && source && proxied) {
          // Todo: what should happen when a src has been changed? Old srce Width and Height are still in the meta data
          newClip.meta = {
            ...(newClip?.meta || {}),
            source: update.src,
            proxied: false,
          };
        }

        return newClip;
      });
    },
});

export const timelineClipState = selectorFamily({
  key: 'timelineClipState',
  get:
    (clipId) =>
    ({ get }) => {
      const clip = get(clipsFamily(clipId));

      if (clip?.meta?.length === 'end') {
        const timelineDuration = get(timelineDurationState);
        return { ...clip, length: timelineDuration - Math.max(clip.start, 0) };
      }

      return clip;
    },

  set:
    (clipId) =>
    ({ get, set }, update) => {
      const { prop, clip } = update;
      const clipTrackId = get(clipsTracksFamily(clipId)).trackId;
      const trackClips = [...get(trackClipsState(clipTrackId))].sort((a, b) => a.start - b.start);
      const currentClipIndex = trackClips.findIndex((c) => c.id === clipId);

      set(clipsFamily(clipId), (prevState) => {
        if (prop === 'start') {
          if (prevState?.meta?.start === 'auto') {
            return { ...prevState, ...clip, meta: { ...prevState.meta, start: 'number' } };
          }

          // when adjusting start, make sure it doesn't overlap the previous clip start+length
          const previousClip = trackClips[currentClipIndex - 1];
          if (previousClip && previousClip.start + previousClip.length >= clip.start) {
            return prevState;
          }
        }

        if (prop === 'length') {
          if (prevState?.meta?.length === 'auto') {
            return { ...prevState, ...clip, meta: { ...prevState.meta, length: 'number' } };
          }
          if (prevState?.meta?.length === 'end') {
            const timelineDuration = get(timelineDurationState);
            return {
              ...prevState,
              ...clip,
              length: timelineDuration - Math.max(prevState.start, 0),
              meta: { ...prevState.meta, length: 'number' },
            };
          }

          // when adjusting length, make sure it doesn't overlap the next clip start time
          const nextClip = trackClips[currentClipIndex + 1];
          if (nextClip && nextClip?.meta?.start !== 'auto' && prevState.start + clip.length >= nextClip.start) {
            return prevState;
          }
        }

        return { ...prevState, ...clip };
      });

      if (_.size(trackClips) > 1) {
        const clipState = get(clipsFamily(clipId));
        // Update track clips following this clip WHILE clip.meta.start === auto
        const restTrackClips = trackClips.slice(currentClipIndex + 1);
        let prevClipEnd = clipState.start + clipState.length;
        _.chain(restTrackClips)
          .takeWhile((clip) => clip?.meta?.start === 'auto')
          .forEach((clip) => {
            set(clipsFamily(clip.id), () => ({
              ...clip,
              start: prevClipEnd,
            }));
            prevClipEnd += clip.length;
          })
          .value();
      }
    },
});

export const useUpdateClipTimingProperty = (id) => {
  return useRecoilCallback((callbackArgs) => (prop, timing) => {
    const { snapshot, set } = callbackArgs;
    const clip = snapshot.getLoadable(clipsFamily(id)).contents;
    const clipTrackId = snapshot.getLoadable(clipsTracksFamily(id)).contents.trackId;
    const trackClips = [...snapshot.getLoadable(trackClipsState(clipTrackId)).contents]
      .filter((c) => c['asset:type'] !== 'mask')
      .sort((a, b) => a.start - b.start);
    const timelineDuration = snapshot.getLoadable(timelineDurationState).contents;

    let options = ['number'];
    if (prop === 'start' || clip['asset:type'] !== 'mask') {
      options.push('auto');
    }
    if (prop === 'length' && trackClips.length === 1) {
      options.push('end');
    }

    set(clipsFamily(id), (state) => {
      const previousTiming = state.meta[prop];
      const nextValueIndex = (options.indexOf(previousTiming) + 1) % options.length;
      const nextTiming = timing || options[nextValueIndex];

      let values = {
        start: state.start,
        length: state.length,
        meta: {
          start: state.meta.start,
          length: state.meta.length,
        },
      };

      if (prop === 'start') {
        if (nextTiming === 'auto') {
          const currentClipIndex = trackClips.findIndex((c) => c.id === id);
          const previousClip = trackClips[currentClipIndex - 1];
          values.start = previousClip ? previousClip.start + previousClip.length : 0;
          if (state.meta.length === 'end') {
            values.length = timelineDuration - Math.max(values.start, 0);
          }
        } else if (nextTiming === 'number') {
          values.start = state.start;
        }
      }

      if (prop === 'length') {
        if (nextTiming === 'end') {
          values.length = timelineDuration - Math.max(values.start, 0);
        } else if (nextTiming === 'auto' || previousTiming === 'end') {
          const clip = snapshot.getLoadable(clipState(id)).contents;
          values.length = clip['asset:meta']?.duration || 3;

          if (_.size(trackClips) > 1) {
            const currentClipIndex = trackClips.findIndex((c) => c.id === id);
            const restTrackClips = trackClips.slice(currentClipIndex + 1);

            let prevClipEnd = state.start + values.length;
            _.chain(restTrackClips)
              .forEach((clip) => {
                set(clipsFamily(clip.id), (prevState) => {
                  if (prevClipEnd <= clip.start) {
                    return prevState;
                  }
                  const updatedClip = {
                    ...prevState,
                    start: prevClipEnd,
                  };
                  prevClipEnd = updatedClip.start + updatedClip.length;
                  return updatedClip;
                });
              })
              .value();
          }
        } else if (nextTiming === 'number') {
          values.length = state.length;
        }
      }

      return {
        ...state,
        ...values,
        meta: {
          ...values.meta,
          [prop]: nextTiming,
        },
      };
    });
  });
};

export const clipVisibilityState = selectorFamily({
  key: 'clipVisibilityState',
  get:
    (clipId) =>
    ({ get }) => {
      const clip = get(clipState(clipId));
      const playhead = get(playheadState);
      const { start, length } = clip;

      if (playhead <= FIRST_FRAME_TIME) {
        return start <= FIRST_FRAME_TIME;
      }

      return playhead >= start && playhead <= start + length;
    },
});

export const clipTrackState = selectorFamily({
  key: 'clipTrackState',
  get:
    (clipId) =>
    ({ get }) =>
      get(clipsTracksFamily(clipId)),
});

export const trackClipIdsState = selectorFamily({
  key: 'trackClipIdsState',
  get:
    (trackId) =>
    ({ get }) => {
      const clipIds = get(clipIdsState);
      return clipIds
        .map((clipId) => {
          const { trackId: clipTrackId } = get(clipsTracksFamily(clipId));
          return clipTrackId === trackId ? clipId : undefined;
        })
        .filter(Boolean);
    },
});

export const trackClipsState = selectorFamily({
  key: 'trackClipsState',
  get:
    (trackId) =>
    ({ get }) => {
      const clipIds = get(clipIdsState);
      return clipIds
        .filter((clipId) => {
          const { trackId: clipTrackId } = get(clipsTracksFamily(clipId));
          return clipTrackId === trackId;
        })
        .map((clipId) => {
          return get(clipsFamily(clipId));
        });
    },
});

export const assetListState = selector({
  key: 'assetListState',
  get: ({ get }) => {
    const soundtrack = get(soundtrackState);
    const clipIds = get(clipIdsState);
    const fontIds = get(fontIdsState);

    const assetsList = clipIds
      .map((clipId) => {
        const { ['asset:src']: src } = get(clipState(clipId));
        return src;
      })
      .filter(Boolean);

    const customFontsList = fontIds
      .map((fontId) => {
        const { src } = get(fontsFamily(fontId));
        return src;
      })
      .filter(Boolean);

    const defaultFontsList = DefaultFonts.map((font) => font.src).filter(Boolean);

    const mediaList = [...new Set([...assetsList, soundtrack?.src].filter(Boolean))].filter(filterURL);

    const fontsList = [...new Set([...defaultFontsList, ...customFontsList])].filter(filterURL);

    return {
      media: mediaList,
      fonts: fontsList,
    };
  },
});

export const fontListAllState = selector({
  key: 'fontListAllState',
  get: ({ get }) => {
    const fonts = get(fontIdsState);

    const fontsList = fonts.map((font) => {
      return font.src || null;
    });

    return fontsList;
  },
});

export const fontListByCategoryState = selector({
  key: 'fontListByCategoryState',
  get: ({ get }) => {
    const fontIds = get(fontIdsState);
    const fonts = fontIds
      .map((id) => ({ key: id, ...get(fontsFamily(id)) }))
      .filter(({ src, family }) => Boolean(src) && Boolean(family));

    return { uploaded: fonts, default: DefaultFonts };
  },
});

export const timelineDurationState = selector({
  key: 'timelineDurationState',
  get: ({ get }) => {
    const clipIds = get(clipIdsState);
    const outPoints = clipIds
      .map((clipId) => get(clipsFamily(clipId)))
      .filter((clip) => clip?.meta?.length !== 'end')
      .map((clip) => clip.start + clip.length)
      .filter((val) => !Number.isNaN(val));

    const duration = Math.max.apply(null, outPoints);
    if (!Number.isFinite(duration)) {
      return 0.0;
    }

    return parseFloat(duration);
  },
});

export const timelineClipTimesState = selector({
  key: 'timelineClipTimesState',
  get: ({ get }) => {
    const clipIds = get(clipIdsState);

    const points = _.uniq(
      clipIds
        .map((clipId) => {
          const { start, length } = get(clipsFamily(clipId));
          return [start, start + length];
        })
        .flat()
        .filter((val) => !Number.isNaN(val))
    ).sort((a, b) => a - b);

    return points;
  },
});

export const aliasedClipsSelectorFamily = selectorFamily({
  key: 'aliasedClipsSelectorFamily',
  get:
    (clipId) =>
    ({ get }) => {
      const clipIds = get(clipIdsState);
      return clipIds
        .map((id) => {
          const clip = get(clipsFamily(id));
          return clip.alias ? { id, ...clip } : null;
        })
        .filter((clip) => clip?.id !== clipId)
        .filter(Boolean);
    },
});

// Todo: change this in to a callback. Is fired when the state changes and tracks a LOT of changes
export const derivedJsonState = selectorFamily({
  key: 'derivedJsonState',
  get:
    (debug = false) =>
    ({ get }) => {
      const background = get(backgroundState);
      const soundtrackRaw = get(soundtrackState);
      const callbackRaw = get(callbackState);
      const disk = get(diskState);
      const cache = get(cacheState);
      const fontIds = get(fontIdsState);
      const output = get(outputSelectorFamily(debug));
      const trackIds = get(trackIdsState);
      const clipIds = get(clipIdsState);

      const callbackOverrides = get(overridesFamily('callback'));
      const callback = {
        ...callbackRaw,
        ...(!debug ? addHandleBars(callbackOverrides) : { callbackOverrides }),
      };

      const soundtrackOverrides = get(overridesFamily('soundtrack'));
      const soundtrack = {
        ...soundtrackRaw,
        ...(!debug ? addHandleBars(soundtrackOverrides) : { soundtrackOverrides }),
      };
      const tracks = clipIds
        .reduce(
          (acc, clipId) => {
            const { trackIndex } = get(clipsTracksFamily(clipId));
            // todo: figure out what type is doing here, we can probably remove it and make sure clip['asset:type'] is used instead
            const { type, ...clipData } = get(clipsFamily(clipId));
            const overrides = get(overridesFamily(clipId));
            const keyframes = get(clipKeyframesSelectorFamily(clipId));
            const clipKeyframesData = processKeyframesOutgoing(keyframes);

            const clipEntry = {
              ...clipData,
              ...clipKeyframesData,
              start: clipData?.meta?.start === 'number' ? clipData?.start : clipData?.meta?.start,
              length: clipData?.meta?.length === 'number' ? clipData?.length : clipData?.meta?.length,
              ...(!debug ? addHandleBars(overrides) : { overrides }),
            };

            const clip = debug ? clipEntry : transformClipOutgoing(clipEntry);

            if (!acc[trackIndex]) {
              acc[trackIndex] = { clips: [] };
            }
            acc[trackIndex].clips.push(clip);
            return acc;
          },
          trackIds.map(() => ({ clips: [] }))
        )
        .filter((track) => track.clips.length > 0);

      let fonts = fontIds.map((fontId) => get(fontsFamily(fontId)));
      if (!debug) {
        fonts = fontIds
          .map((fontId) => {
            const { src, meta } = get(fontsFamily(fontId));
            return meta?.src ?? src;
          })
          .filter(Boolean)
          .map((src) => ({ src }));
      }

      return jsonStringifyTemplate({
        background,
        soundtrack: !debug ? removeMetaDataRecursive(soundtrack) : soundtrack,
        merge: get(mergeDataJsonSelectorFamily(debug)),
        callback: !debug ? callback?.src || '' : callback,
        disk,
        cache,
        fonts,
        output,
        tracks,
      });
    },
});
