import { useRecoilState, useRecoilValue } from 'recoil';

import InputTextElement from '@components/atoms/InputTextElement';

import { templateReadyState, workspaceTabAtom } from '@store/atoms/EditState';
import { idState, nameState, useSaveTemplate } from '@store/atoms/TemplateState';

function TemplateName({ className }) {
  const [templateName, setTemplateName] = useRecoilState(nameState);
  const templateId = useRecoilValue(idState);
  const templateReady = useRecoilValue(templateReadyState);
  const workspaceTab = useRecoilValue(workspaceTabAtom);
  const saveTemplate = useSaveTemplate();

  const tracking = {
    name: 'Change Template Name',
    properties: {
      Id: templateId,
    },
  };

  const handleChange = (event) => {
    setTemplateName(event.target.value);
  };

  const handleOnBlur = () => {
    saveTemplate();
  };

  return (
    <div className={className}>
      <InputTextElement
        id="template-name"
        className="template-name"
        inputType="text"
        handleChange={handleChange}
        handleOnBlur={handleOnBlur}
        tracking={tracking}
        disabled={!templateReady || workspaceTab !== 'design'}
        value={!templateReady ? 'Loading...' : templateName}
      />
    </div>
  );
}

export default TemplateName;
