import cn from 'classnames';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';

import { IconChevronDown, IconChevronUp, IconClose } from '@assets/icons';

import LinkElement from '@components/atoms/LinkElement';

import { sidebarBottom, sidebarTop } from '@constants/Navigation';

const RenderExternalLink = ({ label, to }) => (
  <LinkElement key={label} to={to} external={true} className="nav-link">
    {label}
  </LinkElement>
);

const RenderInternalLink = ({ label, to, baseUrl, isActive }) => (
  <LinkContainer key={label} to={baseUrl + to} className={cn({ active: isActive(baseUrl + to) })}>
    <Nav.Link>{label}</Nav.Link>
  </LinkContainer>
);

const RenderSubLinks = ({ baseUrl, subLinks, isActive }) => (
  <div className="site-sidebar-group__sublinks">
    {subLinks.map(({ label, to, external }) =>
      external ? (
        <RenderExternalLink key={label} label={label} to={to} />
      ) : (
        <RenderInternalLink key={label} label={label} to={to} baseUrl={baseUrl} isActive={isActive} />
      )
    )}
  </div>
);

const RenderLink = ({ link, Icon, isActive }) => {
  const { label, to, baseUrl, subLinks, external } = link;
  const linkPath = baseUrl ? baseUrl + to.overview : to;

  return external ? (
    <div className="site-sidebar-group__link-container">
      <RenderExternalLink label={label} to={to} />
    </div>
  ) : (
    <>
      <div className={cn('site-sidebar-group__link-container', { active: isActive(baseUrl) })}>
        <LinkContainer to={linkPath}>
          <Nav.Link className="flex justify-between">
            <div className="flex items-center">
              {Icon && <Icon size={20} className="mr-2" />}
              {label}
            </div>
            {subLinks ? isActive(baseUrl) ? <IconChevronUp /> : <IconChevronDown /> : null}
          </Nav.Link>
        </LinkContainer>
      </div>
      {subLinks && <RenderSubLinks baseUrl={baseUrl} subLinks={subLinks} isActive={isActive} />}
    </>
  );
};

function SidebarMain() {
  const location = useLocation();

  const isActive = (baseUrl) => location.pathname.startsWith(baseUrl);

  const handleToggleSidebar = () => {
    document.body.classList.toggle('sidebar-open');
  };

  return (
    <aside className="site-sidebar">
      <div className="flex items-center gap-3 mb-8 d-md-none">
        <button className="unstyled text-gray-400" onClick={handleToggleSidebar}>
          <IconClose size={36} />
        </button>
      </div>

      <div className="site-sidebar__nav">
        <Nav>
          {Object.entries(sidebarTop).map(([group, links]) => (
            <div className="site-sidebar-group" key={group}>
              <p className="site-sidebar-group__title uppercase-label">{group}</p>
              {links.map((link) => (
                <div key={link.label}>
                  <RenderLink link={link} Icon={link.Icon} isActive={isActive} />
                </div>
              ))}
            </div>
          ))}
        </Nav>
        <Nav>
          {sidebarBottom.map((link) => (
            <RenderLink link={link} isActive={isActive} key={link.label} />
          ))}
        </Nav>
      </div>
    </aside>
  );
}

export default SidebarMain;
