import { atom, atomFamily, selector, useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import { processKeyframesIncoming } from '@api/transform/utils/keyframes';

import { templateMediaPersist } from '@store/effects';
import { hydrateClipKeyframesCallback } from '@store/studio/Keyframes';
import { hydrateOutputCallback, resetOutputCallback } from '@store/studio/Output';

import { getFont } from '@utils/fonts';
import { getReplacedData } from '@utils/merge';
import { reorganiseTracks } from '@utils/tracks';

const DEFAULT_BACKGROUND_COLOR = '#FFFFFF';
const DEFAULT_CACHE_STATE = true;

export const templateMediaState = atomFamily({
  key: 'edit/template/media',
  default: { loading: false, status: 'init', data: null },
  effects_UNSTABLE: templateMediaPersist,
});

export const workspaceTabAtom = atom({
  key: 'edit/workspace/tab',
  default: 'design',
});

export const settingsTabAtom = atom({
  key: 'edit/settings/tab',
  default: 'properties',
});

export const templateReadyState = atom({
  key: 'edit/template/ready',
  default: false,
});

export const templateJsonEditState = atom({
  key: 'edit/template/json/ready',
  default: false,
});

export const mergeFamily = atomFamily({
  key: 'edit/merge',
  default: [],
});

export const mergeIdsState = atom({
  key: 'edit/merge/ids',
  default: [],
});

export const isKeyframesTabSelector = selector({
  key: 'edit/isKeyframesTabSelector',
  get: ({ get }) => {
    const selectedSettingsTab = get(settingsTabAtom);
    return selectedSettingsTab === 'keyframes';
  },
});

export const hasMergeFieldsSelector = selector({
  key: 'edit/merge/hasMergeFieldsSelector',
  get: ({ get }) => {
    const mergeIds = get(mergeIdsState);
    const mergeFields = mergeIds.map((mergeId) => get(mergeFamily(mergeId)));
    const mergeFieldsWithKey = mergeFields.filter((mergeField) => mergeField.find);
    return mergeFieldsWithKey.length > 0;
  },
});

export const callbackState = atom({
  key: 'edit/callback',
  default: {},
});

export const diskState = atom({
  key: 'edit/disk',
  default: null,
});

export const soundtrackState = atom({
  key: 'edit/template/soundtrack',
  default: null,
});

export const backgroundState = atom({
  key: 'edit/template/background',
  default: DEFAULT_BACKGROUND_COLOR,
});

export const fontIdsState = atom({
  key: 'edit/template/font/ids',
  default: [],
});

export const fontsFamily = atomFamily({
  key: 'edit/template/font',
  default: {},
});

export const cacheState = atom({
  key: 'edit/template/cache',
  default: DEFAULT_CACHE_STATE,
});

export const trackIdsState = atom({
  key: 'edit/timeline/track/ids',
  default: [],
});

export const clipIdsState = atom({
  key: 'edit/timeline/clip/ids',
  default: [],
});

export const assetIdsState = atom({
  key: 'edit/timeline/asset/ids',
  default: [],
});

export const clipsFamily = atomFamily({
  key: 'edit/timeline/track/clip',
  default: {},
});

export const clipErrorsFamily = atomFamily({
  key: 'edit/studio/clip/errors',
  default: {},
});

export const overridesFamily = atomFamily({
  key: 'edit/studio/clip/overrides',
  default: {},
});

export const clipsTracksFamily = atomFamily({
  key: 'edit/timeline/clip/track',
  default: {},
});

export const isValidJson = atom({
  key: 'isValidJson',
  default: true,
});

export const useAddFontState = () => {
  return useRecoilCallback(
    ({ set }) =>
      async ({ src }) => {
        const id = uuid();
        set(fontIdsState, (currentState) => {
          return [...currentState, id];
        });
        const [updatedFont, error] = await getFont({ src });
        if (error) {
          return [undefined, error];
        }
        set(fontsFamily(id), updatedFont);
        return [updatedFont, undefined];
        return id;
      },
    []
  );
};

export const useDeleteFontState = () => {
  return useRecoilCallback(
    ({ set, reset }) =>
      (id) => {
        set(fontIdsState, (currentState) => currentState.filter((fontId) => fontId !== id));
        reset(fontsFamily(id));
      },
    []
  );
};

export const useHydrateStoreFromTemplate = () => {
  const hydrateStore = useRecoilCallback((callbackArgs) => {
    const { set, reset, snapshot } = callbackArgs;
    const resetOutput = resetOutputCallback(callbackArgs);

    const hydrateOutput = hydrateOutputCallback(callbackArgs);
    const hydrateClipKeyframes = hydrateClipKeyframesCallback(callbackArgs);

    return async (json) => {
      const { output, merge, callback, disk, timeline } = json || {};
      const { soundtrack, tracks, fonts } = timeline || {};

      const replacements = (merge || []).reduce((acc, { find, replace }) => {
        acc[find] = replace;
        return acc;
      }, {});

      const currentClipIds = snapshot.getLoadable(clipIdsState).contents;
      currentClipIds.forEach((clipId) => {
        reset(clipsFamily(clipId));
        reset(overridesFamily(clipId));
      });

      const currentFontIds = snapshot.getLoadable(fontIdsState).contents;
      currentFontIds.forEach((fontId) => reset(fontsFamily(fontId)));

      const currentMergeIdsState = snapshot.getLoadable(mergeIdsState).contents;
      currentMergeIdsState.forEach((mergeId) => reset(mergeFamily(mergeId)));

      reset(trackIdsState);
      reset(clipIdsState);
      reset(assetIdsState);
      reset(fontIdsState);
      reset(mergeIdsState);

      reset(callbackState);
      reset(soundtrackState);

      reset(overridesFamily('callback'));
      reset(overridesFamily('soundtrack'));

      resetOutput();

      set(diskState, disk || null);

      set(backgroundState, timeline?.background || DEFAULT_BACKGROUND_COLOR);

      set(cacheState, () => (timeline?.cache === false ? timeline.cache : DEFAULT_CACHE_STATE));

      const { merged: mergedCallback, overrides: callbackOverrides } = getReplacedData({ src: callback }, replacements);
      set(overridesFamily('callback'), callbackOverrides);
      set(callbackState, mergedCallback);

      const { merged: mergedSoundtrack, overrides: soundtrackOverrides } = getReplacedData(soundtrack, replacements);
      set(overridesFamily('soundtrack'), soundtrackOverrides);
      set(soundtrackState, mergedSoundtrack);

      (merge || []).forEach(async ({ find, replace }, index) => {
        const mergeId = uuid();
        set(mergeIdsState, (currentState) => {
          return !currentState[index] ? currentState.concat([mergeId]) : currentState;
        });
        const replaceType = typeof replace;
        const type = replaceType === 'undefined' ? 'string' : replaceType;
        const newMerge = { id: mergeId, find, replace, meta: { type } };
        set(mergeFamily(mergeId), newMerge);
      });

      const organisedTracks = reorganiseTracks(tracks);

      (organisedTracks || []).forEach((track, trackIndex) => {
        const trackId = uuid();
        set(trackIdsState, (currentState) => {
          return !currentState[trackIndex] ? currentState.concat([trackId]) : currentState;
        });

        track.forEach((clip) => {
          const clipId = uuid();
          const rawClip = { id: clipId, ...clip };

          set(clipIdsState, (currentState, index) => {
            return clipIdsState[index] ? currentState : currentState.concat([clipId]);
          });

          const clipKeyframes = processKeyframesIncoming(clip);
          hydrateClipKeyframes(clipId, clipKeyframes);

          const { merged: mergedClip, overrides: clipOverrides } = getReplacedData(rawClip, replacements);
          set(clipsTracksFamily(clipId), { clipId, trackId, trackIndex });
          set(clipsFamily(clipId), mergedClip);
          set(overridesFamily(clipId), clipOverrides);
        });
      });

      (fonts || []).forEach(async (font, fontIndex) => {
        const fontId = uuid();
        set(fontIdsState, (currentState) => {
          return !currentState[fontIndex] ? currentState.concat([fontId]) : currentState;
        });
        const [newFont, error] = await getFont(font);
        if (error) {
          console.error(error);
        }
        set(fontsFamily(fontId), { id: fontId, ...font, ...newFont });
      });

      hydrateOutput(output);
      set(templateReadyState, true);
      set(templateJsonEditState, true);
    };
  }, []);

  return hydrateStore;
};
