import { useRecoilCallback } from 'recoil';

import { userDetailsSelector } from '@store/atoms/AuthState';
import { canvasState, stageState } from '@store/atoms/CanvasState';
import {
  assetIdsState,
  callbackState,
  clipIdsState,
  clipsFamily,
  fontIdsState,
  fontsFamily,
  mergeFamily,
  mergeIdsState,
  overridesFamily,
  templateJsonEditState,
  templateReadyState,
  trackIdsState,
} from '@store/atoms/EditState';
import { renderPreviewLengthState, renderPreviewStartState } from '@store/atoms/RendersState';
import { idState, nameState } from '@store/atoms/TemplateState';
import { studioActiveTabState } from '@store/atoms/UIState';
import { resetOutputCallback } from '@store/studio/Output';

import { allTemplatesState } from './TemplateSelectors';

export const resetStudioCallback = (callbackArgs) => {
  const { refresh, reset, snapshot } = callbackArgs;
  return () => {
    reset(idState);
    reset(nameState);
    reset(templateReadyState);
    reset(templateJsonEditState);

    reset(renderPreviewStartState);
    reset(renderPreviewLengthState);
    reset(canvasState);
    reset(stageState);
    reset(callbackState);

    reset(studioActiveTabState);

    resetOutputCallback(callbackArgs)();

    const currentMergeIdsState = snapshot.getLoadable(mergeIdsState).contents;
    currentMergeIdsState.forEach((mergeId) => reset(mergeFamily(mergeId)));

    const currentClipIds = snapshot.getLoadable(clipIdsState).contents;
    currentClipIds.forEach((clipId) => {
      reset(clipsFamily(clipId));
      reset(overridesFamily(clipId));
    });

    const currentFontIds = snapshot.getLoadable(fontIdsState).contents;
    currentFontIds.forEach((fontId) => reset(fontsFamily(fontId)));

    reset(overridesFamily('callback'));
    reset(overridesFamily('soundtrack'));

    reset(trackIdsState);
    reset(clipIdsState);
    reset(assetIdsState);
    reset(fontIdsState);
    reset(mergeIdsState);

    refresh(userDetailsSelector);
    refresh(allTemplatesState);
  };
};

export const useResetStudio = () => useRecoilCallback(resetStudioCallback);
