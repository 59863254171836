import { Card } from 'react-bootstrap';

import CreateBlankTemplateButton from '@feature/studio/template/TemplateCreate';
import BulkDeleteTemplateButton from '@feature/studio/template/TemplateDeleteBulk';
import TemplateListItems from '@feature/studio/template/list/TemplateListItems';

function OverviewStudio() {
  return (
    <>
      <Card className="p-3 mb-10">
        <Card.Body>
          <div className="max-w-screen-sm pr-2">
            <p className="uppercase-label">STUDIO</p>
            <h1>My Templates</h1>
          </div>
          <div className="d-flex justify-content-between gap-2 my-4">
            <div className="d-flex gap-2">
              <CreateBlankTemplateButton tracking={{ Position: 'Studio Overview' }} />
              <BulkDeleteTemplateButton />
            </div>
            <div className="d-flex gap-2"></div>
          </div>
        </Card.Body>
      </Card>

      <TemplateListItems />
    </>
  );
}

export default OverviewStudio;
