import { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';

import '@css/WorkflowDetails.css';

import tile from '@assets/img/tile.png';

import WorkflowService from '@api/services/workflow';

import WorkflowEditButton from '@feature/workflows/buttons/WorkflowEditButton';
import WorkflowJobTaskList from '@feature/workflows/detail/JobTaskList';

import AlertBox from '@components/alert/AlertBox';
import LinkElement from '@components/atoms/LinkElement';
import Layout from '@components/layout/Layout';
import Skeleton from '@components/skeletons/Skeleton';
import StatusBadge from '@components/status/StatusBadge';
import TableMetaData from '@components/table/MetaData';

import { workflowSelectorFamily } from '@store/Workflows';

import useFetchAndPoll from '@hooks/useFetchAndPoll';

import { MODULE_ATTRIBUTES } from '@constants/Workflows';

const apiMap = {
  edit: 'edit-api',
  ingest: 'ingest-api',
  serve: 'serve-api',
  create: 'create-api',
};

const getOutput = ({ mappings }) => {
  try {
    // outputs is an array but all entries are related to the same asset
    const { api, referenceId } = mappings
      .filter((m) => m.api || m.referenceId)
      .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    // gymnastics to get the dynamic output key and value
    const [output] = mappings.filter((m) => !(m.api || m.referenceId));
    const [[key, value]] = Object.entries(output);
    // get the asset detail URL
    const url = api && referenceId ? `/apis/${apiMap[api]}/assets/${referenceId}` : undefined;

    return { key, value, url };
  } catch (error) {
    console.error(error);
    return {};
  }
};

const getTaskMetadata = (task) => {
  if (!task) return [];

  return [
    [
      'Task ID',
      <Badge className="bg-subtle" key="taskId">
        {task.id}
      </Badge>,
    ],
    ['Task Status', <StatusBadge status={task.status} key="status" />],
    ['Task Ran', new Date(task.created).toLocaleString()],
  ].filter(([, value]) => value);
};

const getJobMetadata = (job) => {
  if (!job) return [];

  return [
    [
      'Job ID',
      <Badge className="bg-subtle" key="jobId">
        {job.id}
      </Badge>,
      'jobId',
    ],
    [
      'Workflow ID',
      <Badge className="bg-subtle" key="workflowId">
        {job.workflowId}
      </Badge>,
      'workflowId',
    ],
    ['Job Status', <StatusBadge status={job.status} key="status" />, 'status'],
    ['Last Ran', new Date(job.created).toLocaleString(), 'created'],
  ];
};

const TaskInputMappings = ({ mappings }) => {
  const [showAll, setShowAll] = useState(false);

  if (!mappings || !mappings.length) return null;

  const mappingsDisplay = mappings.map(Object.entries).flat();
  const displayMappings = showAll ? mappingsDisplay : mappingsDisplay.slice(0, 5);

  return (
    <div className="workflow__task mt-4">
      <div className="workflow__task-header">
        <p className="mb-0 font-bold">
          Task Inputs <span className="text-xs">({mappingsDisplay.length})</span>
        </p>
      </div>
      <div className="workflow__task-body">
        <table className="metadata">
          <tbody>
            {displayMappings.map(([key, value]) => (
              <tr key={key}>
                <td className="metadata__key">{key}</td>
                <td className="metadata__value">{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-2 text-center">
          {!showAll && mappingsDisplay.length > 5 && (
            <Button onClick={() => setShowAll(true)} variant="light" size="sm">
              Show All ({mappingsDisplay.length})
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const TaskOutputMappings = ({ mappings }) => {
  if (!mappings || !mappings.length) return null;

  const { key, value, url } = getOutput({ mappings });

  return (
    <div className="workflow__task mt-4">
      <div className="workflow__task-header">
        <p className="mb-0 font-bold">Task Output</p>
      </div>
      <div className="workflow__task-body">
        <table className="metadata">
          <tbody>
            <tr>
              <td className="metadata__key">{key}</td>
              <td className="metadata__value">{value}</td>
              <td className="metadata__action">
                {url && (
                  <LinkElement to={url} size="sm" variant="light" type="button" className="btn-sm">
                    View Asset
                  </LinkElement>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

function ActiveTaskDetails({ task }) {
  const taskMetadata = getTaskMetadata(task);
  const { error, module, mappings } = task || {};
  const moduleAttrs = MODULE_ATTRIBUTES[module];

  return (
    <div>
      <h3 className="text-xl font-bold">{moduleAttrs?.label || 'Task Details'}</h3>
      <TableMetaData data={taskMetadata} />

      {error ? (
        <AlertBox name="Task Failed" type="error" message={task.error} className="mt-4" />
      ) : (
        <>
          <TaskInputMappings mappings={mappings?.inputs} />
          <TaskOutputMappings mappings={mappings?.outputs} />
        </>
      )}
    </div>
  );
}

function WorkflowJobDetail() {
  const { id, jobId } = useParams();
  const workflow = useRecoilValueLoadable(workflowSelectorFamily(id));
  const { hash: taskIdFromUrl } = useLocation();
  const navigate = useNavigate();

  const { data: job } = useFetchAndPoll({
    id: { id, jobId },
    key: `workflows/job/${id}-${jobId}`,
    fetch: WorkflowService.polling.jobs.get,
  });

  const { data: tasks } = useFetchAndPoll({
    id: { id, jobId },
    key: `workflows/tasks/${id}-${jobId}`,
    fetch: WorkflowService.polling.jobs.tasks,
  });

  const [activeTask, setActiveTask] = useState(null);

  useEffect(() => {
    if (!tasks?.length) return;

    const taskToSetActive = tasks.find((t) => taskIdFromUrl.includes(t.id)) || tasks[0];

    if (taskToSetActive?.id) {
      setActiveTask(taskToSetActive);
      navigate(`#${taskToSetActive.id}`, { replace: true });
    }
  }, [taskIdFromUrl, tasks, navigate]);

  const handleTaskClick = (taskId) => {
    const task = tasks.find((t) => t.id === taskId);
    setActiveTask(task);
    navigate(`#${taskId}`, { replace: true });
  };

  const jobMetadata = getJobMetadata(job);

  return (
    <Layout>
      <Card className="p-3 mb-10">
        <Card.Body>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link className="uppercase-label" to="/workflows/overview">
                Workflows
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link className="uppercase-label" to="/workflows/overview">
                My Workflows
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link className="uppercase-label" to={`/workflows/${id}`}>
                {workflow.state === 'hasValue' && workflow.contents.name}
              </Link>
            </li>
          </ol>

          <div>
            {!job ? (
              <Skeleton className="mt-4" />
            ) : (
              <>
                <Row>
                  <Col>
                    <h1 className="mb-8">Job Details</h1>
                  </Col>
                  <Col xs={12} lg={6}>
                    <div className="d-flex justify-content-center justify-content-lg-end gap-2 mb-4">
                      <WorkflowEditButton id={id} name={workflow.contents.name} />
                    </div>
                  </Col>
                </Row>
                <TableMetaData data={jobMetadata} />
              </>
            )}
          </div>
        </Card.Body>
      </Card>

      <Card className="mb-8">
        <Card.Body>
          <div className="workflow__job">
            <div className="workflow__job-inner">
              <div className="workflow__job-tasks">
                <div className="workflow__job-left" style={{ backgroundImage: `url(${tile})` }}>
                  {!tasks ? (
                    <Skeleton length={5} />
                  ) : (
                    <WorkflowJobTaskList activeTaskId={activeTask?.id} tasks={tasks} onClick={handleTaskClick} />
                  )}
                </div>
                <div className="workflow__job-right">
                  {!activeTask ? <Skeleton length={5} /> : <ActiveTaskDetails task={activeTask} />}
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Layout>
  );
}

export default WorkflowJobDetail;
