import { useRecoilState } from 'recoil';

import { IconWebhook } from '@assets/icons';

import InputText from '@components/controls/InputText';

import { callbackState } from '@store/atoms/EditState';

function SettingWebhook() {
  const [callback, setCallback] = useRecoilState(callbackState);

  const handleWebhookChange = (event) => {
    const { value } = event.target;
    setCallback({ src: value });
  };

  return (
    <div className="md-10">
      <h6>Webhook</h6>
      <InputText
        id="webhook"
        title="Callback URL"
        value={callback?.src || ''}
        prefix={<IconWebhook size={18} />}
        handleChange={handleWebhookChange}
      />
    </div>
  );
}

export default SettingWebhook;
