import { atom, useRecoilCallback } from 'recoil';

import { MAX_ZOOM, MIN_ZOOM } from '@constants/Canvas';

export const canvasState = atom({
  key: 'canvas/dimensions',
  default: {
    width: undefined,
    height: undefined,
  },
});

export const stageState = atom({
  key: 'stage/dimensions',
  default: {
    width: undefined,
    height: undefined,
    scale: 1,
  },
});

export const canvasZoomAtom = atom({
  key: 'canvas/zoom',
  default: 1,
});

const zoomInCallback = (callbackArgs) => {
  const { set } = callbackArgs;
  return () => set(canvasZoomAtom, (prev) => Math.min(prev + 0.1, MAX_ZOOM));
};

const zoomOutCallback = (callbackArgs) => {
  const { set } = callbackArgs;
  return () => set(canvasZoomAtom, (prev) => Math.max(prev - 0.1, MIN_ZOOM));
};

export const useZoomIn = () => useRecoilCallback(zoomInCallback);
export const useZoomOut = () => useRecoilCallback(zoomOutCallback);
