import cn from 'classnames';

import NavbarMain from '@components/navbar/NavbarMain';
import SidebarMain from '@components/navbar/SidebarMain';

function Layout({ children, className }) {
  return (
    <div className={cn('max-w-screen-4xl mx-auto', className)}>
      <NavbarMain />
      <SidebarMain />
      <main>
        <div className="p-10">{children}</div>
      </main>
    </div>
  );
}

export default Layout;
