import { roundKeyframeValue } from '@animation/keyframe';
import { isArray } from 'lodash-es';

import { enabledKeyframeProperties, enabledKeyframePropertiesDefaults } from '@constants/Keyframes';

export const processKeyframesIncoming = (clip) => {
  try {
    const keyframes = Object.entries(clip)
      .filter(([property, values]) => enabledKeyframeProperties.includes(property) && isArray(values))
      .flatMap(([property, keyframes]) => {
        const sortedKeyframes = [...keyframes].sort((a, b) => a.start - b.start);
        const hasKeyframeAtZero = sortedKeyframes.some((kf) => kf.start === 0);
        const result = sortedKeyframes.flatMap(({ from, to, start, length, interpolation, easing }) => [
          { time: start, property, value: from, interpolation, easing },
          { time: roundKeyframeValue(start + length), property, value: to, interpolation, easing },
        ]);
        if (!hasKeyframeAtZero) {
          const value = enabledKeyframePropertiesDefaults[property];
          result.unshift({ time: 0, property, value, interpolation: 'linear', easing: 'linear' });
        }
        return result;
      })
      .reduce((acc, { time, property, value, interpolation, easing }) => {
        acc[time] = { ...acc[time], [property]: { value, interpolation, easing } };
        return acc;
      }, {});

    return keyframes;
  } catch (error) {
    console.error('Error processing incoming keyframes:', error);
    return {};
  }
};

export const processKeyframesForAnimation = (keyframes) => {
  try {
    const sortedKeyframes = [...keyframes].sort((a, b) => a.time - b.time);
    const propertyMap = sortedKeyframes.reduce((acc, { time, id, interpolation, easing, ...props }) => {
      Object.entries(props).forEach(([prop, value]) => {
        if (value !== undefined) {
          acc[prop] = { ...(acc[prop] || {}), [time]: value };
        }
      });
      return acc;
    }, {});

    const result = Object.entries(propertyMap).flatMap(([property, timeValuePairs]) => {
      const times = Object.keys(timeValuePairs)
        .map(Number)
        .sort((a, b) => a - b);

      return times.slice(0, -1).map((currentTime, index) => {
        const nextTime = times[index + 1];
        const currentKeyframe = sortedKeyframes.find((kf) => kf.time === currentTime);
        return {
          from: { [property]: timeValuePairs[currentTime] },
          to: { [property]: timeValuePairs[nextTime] },
          start: currentTime,
          end: nextTime,
          interpolation: currentKeyframe?.interpolation,
          easing: currentKeyframe?.easing,
        };
      });
    });

    return result;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const processKeyframesOutgoing = (keyframes) => {
  try {
    const result = {};
    const sortedKeyframes = [...keyframes].sort((a, b) => a.time - b.time);

    for (let i = 0; i < sortedKeyframes.length - 1; i++) {
      const current = sortedKeyframes[i];
      const next = sortedKeyframes[i + 1];

      const propertiesWithValues = Object.keys(current).filter((key) => {
        return current[key] !== undefined && !['id', 'time', 'interpolation', 'easing'].includes(key);
      });

      propertiesWithValues.forEach((property) => {
        if (!result[property]) {
          result[property] = [];
        }

        // Only add keyframe if the value changes
        if (next[property] !== undefined) {
          const keyframe = {
            start: roundKeyframeValue(current.time),
            length: roundKeyframeValue(next.time - current.time),
            from: current[property],
            to: next[property],
            interpolation: current.interpolation[property],
            easing: current.easing[property],
          };

          result[property].push(keyframe);
        }
      });
    }

    // Clean up empty arrays
    Object.keys(result).forEach((key) => {
      if (result[key].length === 0) {
        delete result[key];
      }
    });

    return result;
  } catch (error) {
    console.error(error);
    return {};
  }
};
