import cn from 'classnames';
import { memo } from 'react';

import { IconImage } from '@assets/icons';

import TimelineClipInfo from '@feature/studio/timeline/TimelineClipInfo';

function TimelineClipImage({ id, clip }) {
  return (
    <div className={cn('clip__inner', { loading: !clip['asset:src'] })}>
      <div className="clip__header">
        <span className="clip__icon">
          <IconImage size={18} />
        </span>
        <TimelineClipInfo id={id} />
      </div>
      <div className="clip__background" style={{ '--image-url': `url("${clip['asset:src']}")` }} />
    </div>
  );
}

export default memo(TimelineClipImage);
