import { atom, selector } from 'recoil';

import { formatAtom } from '@store/studio/Output';

export const FIRST_FRAME_TIME = 0;
export const PLAYABLE_FORMATS = ['mp4', 'mp3'];

export const playheadState = atom({
  key: 'playheadState',
  default: FIRST_FRAME_TIME,
});

export const playheadIsPlayingState = atom({
  key: 'playheadIsPlayingState',
  default: false,
});

export const playheadIsPausedState = atom({
  key: 'playheadIsPausedState',
  default: false,
});

export const playheadIsStoppedState = atom({
  key: 'playheadIsStoppedState',
  default: true,
});

export const playableSelector = selector({
  key: 'playable',
  get: ({ get }) => {
    const format = get(formatAtom);
    return PLAYABLE_FORMATS.includes(format);
  },
});
