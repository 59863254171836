import { useRecoilValue } from 'recoil';

import CanvasClip from '@feature/studio/canvas/CanvasClip';

import { trackClipIdsState } from '@store/selectors/EditSelectors';

const getClipIndex = (trackIndex, clipIndex) => {
  const index = trackIndex + clipIndex / 100;
  return index;
};

function CanvasTrack({ id, index: trackIndex }) {
  const clipIds = useRecoilValue(trackClipIdsState(id));
  const trackClips = clipIds.slice().reverse().filter(Boolean);

  return trackClips.map((clipId, clipIndex) => (
    <CanvasClip key={clipId} id={clipId} index={getClipIndex(trackIndex, clipIndex)} />
  ));
}

export default CanvasTrack;
