import dataClient from '@api/clients/data';
import ingestClient from '@api/clients/ingest';
import { transformSources, transformSourcesByCursor } from '@api/transform/sources';

import { jsonParse } from '@utils/jsonParse';

export const transformSourcesByCursorAxios = [jsonParse];

export const getSourceOutputs = async (id, config) => {
  const url = `sources/${id}`;
  const { data } = await ingestClient.get(url, config);
  return data?.body?.data;
};

export const getSource = async (id, config) => {
  const url = `sources/${id}`;
  const { data } = await dataClient.get(url, config);
  return data?.body?.data;
};

export const getSources = async (filterParams) => {
  const url = `sources`;
  const { data } = await dataClient.get(url, { params: filterParams });
  return transformSources(data?.body);
};

export const getSourcesByCursor = async ({ limit = 30, next }) => {
  const url = `sources`;
  const params = {
    include: 'renditions',
    'page[limit]': limit,
    'page[next]': next,
  };
  const { data } = await ingestClient.get(url, { params, transformResponse: transformSourcesByCursorAxios });
  return transformSourcesByCursor(data?.body?.data);
};

export const deleteSource = async (id) => {
  const url = `sources/${id}`;
  return ingestClient.delete(url);
};

export default {
  get: getSource,
  getAll: getSources,
  getAllByCursor: getSourcesByCursor,
  renditions: {
    getAll: getSourceOutputs,
  },
  delete: deleteSource,
};
