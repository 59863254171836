import { useRecoilValue } from 'recoil';

import InputText from '@components/controls/InputText';

import { idState } from '@store/atoms/TemplateState';

const s3Options = [
  { label: 'prefix', placeholder: 'sandbox' },
  { label: 'filename', placeholder: 'my-file' },
  { label: 'bucket', placeholder: 'my-gcp-bucket' },
];

function GoogleCloudStorage({ destination, setDestination }) {
  const templateId = useRecoilValue(idState);

  const tracking = {
    properties: {
      Id: templateId,
    },
  };

  const handleOptionsChange = (event) => {
    const { name, value } = event.target;
    setDestination((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options,
        [name]: value,
      },
    }));
  };

  return (
    <div className="d-flex flex-column gap-2">
      {s3Options.map(({ label, placeholder }) => (
        <InputText
          key={label}
          name={label}
          label={label}
          placeholder={placeholder}
          value={destination?.options?.[label]}
          handleChange={handleOptionsChange}
          tracking={{ name: `Change Google Cloud Storage ${label}`, ...tracking }}
        />
      ))}
    </div>
  );
}

export default GoogleCloudStorage;
