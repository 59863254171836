import { Toast, ToastContainer } from 'react-bootstrap';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { IconExclamationTriangleFill } from '@assets/icons';

import { errorState } from '@store/atoms/UIState';

const DEFAULT_ERROR_TYPE = 'error';
const DEFAULT_ERROR_TITLE = 'Something went wrong';

function ErrorToast() {
  const { type = DEFAULT_ERROR_TYPE, title = DEFAULT_ERROR_TITLE, message } = useRecoilValue(errorState);
  const resetErrorMessage = useResetRecoilState(errorState);

  return (
    <ToastContainer position="top-center" className={`p-3 toast-${type}`}>
      <Toast show={Boolean(message)} onClose={resetErrorMessage}>
        <Toast.Header>
          <div className="d-flex align-items-center gap-2 me-auto">
            <IconExclamationTriangleFill size={22} />
            <span className="font-bold">{title}</span>
          </div>
        </Toast.Header>
        <Toast.Body>
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export default ErrorToast;
