import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';

import { IconReset } from '@assets/icons';

// import { IconReset } from '@assets/icons';
import WorkflowEditButton from '@feature/workflows/buttons/WorkflowEditButton';
import WorkflowListRunTestButton from '@feature/workflows/buttons/WorkflowListRunTestButton';

import { useAnalytics } from '@components/analytics/Analytics';
import Header from '@components/header/Header';
import Layout from '@components/layout/Layout';
import ResourceFilter from '@components/resources/ResourceFilter';
import ResourceHistoryWorkflowJobs from '@components/resources/ResourceHistoryWorkflowJobs';
import Skeleton from '@components/skeletons/Skeleton';
import TableMetaData from '@components/table/MetaData';

import { workflowSelectorFamily } from '@store/Workflows';

import useGetWorkflowJobs from '@hooks/useGetWorkflowJobs';

const getWorkflowMetadata = ({ meta }) => {
  if (!meta) return [];

  return [
    ['Created', meta.created, 'created'],
    ['Last Ran', meta.lastRan, 'lastRan'],
    [
      'Webhook URL',
      meta.url && (
        <code style={{ userSelect: 'all', cursor: 'cell' }} key="url">
          {meta.url}
        </code>
      ),
      'url',
    ],
  ].filter(([, value]) => value);
};

const defaultValues = {
  startDateRange: dayjs().subtract(1, 'months').startOf('month').format('YYYY-MM-01T00:00'),
  endDateRange: dayjs().add(24, 'hours').format('YYYY-MM-DDT00:00'),
  status: '',
  limit: '',
  offset: '',
};

function WorkflowDetail() {
  const { trackEvent } = useAnalytics();
  const { id } = useParams();
  const { state, contents } = useRecoilValueLoadable(workflowSelectorFamily(id));
  const { data, loading, fetchData } = useGetWorkflowJobs({ id });

  const [searchParams, setSearchParams] = useSearchParams();

  const updateFilter = (params) => {
    const newSearchParams = new URLSearchParams(searchParams);
    ['startDateRange', 'endDateRange', 'status', 'limit', 'offset'].forEach((key) =>
      newSearchParams.set(key, params[key])
    );
    setSearchParams(newSearchParams, { replace: true });
    fetchData(params);
  };

  const refreshJobs = () => {
    fetchData(defaultValues);
  };

  useEffect(() => {
    trackEvent('View Workflow Detail', {
      Id: id,
    });
  }, []);

  useEffect(() => {
    updateFilter({
      startDateRange: searchParams.get('startDateRange') || defaultValues.startDateRange,
      endDateRange: searchParams.get('endDateRange') || defaultValues.endDateRange,
      status: searchParams.get('status') || defaultValues.status,
      limit: searchParams.get('limit') || defaultValues.limit,
      offset: searchParams.get('offset') || defaultValues.offset,
    });
  }, [searchParams]);

  const metaData = getWorkflowMetadata(contents);

  if (state === 'loading') {
    return (
      <Layout>
        <Skeleton length={5} />
      </Layout>
    );
  }

  if (state === 'hasError' || !contents) {
    console.error('Error loading workflow', contents);
    return (
      <Layout>
        <Header title="Something went wrong">
          <p>There was an error loading this workflow.</p>
        </Header>
      </Layout>
    );
  }

  return (
    <Layout>
      <Card className="p-3 mb-2">
        <Card.Body>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link className="uppercase-label" to="/workflows/overview">
                Workflows
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link className="uppercase-label" to="/workflows/overview">
                My Workflows
              </Link>
            </li>
          </ol>

          <Row>
            <Col>
              <h1>{contents.name}</h1>
            </Col>
            <Col xs={12} lg={6}>
              <div className="d-flex justify-content-center justify-content-lg-end gap-2 mb-4">
                <Button onClick={refreshJobs} variant="light">
                  <IconReset />
                </Button>
                <WorkflowEditButton id={id} name={contents.name} />
                <WorkflowListRunTestButton id={id} onRunSuccess={refreshJobs} />
              </div>
            </Col>
          </Row>

          <TableMetaData data={metaData} />
        </Card.Body>
      </Card>
      <Card className="p-3 mb-10">
        <Card.Body>
          <ResourceFilter
            product="workflow-jobs"
            startDateRange={searchParams.get('startDateRange')}
            endDateRange={searchParams.get('endDateRange')}
            status={searchParams.get('status')}
            limit={searchParams.get('limit')}
            offset={searchParams.get('offset')}
            onFilterChange={updateFilter}
          />
        </Card.Body>
      </Card>

      <ResourceHistoryWorkflowJobs data={data} loading={loading} workflowId={id} />
    </Layout>
  );
}

export default WorkflowDetail;
