import '@css/TimelinePlayhead.css';

import Playhead from '@assets/img/icons/playhead.svg?react';

import TimelinePlayheadReactable from '@feature/studio/timeline/TimelinePlayheadReactable';

function TimelinePlayhead() {
  return (
    <TimelinePlayheadReactable>
      <div className="timeline-playhead">
        <div className="timeline-playhead__icon">
          <Playhead />
        </div>
      </div>
    </TimelinePlayheadReactable>
  );
}

export default TimelinePlayhead;
