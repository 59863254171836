import axios from 'axios';

import { controllerManager } from '@api/utils/AbortControllerManager';
import { pollDataManager } from '@api/utils/PollDataManager';

import { delay } from '@utils/delay';

const startPolling = async (client, url, predicate, dataKey) => {
  const controller = controllerManager.getController(dataKey);

  while (true) {
    try {
      const response = await client.request(url, {
        signal: controller.signal,
      });

      pollDataManager.publish(dataKey, response);

      if (predicate(response)) {
        return response;
      }
      await delay();
    } catch (error) {
      if (axios.isCancel(error)) {
        break;
      }
      console.error('Polling error:', error);
      throw error;
    }
  }
};

export { startPolling };
