import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { IconBolt, IconCode, IconDuration, IconEnvelopeHeart, IconFullscreen, IconWindow } from '@assets/icons';

import { useAuth0 } from '@context/Auth0ReactSPA';

import CancelItem from '@feature/subscription/CancelItem';
import PaygListItem from '@feature/subscription/PaygListItem';
import SubscriptionListItem from '@feature/subscription/SubscriptionListItem';
import CreditsPanel from '@feature/usage/CreditsPanel';

import { useAnalytics } from '@components/analytics/Analytics';
import ButtonElement from '@components/atoms/ButtonElement';
import Content from '@components/content/Content';
import Header from '@components/header/Header';
import Layout from '@components/layout/Layout';
import Skeleton from '@components/skeletons/Skeleton';

import { authenticatedUser } from '@store/atoms/AuthState';
import { subscriberSelector, subscriptionsSelector } from '@store/atoms/SubscriptionsState';

function Subscription() {
  const { show } = useIntercom();
  const { loading, user } = useAuth0();
  const { trackEvent } = useAnalytics();
  const location = useLocation();
  const navigate = useNavigate();

  const authUser = useRecoilValue(authenticatedUser);
  const { contents: subscriptions } = useRecoilValueLoadable(subscriptionsSelector);
  const { contents: subscriber } = useRecoilValueLoadable(subscriberSelector);
  const currentMonthStart = dayjs().startOf('month').format('YYYY-MM-DD');
  const isEnterprisePlan = authUser?.plans?.v1 === 'enterprise';

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('paid') === 'true' && searchParams.get('credits')) {
      trackEvent('Purchase', { Type: 'Payg', Plan: `Payg${searchParams.get('credits')}` });

      searchParams.delete('paid');
      searchParams.delete('credits');

      navigate({ pathname: location.pathname, search: searchParams.toString() }, { replace: true });
    }
  }, [location.search, navigate, trackEvent]);

  if (loading || !user || !subscriptions) {
    return <div />;
  }
  const {
    payg25,
    payg50,
    payg100,
    payg250,
    payg500,
    payg1000,
    payg10000,
    plus200,
    plus500,
    plus1000,
    plus2k,
    plus5k,
    plus10k,
    plus25k,
  } = subscriptions || {};

  const sdkUsers = {
    plus200: {
      code: 'plus200',
      users: 10,
    },
    plus500: {
      code: 'plus500',
      users: 20,
    },
    plus1000: {
      code: 'plus1000',
      users: 40,
    },
    plus2k: {
      code: 'plus2k',
      users: 80,
    },
    plus5k: {
      code: 'plus5k',
      users: 200,
    },
    plus10k: {
      code: 'plus10k',
      users: 400,
    },
    plus25k: {
      code: 'plus25k',
      users: 1000,
    },
  };

  const professionalSubscriptions = [plus200, plus500, plus1000, plus2k, plus5k, plus10k, plus25k];
  const paygPlans = [payg25, payg50, payg100, payg250, payg500, payg1000, payg10000];

  if (isEnterprisePlan) {
    return (
      <Layout>
        <Header title="Your Subscription">
          <p>
            Your account is set up as an <code>Enterprise</code> account. Please contact your account manager to discuss
            your plan.
          </p>
        </Header>
      </Layout>
    );
  }

  return (
    <Layout className="pb-4">
      <div className="text-prose mb-16">
        <p className="uppercase-label">Billing</p>
        <h1>Your Subscription</h1>
        <p className="text-gray-500">
          Purchase credits or subscribe for more savings, fitting the demands of your application, workflow, or
          campaign.
        </p>
      </div>

      <Content>
        <CreditsPanel user={authUser} subscription={subscriber?.subscription} currentMonthStart={currentMonthStart} />
      </Content>

      <Content>
        <Row>
          <Col sm={12} xl={6} className="my-3">
            <Card className="payg">
              <Card.Body>
                {subscriber?.subscription && Object.keys(subscriptions).length > 0 ? (
                  <PaygListItem
                    subscriptions={subscriptions}
                    paygPlans={paygPlans}
                    user={user}
                    subscriber={subscriber}
                    disabled={professionalSubscriptions.some((sub) => sub.code === subscriber?.subscription?.code)}
                  />
                ) : (
                  <Skeleton length={3} />
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} xl={6} className="my-3">
            <Card className="subscription">
              <Card.Body>
                {subscriber?.subscription && Object.keys(subscriptions).length > 0 ? (
                  <SubscriptionListItem
                    subscriptions={subscriptions}
                    professionalSubscriptions={professionalSubscriptions}
                    subscriber={subscriber}
                    sdkUsers={sdkUsers}
                  />
                ) : (
                  <Skeleton length={3} />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Content>

      <Content heading="ALL PLANS INCLUDE">
        <Row>
          <Col sm={6} lg={4} xl={2} className="my-3">
            <Card className="h-100">
              <Card.Body className="text-center">
                <IconFullscreen size={36} className="mt-5" />
                <p className="font-bold mt-5">1080p Resolution</p>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} lg={4} xl={2} className="my-3">
            <Card className="h-100">
              <Card.Body className="text-center">
                <IconCode size={36} className="mt-5" />
                <p className="font-bold mt-5">Developer Sandbox</p>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} lg={4} xl={2} className="my-3">
            <Card className="h-100">
              <Card.Body className="text-center">
                <IconWindow size={36} className="mt-5" />
                <p className="font-bold mt-5">Video Editor & Player SDK</p>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} lg={4} xl={2} className="my-3">
            <Card className="h-100">
              <Card.Body className="text-center">
                <IconDuration size={36} className="mt-5" />
                <p className="font-bold mt-5">3 hour render length</p>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} lg={4} xl={2} className="my-3">
            <Card className="h-100">
              <Card.Body className="text-center">
                <IconBolt size={36} className="mt-5" />
                <p className="font-bold mt-5">Industry-leading rendering speeds</p>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} lg={4} xl={2} className="my-3">
            <Card className="h-100">
              <Card.Body className="text-center">
                <IconEnvelopeHeart size={36} className="mt-5" />
                <p className="font-bold mt-5">Email support</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Content>

      <Content heading="HIGH VOLUME SOLUTIONS">
        <Row>
          <Col>
            <Card className="subscription">
              <Card.Body>
                <Row>
                  <Col sm={12}>
                    <p>
                      For solutions requiring high-volume credits or white-label editor usage please get in touch with
                      us.
                    </p>
                    <ButtonElement handleEvent={show} variant="dark" className="mt-2">
                      Contact us
                    </ButtonElement>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Content>

      {subscriber?.subscription?.code !== 'free' && subscriber?.subscription?.code !== 'freeTrial' && (
        <Content heading="CANCEL">
          <Row>
            <Col>
              <Card className="subscription">
                <Card.Body>
                  <Row>
                    <Col sm={12}>
                      {Object.keys(subscriptions).length > 0 ? (
                        <CancelItem subscription={subscriptions.free} user={authUser} subscriber={subscriber} />
                      ) : (
                        <Skeleton />
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Content>
      )}
    </Layout>
  );
}

export default Subscription;
