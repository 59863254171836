import { useState } from 'react';
import { useRecoilState } from 'recoil';

import '@css/OutputPresetButtons.css';

import OutputPresetButton from '@feature/studio/configuration/OutputPresetButton';

import { formatAtom } from '@store/studio/Output';

const DEFAULT_OUTPUT_FORMAT = 'video';

const getOutputFormat = (format) => {
  if (!format) {
    return undefined;
  }
  if (format === 'mp3') {
    return 'audio';
  }
  if (format === 'mp4') {
    return 'video';
  }
  return 'image';
};

function OutputPresetButtons({ presets }) {
  const [format, setFormat] = useRecoilState(formatAtom);
  const [selectedType, setSelectedType] = useState(getOutputFormat(format) || DEFAULT_OUTPUT_FORMAT);

  const handleSelectPreset = (type) => () => {
    const { format } = presets[type];
    setSelectedType(type);
    setFormat(format);
  };

  return (
    <>
      {Object.entries(presets).map(([type, { title, icon, caption }]) => (
        <OutputPresetButton
          key={type}
          type={type}
          title={title}
          icon={icon}
          caption={caption}
          selected={selectedType === type}
          onClick={handleSelectPreset(type)}
        />
      ))}
    </>
  );
}

export default OutputPresetButtons;
