import { Graphics } from '@inlet/react-pixi';
import { useEffect, useRef } from 'react';

const CenterLines = ({ width, height, snappingLines }) => {
  const graphicsRef = useRef(null);

  useEffect(() => {
    const graphics = graphicsRef.current;
    graphics.clear();

    const drawLine = (startX, startY, endX, endY, lineThickness, lineColor) => {
      graphics.lineStyle(lineThickness, lineColor, 1);
      graphics.moveTo(startX, startY);
      graphics.lineTo(endX, endY);
    };

    if (snappingLines.x) {
      drawLine(width / 2, 0, width / 2, height, 2, 0x9747ff);
    }

    if (snappingLines.y) {
      drawLine(0, height / 2, width, height / 2, 2, 0x9747ff);
    }
  }, [width, height, snappingLines]);

  return <Graphics ref={graphicsRef} />;
};

export default CenterLines;
