import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import '@css/Dashboard.css';

import AlertBox from '@components/alert/AlertBox';
import Content from '@components/content/Content';
import KeysList from '@components/keys/Keys';
import Layout from '@components/layout/Layout';
import StageSelect from '@components/stageSelect/StageSelect';

function Keys() {
  return (
    <Layout>
      <div className="text-prose mb-16">
        <p className="uppercase-label">API Keys and Envrionment</p>
        <h1>Your API Keys</h1>

        <p>
          An API Key is needed to connect your application to the Shotstack API. Use the Sandbox key for testing and
          developing and when you are ready, check your <Link to="/subscription">subscription</Link> and use the Prod
          key for your live application without watermarks.
        </p>
        <p>
          Once ready, follow this tutorial to{' '}
          <a href="https://shotstack.io/docs/guide/getting-started/hello-world-using-curl">build your first video</a>.
        </p>
      </div>

      <Content heading="API KEYS">
        <Card className="mb-4">
          <Card.Body>
            <KeysList />
            <AlertBox
              type="alert"
              name={'Sandbox Credit Usage'}
              message={
                'The sandbox environment is free to use and does not consume any credits. We highly recommend using this environment for all testing and development activities.'
              }
            ></AlertBox>
          </Card.Body>
        </Card>
      </Content>
      <Content heading="ENVIRONMENT">
        <Card className="mb-4">
          <Card.Body>
            <p>You can use your sandbox account as much as you like, rate limits apply and videos are watermarked.</p>
            <Col md={4}>
              <StageSelect />
            </Col>
          </Card.Body>
        </Card>
      </Content>
    </Layout>
  );
}

export default Keys;
