import config from '@config';
import { useEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { IconArrowRight, IconClipboard, IconClipboardCheck } from '@assets/icons';

import GettingStartedMergeField from '@feature/welcome/GettingStartedMergeField';

import { useAnalytics } from '@components/analytics/Analytics';
import ButtonElement from '@components/atoms/ButtonElement';
import PrismSnippet from '@components/code/PrismSnippet';
import InputText from '@components/controls/InputText';
import KeysList from '@components/keys/Keys';

import { authToken } from '@store/atoms/AuthState';
import { mergeIdsState } from '@store/atoms/EditState';
import { useUpdateMergeFieldState } from '@store/atoms/MergeState';
import { snippetLangState, snippetTypeState } from '@store/atoms/SnippetState';
import { stageAtom } from '@store/atoms/StageState';
import { snippetState } from '@store/selectors/SnippetSelectors';

import useAxiosPoll from '@hooks/useAxiosPoll';

const isVideoPlaying = (video) =>
  !!(video?.currentTime > 0 && !video?.paused && !video?.ended && video?.readyState > 2);

const UUID_REGEX = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;

const RENDER_ENDPOINT = 'render';
const RENDER_STATUS_DONE = 'done';
const RENDER_STATUS_FAILED = 'failed';
const RENDER_POLLING_INTERVAL = 2000;
const RENDER_STATUS_MESSAGE = 'Waiting for UUID...';

function GettingStartedBody({ steps, activeStep, onNext, onComplete }) {
  const videoRef = useRef(null);
  const { trackEvent } = useAnalytics();
  const [copied, setCopied] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [renderId, setRenderId] = useState('');
  const [renderIdSubmitted, setRenderIdSubmitted] = useState(false);
  const [videoSrc, setVideoSrc] = useState(undefined);
  const [videoStatus, setVideoStatus] = useState(RENDER_STATUS_MESSAGE);
  const { startPolling } = useAxiosPoll();

  const handleUpdateMergeField = useUpdateMergeFieldState();
  const mergeFieldIds = useRecoilValue(mergeIdsState);
  const snippetStateValue = useRecoilValue(snippetState);
  const setSnippetLang = useSetRecoilState(snippetLangState);
  const setSnippetType = useSetRecoilState(snippetTypeState);
  const token = useRecoilValue(authToken);
  const stage = useRecoilValue(stageAtom);

  const requestHeaders = {
    headers: {
      Authorization: `Bearer ${token}`,
      stage,
      'User-Agent': config.headers.userAgent,
    },
  };

  useEffect(() => {
    setSnippetLang('http');
    setSnippetType('id');
  }, []);

  const getStatus = () => {
    const url = `${config.edit.url}${RENDER_ENDPOINT}/${renderId}?data=false`;
    const pollConfig = requestHeaders;
    const onPollCheck = (response) => {
      const { response: attributes } = response.data;
      setVideoStatus(attributes?.status);
      return [RENDER_STATUS_DONE, RENDER_STATUS_FAILED].includes(attributes?.status);
    };

    return startPolling({
      url,
      config: pollConfig,
      interval: RENDER_POLLING_INTERVAL,
      onPollCheck,
    });
  };

  const validateRenderId = () => {
    return !renderId || !UUID_REGEX.test(renderId);
  };

  const onRenderIdSubmit = async () => {
    setErrorMessage(undefined);

    if (validateRenderId()) {
      setErrorMessage('The render ID is invalid.');
      return;
    }

    setRenderIdSubmitted(true);
    try {
      const response = await getStatus();
      const { response: attributes } = response.data;
      if (attributes?.status === RENDER_STATUS_FAILED) {
        throw new Error('Something went wrong, please try again.');
      }
      videoRef.current.scrollIntoView({ behavior: 'smooth' });
      setVideoSrc(attributes.url);
    } catch (error) {
      console.error({ error });
      setRenderIdSubmitted(false);
      if (error?.response?.status === 400) {
        setErrorMessage('The render ID is invalid.');
        return;
      }
      setErrorMessage(error?.message);
    }
  };

  const handleVideoError = (event) => {
    console.error('Error loading video:', event.target.error);
  };

  const stopAndResetVideo = () => {
    if (isVideoPlaying(videoRef.current)) {
      videoRef.current.pause();
    }
    videoRef.current.removeAttribute('src');
    videoRef.current.load();
  };

  const handleCopy = () => {
    setCopied(true);
    trackEvent('Select Copy Snippet - Getting Started', {
      Context: 'Button',
      properties: {
        Version: 1,
        'Step Number': activeStep,
      },
    });

    const resetCopiedTimeout = setTimeout(() => {
      setCopied(false);
      clearTimeout(resetCopiedTimeout);
    }, 2000);
  };

  const handleNext = () => {
    trackEvent(`Select Next Step - Getting Started`, {
      Context: 'Button',
      properties: {
        Version: 1,
        'Step Number': activeStep,
      },
    });
    setRenderId('');
    setRenderIdSubmitted(false);
    setVideoStatus(RENDER_STATUS_MESSAGE);
    setVideoSrc(undefined);
    stopAndResetVideo();

    onNext();
  };

  if (activeStep === 3) {
    trackEvent(`Complete - Getting Started`, {
      Context: 'View',
      properties: {
        Version: 1,
        'Step Number': activeStep,
      },
    });

    return (
      <div className="welcome__inner">
        <div className="welcome__header">
          <h2 className="welcome__title centered">{steps[activeStep].title}</h2>
          <p className="centered" dangerouslySetInnerHTML={{ __html: steps[activeStep].text }} />
          <KeysList />
          <div className="centered">
            <ButtonElement handleEvent={onComplete}>
              Dashboard
              <IconArrowRight className="ms-2" />
            </ButtonElement>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="welcome__inner">
      {activeStep === 1 && (
        <>
          <p className="centered">
            We&apos;ll take you through how easy it is to create a video from a template using the Edit API. <br />
            <br />
            For more advanced API use cases <a href="https://shotstack.io/docs/guide/">please read the docs</a>.
          </p>
          <hr className="mb-4 pb-4 " />
        </>
      )}
      <div className={renderIdSubmitted ? 'opacity-50' : ''}>
        <p className="welcome__step">Step 1</p>
        <div className="welcome__header">
          <h2 className="welcome__title">{steps[activeStep].title}</h2>
          <p dangerouslySetInnerHTML={{ __html: steps[activeStep].text }} />
          {activeStep === 2 &&
            mergeFieldIds.map((id) => (
              <GettingStartedMergeField key={id} id={id} handleUpdateMergeField={handleUpdateMergeField} />
            ))}
        </div>
        <div className="welcome__snippet">
          <CopyToClipboard text={snippetStateValue} onCopy={handleCopy}>
            <Button className="btn-copy" title="Copy to clipboard" variant={copied ? 'primary' : 'light'} type="button">
              {copied ? <IconClipboardCheck size="16" /> : <IconClipboard size="16" />}
            </Button>
          </CopyToClipboard>
          <PrismSnippet text={snippetStateValue} />
        </div>
      </div>
      <p className="welcome__step">Step 2</p>
      <div className="welcome__header">
        <h2 className="welcome__title">Preview your render</h2>
        <p>Copy and paste the ID from the response to see your video</p>
      </div>

      <div className="welcome__player">
        <div className="welcome__input">
          <InputText
            label="Video Id"
            placeholder="df113ea3-9fdc-1232-b9c3-fb987b179a18"
            value={renderId}
            handleChange={(e) => setRenderId(e.target.value)}
            handleBlur={onRenderIdSubmit}
            disabled={renderIdSubmitted}
          />
          {errorMessage && <p className="input-error mt-2">{errorMessage}</p>}
        </div>
        <div className="welcome__video">
          <video ref={videoRef} src={videoSrc} muted autoPlay controls onError={handleVideoError} />
          {!videoSrc && (
            <div className="welcome__video-loader">
              <div>
                <Spinner animation="border" role="status" variant="light">
                  <span className="visually-hidden">{videoStatus}...</span>
                </Spinner>
                <p>{videoStatus}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {activeStep === 1 && videoSrc && (
        <div className="welcome__step-complete">
          <h2>Easy peasy 🍋</h2>
          <p>Your first video is done, now let&apos;s customise it with your own data...</p>

          <ButtonElement handleEvent={handleNext}>
            Next: Customise your video
            <IconArrowRight className="ms-2" />
          </ButtonElement>
        </div>
      )}
      {activeStep === 2 && videoSrc && (
        <div className="welcome__step-complete">
          <h2>Cus-to-mised 🙌</h2>
          <p>
            This is a simple customisation example. You can add more merge fields <br /> in your videos, to create
            highly engaging and personalised experiences.
          </p>

          <ButtonElement handleEvent={handleNext}>
            Give me the keys
            <IconArrowRight className="ms-2" />
          </ButtonElement>
        </div>
      )}
    </div>
  );
}

export default GettingStartedBody;
