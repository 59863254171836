import getCleanUrl from '@utils/getCleanUrl';
import IndexedDBUtil from '@utils/indexedDBUtils';

const dbMediaCaptures = new IndexedDBUtil({ db: 'media', table: 'captures', version: '004' });
const dbTemplateMedia = new IndexedDBUtil({ db: 'templates', table: 'media', version: '001' });

export const mediaCapturePersist = (param) => [
  ({ setSelf, onSet }) => {
    const key = getCleanUrl(param);

    async function loadData() {
      let status = 'failed-restore';
      let data = null;
      if (key) {
        const savedValue = await dbMediaCaptures.getItem(key);
        status = !savedValue ? 'failed-restore' : 'restored';
        data = savedValue ?? null;
      }
      setSelf({ loading: false, status, data });
    }

    loadData();

    onSet(async ({ data }) => {
      try {
        await dbMediaCaptures.setItem(key, data);
      } catch (error) {
        setSelf({ loading: false, status: 'failed-store', data: null });
      }
    });
  },
];

export const templateMediaPersist = (key) => [
  ({ setSelf, onSet }) => {
    async function loadData() {
      const savedValue = await dbTemplateMedia.getItem(key);
      const status = !savedValue ? 'failed-restore' : 'restored';
      const data = savedValue ?? null;
      setSelf({ loading: false, status, data });
    }

    loadData();

    onSet(async ({ data }) => {
      try {
        await dbTemplateMedia.setItem(key, data);
      } catch (error) {
        setSelf({ loading: false, status: 'failed-store', data: null });
      }
    });
  },
];

export const localStorageEffect =
  (key, defaultValue) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    } else if (defaultValue !== undefined) {
      setSelf(defaultValue);
      localStorage.setItem(key, JSON.stringify(defaultValue));
    }

    onSet((newValue, _, isReset) =>
      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue))
    );
  };
