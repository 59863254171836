import { selector, useRecoilCallback } from 'recoil';

import { duplicateTemplate, getTemplateRaw, getTemplates } from '@api/services/template';

import { authReady } from '@store/atoms/AuthState';
import { renderFastPreviewState, renderPreviewLengthState, renderPreviewStartState } from '@store/atoms/RendersState';
import { bulkTemplateDeleteState, idState, nameState } from '@store/atoms/TemplateState';
import { derivedJsonState } from '@store/selectors/EditSelectors';

import { getTemplateForPreview } from '@utils/editor/preview';

const GETTING_STARTED_TEMPLATE_ID = 'hello-world-title-video';
const GETTING_STARTED_TEMPLATE_NAME = 'Shotstack - Getting Started';

export const templateState = selector({
  key: 'templateState',
  get: ({ get }) => {
    const name = get(nameState);
    const id = get(idState);
    let template = get(derivedJsonState());

    if (template && typeof template === 'string') {
      template = JSON.parse(template);
    }

    return { id, name, template };
  },
  set: ({ set }, templateData) => {
    const { name, id } = templateData;

    set(nameState, name);
    set(idState, id);
  },
});

export const allTemplatesState = selector({
  key: 'allTemplatesState',
  get: async ({ get }) => {
    const ready = get(authReady);
    if (!ready) {
      return [];
    }
    const { templates } = await getTemplates();
    return templates || [];
  },
});

export const allTemplatesListState = selector({
  key: 'allTemplatesListState',
  get: async ({ get }) => {
    const allTemplates = await get(allTemplatesState);

    return {
      templates: allTemplates || [],
      isFreePlan: false,
      isRestricted: false,
    };
  },
});

export const gettingStartedTemplateState = selector({
  key: 'gettingStartedTemplateState',
  get: async ({ get }) => {
    const ready = get(authReady);
    if (!ready) {
      return { data: undefined };
    }
    const templates = await get(allTemplatesState);

    const { id: existingTemplateId } =
      (templates || [])?.find(({ name }) => name === GETTING_STARTED_TEMPLATE_NAME) || {};

    if (existingTemplateId) {
      const data = await getTemplateRaw(existingTemplateId);
      return {
        isExisting: true,
        data,
      };
    }

    const data = await duplicateTemplate(GETTING_STARTED_TEMPLATE_ID, GETTING_STARTED_TEMPLATE_NAME);

    return {
      data,
    };
  },
});

export const useRenderTemplateState = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const templateData = snapshot.getLoadable(templateState).contents;
        const previewRangeStart = snapshot.getLoadable(renderPreviewStartState).contents;
        const previewRangeLength = snapshot.getLoadable(renderPreviewLengthState).contents;
        const isPreviewQuality = snapshot.getLoadable(renderFastPreviewState).contents;

        const range = { start: previewRangeStart, length: previewRangeLength };
        const template = templateData.template;

        return getTemplateForPreview({ template, range, isPreviewQuality });
      },
    []
  );
};

export const useToggleDeleteTemplateState = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (id) => {
        let updatedState = snapshot.getLoadable(bulkTemplateDeleteState).contents;
        if (updatedState.includes(id)) {
          updatedState = updatedState.filter((templateId) => templateId !== id);
        } else {
          updatedState = [...updatedState, id];
        }
        set(bulkTemplateDeleteState, updatedState);
        return updatedState;
      },
    []
  );
};

export const useRemoveTemplatesFromListState = () => {
  return useRecoilCallback(
    ({ snapshot, refresh, reset }) =>
      async () => {
        const toggleTemplateDelete = snapshot.getLoadable(bulkTemplateDeleteState).contents;
        const templateList = snapshot.getLoadable(allTemplatesState).contents;
        const updatedState = templateList.filter((templateId) => !toggleTemplateDelete.includes(templateId));

        refresh(allTemplatesState);
        reset(bulkTemplateDeleteState);

        return updatedState;
      },
    []
  );
};
