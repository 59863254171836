import { atom } from 'recoil';

export const snippetLangState = atom({
  key: 'snippetLangState',
  default: 'http',
});

export const snippetTypeState = atom({
  key: 'snippetTypeState',
  default: 'blob',
});

export const snippetPanelState = atom({
  key: 'snippetPanelState',
  default: false,
});
