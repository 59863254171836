import { useRef, useState } from 'react';
import { Badge, ButtonGroup, Card, Overlay, Popover } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useRecoilValue } from 'recoil';

import { IconMore } from '@assets/icons';
import LogoEmblem from '@assets/img/logo/emblem.svg?react';

import DeleteTemplateButton from '@feature/studio/template/TemplateDelete';
import DuplicateTemplateButton from '@feature/studio/template/TemplateDuplicate';

import ButtonElement from '@components/atoms/ButtonElement';
import CheckboxElement from '@components/atoms/CheckboxElement';

import { templateMediaState } from '@store/atoms/EditState';
import { useToggleDeleteTemplateState } from '@store/selectors/TemplateSelectors';

import useCaptureFrames from '@hooks/useCaptureFrames';

const TemplateAlbumCoverMedia = ({ src }) => {
  const { frames } = useCaptureFrames({
    src: src,
  });

  if (frames.loading || !frames?.data) return null;

  if (frames?.data?.data) {
    return (
      <div className="template-albumcover-media">
        <img src={frames?.data.data[0]} alt="template" />
      </div>
    );
  }
  if (frames?.data?.url) {
    return (
      <div className="template-albumcover-media">
        <img src={frames?.data.url} alt="template" />
      </div>
    );
  }
  return null;
};

const TemplateAlbumCover = ({ templateMedia }) => {
  return (
    <div className="template-albumcover">
      {templateMedia.data && templateMedia.data.map((src, index) => <TemplateAlbumCoverMedia key={index} src={src} />)}
      <div className="template-albumcover-bg">
        <LogoEmblem />
      </div>
    </div>
  );
};

function TemplateListItem({ name, id }) {
  const documentRef = useRef(document);
  const templateLink = `/studio/editor/${id}`;
  const [showOverlay, setShowOverlay] = useState(false);
  const [target, setTarget] = useState(null);
  const templateMedia = useRecoilValue(templateMediaState(id));

  const toggleDeleteTemplate = useToggleDeleteTemplateState();

  const handleMoreClick = (event) => {
    setShowOverlay(!showOverlay);
    setTarget(event.target);
  };

  return (
    <Card className="template-item">
      <Badge className="bg-subtle">ID:&nbsp;{id}</Badge>
      <LinkContainer to={templateLink} style={{ cursor: 'pointer' }}>
        <Card.Header>
          <TemplateAlbumCover templateMedia={templateMedia} />
        </Card.Header>
      </LinkContainer>
      <Card.Body className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div className="me-2">
              <CheckboxElement checked={false} handleEvent={() => toggleDeleteTemplate(id)} />
            </div>
            <div>
              <span className="fw-bold">{name}</span>
            </div>
          </div>
          <div>
            <ButtonElement
              title="More"
              handleEvent={handleMoreClick}
              variant="light"
              className="template-more unstyled"
            >
              <IconMore size="18" />
            </ButtonElement>
            <Overlay
              show={showOverlay}
              target={target}
              placement="left"
              container={documentRef.current?.body}
              containerPadding={20}
              rootClose
              onHide={() => setShowOverlay(false)}
            >
              <Popover id="popover-contained">
                <Popover.Body>
                  <ButtonGroup vertical className="w-100">
                    <DuplicateTemplateButton templateId={id} templateName={name} />
                    <DeleteTemplateButton templateId={id} title="Delete" templateName={name} className="w-100" />
                  </ButtonGroup>
                </Popover.Body>
              </Popover>
            </Overlay>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default TemplateListItem;
