import { useState } from 'react';
import { useRecoilRefresher_UNSTABLE, useResetRecoilState, useSetRecoilState } from 'recoil';

import { duplicateTemplate } from '@api/services/template';

import { errorState } from '@store/atoms/UIState';
import { allTemplatesState } from '@store/selectors/TemplateSelectors';

const useDuplicateTemplate = ({ onSuccess } = {}) => {
  const [loading, setLoading] = useState(false);
  const setErrorMessage = useSetRecoilState(errorState);
  const resetErrorMessage = useResetRecoilState(errorState);
  const refreshAllTemplateState = useRecoilRefresher_UNSTABLE(allTemplatesState);

  const handleDuplicate = async (templateId) => {
    setLoading(true);
    try {
      const data = await duplicateTemplate(templateId);
      refreshAllTemplateState();
      onSuccess(data);
    } catch (error) {
      setErrorMessage({ message: error.message });
      setTimeout(() => {
        resetErrorMessage();
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  return { loading, handleDuplicate };
};

export default useDuplicateTemplate;
