import { Container } from '@inlet/react-pixi';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import CanvasFrameHandle from '@feature/studio/canvas/CanvasFrameHandle';
import CanvasFrameOutline from '@feature/studio/canvas/CanvasFrameOutline';
import CanvasFrameRotationHandle from '@feature/studio/canvas/CanvasFrameRotationHandle';

import { sdkOptionsSelector } from '@store/atoms/SdkState';

function CanvasEditFrame({
  bounds,
  width,
  height,
  handlePositionGrab,
  handlePositionDrag,
  handlePositionDrop,
  handleResizeGrab = undefined,
  handleResizeDrag = undefined,
  handleResizeDrop = undefined,
  handleScaleGrab = undefined,
  handleScaleDrag = undefined,
  handleScaleDrop = undefined,
  handleRotateGrab,
  handleRotateDrag,
  handleRotateDrop,
  inView,
  selected,
  zIndex,
  angle,
}) {
  const { topLeft, bottomRight, topCenter } = bounds;
  const [visible, setVisible] = useState(false);
  const [isAdjusting, setIsAdjusting] = useState(false);
  const { isSdkEnabled, isInteractive } = useRecoilValue(sdkOptionsSelector);

  const centerX = (topLeft.x + bottomRight.x) / 2;
  const centerY = (topLeft.y + bottomRight.y) / 2;

  if (isSdkEnabled && !isInteractive) {
    return null;
  }

  return (
    <Container>
      <CanvasFrameOutline
        bounds={bounds}
        width={width}
        handleGrab={handlePositionGrab}
        handleDrag={handlePositionDrag}
        handleDrop={handlePositionDrop}
        height={height}
        inView={inView}
        visible={visible || selected}
        setVisible={setVisible}
        isAdjusting={isAdjusting}
        zIndex={zIndex}
        rotationAngle={angle}
      />
      {handleScaleGrab && (
        <>
          <CanvasFrameRotationHandle
            width={width}
            coordinates={topCenter}
            centerX={centerX}
            centerY={centerY}
            rotationAngle={angle}
            handleDrag={handleRotateDrag}
            handleDrop={handleRotateDrop}
            handleGrab={handleRotateGrab}
            inView={inView}
            isAdjusting={isAdjusting}
            position="topCenter"
            setIsAdjusting={setIsAdjusting}
            setVisible={setVisible}
            visible={visible}
            zIndex={zIndex}
          />
          {['topLeft', 'topRight', 'bottomRight', 'bottomLeft'].map((position) => (
            <CanvasFrameHandle
              key={position}
              width={width}
              coordinates={bounds[position]}
              centerX={centerX}
              centerY={centerY}
              rotationAngle={angle}
              handleDrag={handleScaleDrag}
              handleDrop={handleScaleDrop}
              handleGrab={handleScaleGrab}
              inView={inView}
              isAdjusting={isAdjusting}
              position={position}
              setIsAdjusting={setIsAdjusting}
              setVisible={setVisible}
              visible={visible}
              zIndex={zIndex}
            />
          ))}
        </>
      )}
      {handleResizeGrab && (
        <>
          <CanvasFrameRotationHandle
            width={width}
            coordinates={topCenter}
            centerX={centerX}
            centerY={centerY}
            rotationAngle={angle}
            handleDrag={handleRotateDrag}
            handleDrop={handleRotateDrop}
            handleGrab={handleRotateGrab}
            inView={inView}
            isAdjusting={isAdjusting}
            position="topCenter"
            setIsAdjusting={setIsAdjusting}
            setVisible={setVisible}
            visible={visible}
            zIndex={zIndex}
          />
          {['top', 'right', 'bottom', 'left'].map((position) => (
            <CanvasFrameHandle
              key={position}
              width={width}
              coordinates={bounds[position]}
              centerX={centerX}
              centerY={centerY}
              rotationAngle={angle}
              handleDrag={handleResizeDrag}
              handleDrop={handleResizeDrop}
              handleGrab={handleResizeGrab}
              inView={inView}
              isAdjusting={isAdjusting}
              position={position}
              setIsAdjusting={setIsAdjusting}
              setVisible={setVisible}
              visible={visible}
              zIndex={zIndex}
            />
          ))}
        </>
      )}
    </Container>
  );
}

export default CanvasEditFrame;
