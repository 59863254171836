import { memo } from 'react';

import { IconText } from '@assets/icons';

import TimelineClipInfo from '@feature/studio/timeline/TimelineClipInfo';

function TimelineClipText({ id, clip }) {
  const text = [clip['asset:text'], clip['asset:html'], clip['asset:title']].filter(Boolean).join(' ');
  return (
    <div className="clip__inner">
      <div className="clip__header">
        <span className="clip__icon">
          <IconText size={16} />
        </span>
        <TimelineClipInfo id={id} />

        {text ? (
          <div className="clip__content-raw" dangerouslySetInnerHTML={{ __html: text }} />
        ) : (
          <span className="clip__type">{clip['asset:type']}</span>
        )}
      </div>
    </div>
  );
}

export default memo(TimelineClipText);
