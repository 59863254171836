import { useCallback, useState } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import ButtonElement from '@components/atoms/ButtonElement';

import { useSaveWorkflow } from '@store/Workflows';
import { errorState } from '@store/atoms/UIState';

import { getErrorMessage } from '@utils/getErrorMessage';

function WorkflowSave() {
  const [saving, setSaving] = useState(false);
  const saveWorkflow = useSaveWorkflow();
  const setErrorMessage = useSetRecoilState(errorState);
  const resetErrorMessage = useResetRecoilState(errorState);

  const handleSave = useCallback(async () => {
    setSaving(true);
    resetErrorMessage();
    try {
      await saveWorkflow();
    } catch (error) {
      setErrorMessage({ message: getErrorMessage(error) });
    } finally {
      setSaving(false);
    }
  }, [resetErrorMessage, saveWorkflow, setErrorMessage]);

  return (
    <ButtonElement handleEvent={handleSave} variant="light" loading={saving} style={{ minWidth: 75 }}>
      Save
    </ButtonElement>
  );
}

export default WorkflowSave;
