import { useRecoilValue } from 'recoil';

import '@css/TimelineSdkScrub.css';

import { useTimelinePlaybackContext } from '@context/TimelinePlaybackProvider';

import { sdkOptionsSelector } from '@store/atoms/SdkState';

import { TIMELINE_TRACK_OFFSET } from '@constants/Timeline';

function TimelineSdkScrub() {
  const { playhead, timelineDuration, isPlayable, isPlaying, handlePause, setPlayhead } = useTimelinePlaybackContext();
  const { isSdkEnabled } = useRecoilValue(sdkOptionsSelector);

  const handlePlayheadChange = (event) => {
    const { value } = event.target;
    if (isPlaying) handlePause();
    setPlayhead(!value ? 0 : parseFloat(value));
  };

  if (!isPlayable) {
    return null;
  }

  const trackOffset = isSdkEnabled ? 0 : TIMELINE_TRACK_OFFSET;

  return (
    <div className="sdk-scrub" style={{ transform: `translate(${trackOffset}px, 0px)` }}>
      <input
        step="0.0001"
        min="0"
        max={timelineDuration}
        onChange={handlePlayheadChange}
        type="range"
        value={playhead}
      />
    </div>
  );
}

export default TimelineSdkScrub;
