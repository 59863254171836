import createAuth0Client from '@auth0/auth0-spa-js';
import * as Sentry from '@sentry/react';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useHotjar from 'react-use-hotjar';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import { authToken, authenticatedUser, userDetailsSelector } from '@store/atoms/AuthState';

const identifySentry = (id) => {
  Sentry.setUser({ id });
};

export const Auth0Context = createContext();
export const useAuth0 = () => useContext(Auth0Context);

export function Auth0Provider({ children, ...initOptions }) {
  const { identifyHotjar } = useHotjar();
  const [isIdentified, setIsIdentified] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [auth0User, setAuth0User] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const setToken = useSetRecoilState(authToken);
  const setAuthenticatedUser = useSetRecoilState(authenticatedUser);
  const { contents: userDetails } = useRecoilValueLoadable(userDetailsSelector);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (searchParams.has('code')) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          navigate(appState?.targetUrl ?? '/', {
            replace: true,
          });
        } catch (error) {
          console.error(error);
        }
      }

      const authenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(authenticated);

      if (authenticated) {
        const auth0User = await auth0FromHook.getUser();
        const auth0Token = await auth0FromHook.getTokenSilently();
        setAuth0User(auth0User);
        setToken(auth0Token);
      }

      setLoading(false);
    };

    initAuth0();
  }, []);

  useEffect(() => {
    if (auth0User && userDetails?.auth0_id) {
      setAuthenticatedUser({ ...auth0User, ...userDetails });
      if (isIdentified) return;

      const { auth0_id: auth0Id, ...restUserData } = userDetails;
      identifySentry(auth0Id);
      identifyHotjar(auth0Id, restUserData);
      setIsIdentified(true);
    }
  }, [auth0User, userDetails]);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client?.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const auth0User = await auth0Client?.getUser();
    setAuth0User(auth0User);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client?.handleRedirectCallback();
    const auth0User = await auth0Client?.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setAuth0User(auth0User);
  };

  return (
    <Auth0Context.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isAuthenticated,
        user: auth0User,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client?.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client?.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client?.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client?.getTokenWithPopup(...p),
        logout: (...p) => auth0Client?.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
}
