import cn from 'classnames';
import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import { IconAlias, IconEnd, IconStart } from '@assets/icons';

import { timelineClipState, useUpdateClipTimingProperty } from '@store/selectors/EditSelectors';

import formatTimecode from '@utils/formatTimeCode';

function TimelineClipInfo({ id }) {
  const clip = useRecoilValue(timelineClipState(id));
  const updateClipTimingProperty = useUpdateClipTimingProperty(id);
  const updateTiming = (prop) => () => updateClipTimingProperty(prop);

  return (
    <div className="clip__info">
      <div
        className={cn('clip__attr', `clip__timing clip__timing--${clip?.meta?.start}`)}
        onDoubleClick={updateTiming('start')}
      >
        <span>{clip?.meta?.start === 'number' ? '123' : clip?.meta?.start}</span>
      </div>
      <div className="clip__time">
        <IconStart size={12} />
        <span>{formatTimecode(clip.start)}</span>
      </div>

      <div
        className={cn('clip__attr', `clip__timing clip__timing--${clip?.meta?.length}`)}
        onDoubleClick={updateTiming('length')}
      >
        <span>{clip?.meta?.length === 'number' ? '123' : clip?.meta?.length}</span>
      </div>
      <div className="clip__time">
        <IconEnd size={12} />
        <span>{formatTimecode(clip.start + clip.length)}</span>
      </div>

      {clip.alias && (
        <div className="clip__attr clip__alias">
          <span title="Clip alias">
            <IconAlias size={10} />
            {clip.alias}
          </span>
        </div>
      )}
    </div>
  );
}

export default memo(TimelineClipInfo);
