import config from '@config';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IconClipboard } from '@assets/icons';

import RenderPreviewAudio from '@feature/studio/render/RenderPreviewAudio';
import RenderPreviewImage from '@feature/studio/render/RenderPreviewImage';
import RenderPreviewStatus from '@feature/studio/render/RenderPreviewStatus';
import RenderPreviewVideo from '@feature/studio/render/RenderPreviewVideo';

import LinkElement from '@components/atoms/LinkElement';

import { authToken } from '@store/atoms/AuthState';
import { rendersState } from '@store/atoms/RendersState';

import { getRenderErrorMessage } from '@utils/errors/render';
import getFileTypeFromUrl from '@utils/getFileTypeFromUrl';

const RENDER_ENDPOINT = 'render';
const POLL_DELAY = 2500;
const STATUS_DONE = 'done';
const STATUS_QUEUED = 'queued';
const STATUS_FAILED = 'failed';

const RenderPreviewComponentMap = {
  audio: RenderPreviewAudio,
  image: RenderPreviewImage,
  video: RenderPreviewVideo,
};

// eslint-disable-next-line no-promise-executor-return
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

let poll;
const createPoll = (setStatus) => {
  const pollRef = axios.create();

  pollRef.interceptors.response.use(async (response) => {
    const { status } = response.data.response;
    setStatus(status);

    if (status === STATUS_DONE || status === STATUS_FAILED) {
      return response;
    }

    await delay(POLL_DELAY);

    return poll.request(response.config);
  });

  return {
    pollRef,
  };
};

const pollRender = (token, stage, renderId) =>
  poll.get(`${config.edit.url}${RENDER_ENDPOINT}/${renderId}?data=false`, {
    headers: {
      Authorization: `Bearer ${token}`,
      stage,
    },
  });

function RenderPreview({ renderData, handleErrors }) {
  const { id: renderId, stage } = renderData;
  const token = useRecoilValue(authToken);
  const [renders, setRenders] = useRecoilState(rendersState);
  const [status, setStatus] = useState('submitted');
  const [render, setRender] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [errorSent, setErrorSent] = useState(false);

  const setRenderComplete = ({ id, url }) => {
    setRender({
      id,
      url,
    });
    setStatus(STATUS_DONE);
  };

  useEffect(() => {
    if (!errorSent && errorMessage) {
      handleErrors(`<b>${errorMessage}</b>`);
      setErrorSent(true);
    }
  }, [handleErrors, errorMessage, errorSent]);

  useEffect(() => {
    async function fetchRender() {
      try {
        if (!token || !token.length) {
          throw new Error('No token');
        }
        const renderIndex = renders.findIndex((renderItem) => renderItem.id === renderId);
        const { url: renderUrl } = renders[renderIndex] || {};

        if (renderUrl) {
          setRenderComplete({ id: renderId, url: renderUrl });
          return;
        }

        if (!poll) {
          const { pollRef } = createPoll(setStatus);
          poll = pollRef;
        }

        setStatus(STATUS_QUEUED);
        await delay(POLL_DELAY);

        const response = await pollRender(token, stage, renderId);
        const { status: renderStatus, error, id, url } = response?.data?.response || {};

        if (renderStatus === STATUS_FAILED) {
          setErrorMessage(error);
        }

        setRender({
          id,
          url,
        });

        setRenders((currentState) => {
          const updatedState = currentState.map((renderItem) =>
            renderItem.id === id
              ? {
                  ...renderItem,
                  url,
                }
              : renderItem
          );

          return updatedState;
        });
      } catch (error) {
        setErrorMessage(getRenderErrorMessage(error));
        setStatus(STATUS_FAILED);
      }
    }

    fetchRender();
  }, [token, renderId, stage, setRenders, renders]);

  if (status === STATUS_DONE && render) {
    const fileTypeFromUrl = getFileTypeFromUrl(render.url);
    const Component = RenderPreviewComponentMap[fileTypeFromUrl];
    if (Component) {
      return (
        <div className="render-preview">
          <Component render={render} />

          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <Badge className="bg-subtle">Render ID: {render.id}</Badge>
              <CopyToClipboard text={render.id}>
                <Button className="btn-copy ms-1" title="Copy ID clipboard" type="button" variant="light">
                  <IconClipboard size="14" />
                </Button>
              </CopyToClipboard>
            </div>

            <LinkElement type="button" to={render.url} variant="light" external size="sm">
              Open
            </LinkElement>
          </div>
        </div>
      );
    }
    return null;
  }

  return status && status !== STATUS_DONE && <RenderPreviewStatus status={status} />;
}

export default RenderPreview;
