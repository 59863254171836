import { useRecoilState } from 'recoil';

import InputNumber from '@components/controls/InputNumber';

import { sizeAtomFamily } from '@store/studio/Output';

import { parseNumberOrEmptyString } from '@utils/numbers';

function HeightInput() {
  const [height, setHeight] = useRecoilState(sizeAtomFamily('height'));

  const handleChange = (event) => {
    const { value } = event.target;
    setHeight(parseNumberOrEmptyString(value, false));
  };

  return (
    <InputNumber
      id="output-height"
      title="Height"
      value={height}
      min={0}
      prefix="H"
      step={1}
      handleChange={handleChange}
    />
  );
}

export default HeightInput;
