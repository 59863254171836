import { useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import '@css/Dashboard.css';

import CreateTemplate from '@feature/studio/template/TemplateCreate';

import { useAnalytics } from '@components/analytics/Analytics';
import LinkElement from '@components/atoms/LinkElement';
import Layout from '@components/layout/Layout';

import { authenticatedUser } from '@store/atoms/AuthState';

function getTourId(user) {
  let tourId;
  switch (user?.metadata?.build_type) {
    case 'With Code':
      tourId = '482002';
      break;
    case 'Without Code':
      tourId = '493888';
      break;
    default:
      tourId = '482002';
  }
  return tourId;
}

function Dashboard() {
  const { trackEvent } = useAnalytics();
  const user = useRecoilValue(authenticatedUser);
  const tourId = getTourId(user);
  const history = useNavigate();

  useEffect(() => {
    trackEvent('View Dashboard');
    history('/studio/overview');
  }, [trackEvent, history]);

  return (
    <Layout>
      <Row className="max-w-screen-lg mx-auto mt-5">
        <Col xl={6}>
          <Card className="h-100">
            <Card.Link
              href={`/studio/editor/real-estate-slideshow-sd-overlays-merge?t=4.5&product_tour_id=${tourId}`}
              data-title="Learn how to design your dynamic templates"
            >
              <Card.Img variant="top" src="/img/dash-editor.webp" />
            </Card.Link>
            <Card.Body>
              <div className="h-100 d-flex flex-column justify-content-between">
                <div className="mb-4">
                  <Card.Title>
                    <Card.Link
                      href={`/studio/editor/real-estate-slideshow-sd-overlays-merge?t=4.5&product_tour_id=${tourId}`}
                      data-title="Learn how to design your dynamic templates"
                    >
                      Automate via template
                    </Card.Link>
                  </Card.Title>
                  <Card.Text>Automate your videos using a dynamic template.</Card.Text>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <Button
                    href={`/studio/editor/real-estate-slideshow-sd-overlays-merge?t=4.5&product_tour_id=${tourId}`}
                  >
                    Learn More
                  </Button>
                  <CreateTemplate btnVariant="light">New Template</CreateTemplate>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={6}>
          <Card className="h-100">
            <Card.Link href="/getting-started" data-title="Render videos with the API">
              <Card.Img variant="top" src="/img/dash-postman.webp" />
            </Card.Link>
            <Card.Body>
              <div className="h-100 d-flex flex-column justify-content-between">
                <div className="mb-4">
                  <Card.Title>
                    <Card.Link href="/getting-started" data-title="Render videos with the API">
                      Automate via API
                    </Card.Link>
                  </Card.Title>
                  <Card.Text>Use the API to create data-driven video.</Card.Text>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <LinkElement type="button" to="/getting-started">
                    Interactive Guide
                  </LinkElement>
                  <LinkElement type="button" to="/keys" variant="light">
                    API Keys
                  </LinkElement>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default Dashboard;
