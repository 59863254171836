import { Col, Row } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';

import AssetSourceInput from '@components/controls/AssetSourceInput';
import InputNumber from '@components/controls/InputNumber';
import InputSelect from '@components/controls/InputSelect';

import { soundtrackState } from '@store/atoms/EditState';
import { idState } from '@store/atoms/TemplateState';

import VolumeEffects from '@constants/VolumeEffects';

function SettingSoundtrack() {
  const [soundtrack, setSoundtrack] = useRecoilState(soundtrackState);
  const templateId = useRecoilValue(idState);

  const tracking = {
    properties: {
      Id: templateId,
    },
  };

  const handleEffectChange = (event) => {
    const { value } = event.target;
    const { effect, ...newSoundtrack } = soundtrack;

    if (value.length > 0) {
      newSoundtrack.effect = value;
    }

    setSoundtrack(newSoundtrack);
  };

  const handleVolumeChange = (event) => {
    const { value } = event.target;
    const { volume, ...newSoundtrack } = soundtrack;

    if (value.length > 0 && value >= 0 && value <= 1) {
      newSoundtrack.volume = parseFloat(value);
    }

    setSoundtrack(newSoundtrack);
  };

  if (!soundtrack?.src) {
    return null;
  }

  return (
    <div>
      <h6>Soundtrack</h6>

      <AssetSourceInput asset={soundtrack} type="audio" accepts="audio/*" />
      <Row>
        <Col md={6}>
          <InputNumber
            handleChange={handleVolumeChange}
            label="Volume"
            max={1}
            min={0}
            step={0.01}
            tracking={{
              ...tracking,
              name: 'Change Soundtrack Volume',
            }}
            value={soundtrack?.volume >= 0 ? soundtrack?.volume : ''}
          />
        </Col>
        <Col md={6}>
          <InputSelect
            handleChange={handleEffectChange}
            label="Volume Effect"
            selected={soundtrack?.effect || ''}
            tracking={{
              ...tracking,
              name: 'Select Soundtrack Volume Effect',
            }}
            values={VolumeEffects}
          />
        </Col>
      </Row>
    </div>
  );
}

export default SettingSoundtrack;
