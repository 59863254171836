import { selector } from 'recoil';

import onboardingService from '@api/services/onboarding';

import { authReady, authenticatedUser } from './AuthState';

export const subscriptionsSelector = selector({
  key: 'subscriptions/all',
  get: async () => {
    const response = await onboardingService.getSubscriptions();
    return response;
  },
});

export const subscriberSelector = selector({
  key: 'subscriptions/subscriber',
  get: async ({ get }) => {
    await get(authReady);
    const authUser = get(authenticatedUser);
    const response = await onboardingService.getSubscriber(authUser?.stripe_id ?? null);
    return response;
  },
});
