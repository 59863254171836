const timingFields = {
  start: 'number',
  length: 'number',
};

const visualEffectsFields = {
  opacity: 'number',
};

const dimensionFields = {
  'asset:width': 'number',
  'asset:height': 'number',
};

const offsetFields = {
  'offset:x': 'number',
  'offset:y': 'number',
};

const sizeFields = {
  fit: 'text',
  scale: 'number',
};

const transformFields = {
  'transform:rotate:angle': 'number',
};

const assetFields = {
  'asset:src': 'string',
};

const textAssetFields = {
  'asset:alignment:horizontal': 'string',
  'asset:alignment:vertical': 'string',
  'asset:font:family': 'string',
  'asset:font:color': 'string',
  'asset:font:size': 'number',
  'asset:font:lineHeight': 'number',
  'asset:font:opacity': 'number',
  'asset:background:color': 'string',
  'asset:background:opacity': 'number',
  'asset:background:padding': 'number',
  'asset:background:borderRadius': 'number',
};

export default {
  video: {
    'asset:trim': 'number',
    'asset:volume': 'number',
    ...timingFields,
    ...visualEffectsFields,
    ...assetFields,
    ...sizeFields,
    ...dimensionFields,
    ...offsetFields,
    ...transformFields,
  },
  overlay: {
    'asset:trim': 'number',
    'asset:volume': 'number',
    ...timingFields,
    ...visualEffectsFields,
    ...assetFields,
    ...sizeFields,
    ...dimensionFields,
    ...offsetFields,
    ...transformFields,
  },
  mask: {
    ...timingFields,
  },
  image: {
    ...timingFields,
    ...visualEffectsFields,
    ...assetFields,
    ...sizeFields,
    ...dimensionFields,
    ...offsetFields,
    ...transformFields,
  },
  text: {
    ...textAssetFields,
    ...timingFields,
    ...visualEffectsFields,
    ...dimensionFields,
    ...offsetFields,
    ...transformFields,
  },
  caption: {
    'asset:trim': 'number',
    ...assetFields,
    ...textAssetFields,
    ...timingFields,
    ...visualEffectsFields,
    ...dimensionFields,
    ...offsetFields,
    ...transformFields,
  },
  'text-to-speech': {
    'asset:text': 'string',
    'asset:voice': 'string',
    ...textAssetFields,
    ...timingFields,
  },
  html: {
    'asset:fontSize': 'string',
    'asset:fontFamily': 'string',
    'asset:textAlign': 'string',
    'asset:color': 'string',
    'asset:background': 'string',
    'asset:position': 'string',
    ...timingFields,
    ...visualEffectsFields,
    ...offsetFields,
    ...dimensionFields,
    ...transformFields,
  },
  audio: {
    'asset:trim': 'number',
    'asset:volume': 'number',
    'asset:effect': 'string',
    ...timingFields,
    ...assetFields,
  },
  'text-to-image': {
    ...timingFields,
    ...visualEffectsFields,
    ...assetFields,
    ...sizeFields,
    ...dimensionFields,
    ...offsetFields,
    ...transformFields,
  },
  title: {
    ...timingFields,
    ...sizeFields,
    ...offsetFields,
    ...transformFields,
    ...dimensionFields,
  },
  mask: {
    ...timingFields,
    ...assetFields,
  },
  soundtrack: {
    src: 'string',
  },
  callback: {
    src: 'string',
  },
};
