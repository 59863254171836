import _ from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useIntercom } from 'react-use-intercom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IconGear, IconKey, IconQuestionCircle } from '@assets/icons';

import GoogleCloudStorageForm from '@feature/integrations/providers/googleCloudStorage/GoogleCloudStorageForm';
import GoogleDriveForm from '@feature/integrations/providers/googledrive/GoogleDriveForm';
import MuxForm from '@feature/integrations/providers/mux/MuxForm';
import S3Form from '@feature/integrations/providers/s3/S3Form';
import TiktokForm from '@feature/integrations/providers/tiktok/TiktokForm';
import VimeoForm from '@feature/integrations/providers/vimeo/VimeoForm';

import { useAnalytics } from '@components/analytics/Analytics';
import ButtonElement from '@components/atoms/ButtonElement';
import GoogleCloudStorage from '@components/destinations/GoogleCloudStorage';
import GoogleDrive from '@components/destinations/GoogleDrive';
import Mux from '@components/destinations/Mux';
import S3 from '@components/destinations/S3';
import Shotstack from '@components/destinations/Shotstack';
import TikTok from '@components/destinations/TikTok';
import Vimeo from '@components/destinations/Vimeo';

import { stageAtom } from '@store/atoms/StageState';
import { useSaveTemplate } from '@store/atoms/TemplateState';
import { destinationAtomFamily } from '@store/studio/Output';

import { providers } from '@constants/IntegrationProviders';

const DestinationComponentMap = {
  mux: { settings: Mux, form: MuxForm },
  s3: { settings: S3, form: S3Form },
  shotstack: { settings: Shotstack },
  'google-cloud-storage': { settings: GoogleCloudStorage, form: GoogleCloudStorageForm },
  vimeo: { settings: Vimeo, form: VimeoForm },
  'google-drive': { settings: GoogleDrive, form: GoogleDriveForm },
  tiktok: { settings: TikTok, form: TiktokForm },
};

function SettingDestination({ id, integrations }) {
  const stage = useRecoilValue(stageAtom);
  const [destination, setDestination] = useRecoilState(destinationAtomFamily(id));
  const [isSaving, setIsSaving] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showCredentialsModal, setShowCredentialsModal] = useState(false);
  const [hasCredentials, setHasCredentials] = useState(false);
  const saveTemplate = useSaveTemplate();
  const { trackEvent } = useAnalytics();
  const { showArticle } = useIntercom();
  const [isFormValid, setIsFormValid] = useState(false);

  const handleValidationChange = useCallback((isValid) => {
    setIsFormValid(isValid);
  }, []);

  useEffect(() => {
    setHasCredentials(_.get(integrations, [id, stage]) || false);
  }, [integrations, id, stage]);

  const details = providers.destinations.find(({ slug }) => slug === id);
  const { settings: DestinationComponent, form: FormComponent } = DestinationComponentMap[id] || {};

  const handleDestinationEnabledToggle = () => {
    const isEnabledState = !destination?.meta?.enabled;
    setShowSettings(isEnabledState);
    setDestination((prevState) => ({
      ...prevState,
      meta: { ...prevState?.meta, enabled: isEnabledState },
    }));
    saveTemplate();

    // Track event when a destination is connected
    if (isEnabledState) {
      trackEvent('Destination Connected', {
        destination_id: id,
        destination_name: details.title,
      });
    }
  };

  const handleOpenCredentialsModal = () => {
    setShowCredentialsModal(true);
  };

  const handleCloseCredentialsModal = () => {
    setShowCredentialsModal(false);
  };

  const handleOpenHelpArticle = (id) => {
    switch (id) {
      case 'tiktok':
        showArticle(9862539);
        break;
      case 's3':
        showArticle(9862947);
        break;
      default:
        break;
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    await saveTemplate();
    setIsSaving(false);
  };

  if (!DestinationComponent || !details) {
    return null;
  }

  return (
    <div className="mb-3">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-4">
          <img
            alt={`${details.title} destination logo`}
            src={`/img/provider/${details.image}`}
            style={{ maxHeight: '25px', marginRight: '10px' }}
          />
          <div className="text-lg font-bold">{details.title}</div>
        </div>
        <div className="d-flex gap-2">
          {id !== 'shotstack' && (
            <Button variant={destination?.meta?.enabled ? 'light' : 'primary'} onClick={handleDestinationEnabledToggle}>
              {destination?.meta?.enabled ? 'Disconnect' : 'Connect'}
            </Button>
          )}
          <Button variant="light" onClick={() => setShowSettings(!showSettings)} disabled={!destination?.meta?.enabled}>
            <IconGear />
          </Button>
          {['tiktok', 's3'].includes(id) && (
            <Button variant="light" onClick={() => handleOpenHelpArticle(id)}>
              <IconQuestionCircle />
            </Button>
          )}
        </div>
      </div>

      {showSettings && (
        <>
          <hr />
          <div className="px-4 pt-2">
            {details.apiKeys && FormComponent && (
              <Alert
                variant={hasCredentials ? 'light' : 'warning'}
                className="d-flex justify-content-between align-items-center"
              >
                <span>
                  {hasCredentials ? 'API credentials are set.' : 'API credentials are required for this destination.'}
                </span>
                <Button variant={hasCredentials ? 'light' : 'warning'} onClick={handleOpenCredentialsModal}>
                  <IconKey /> {hasCredentials ? 'Edit Credentials' : 'Add Credentials'}
                </Button>
              </Alert>
            )}
            <DestinationComponent
              destination={destination}
              setDestination={setDestination}
              id={id}
              onValidationChange={handleValidationChange}
            />

            <div className="d-flex justify-content-end mt-4">
              <ButtonElement handleEvent={handleSave} disabled={isSaving || !isFormValid} loading={isSaving}>
                Save Changes
              </ButtonElement>
            </div>
          </div>
        </>
      )}

      <Modal show={showCredentialsModal} onHide={handleCloseCredentialsModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{`${details.title} Credentials`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{FormComponent && <FormComponent stage={stage} onFormFilled={() => {}} />}</Modal.Body>
      </Modal>
    </div>
  );
}

export default SettingDestination;
