import { atom, selectorFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const dismissibleAtom = atom({
  key: 'dismissible/atom',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const dismissibleSelector = selectorFamily({
  key: 'dismissible/selector',
  get:
    (id) =>
    ({ get }) =>
      get(dismissibleAtom).includes(id),
  set:
    (id) =>
    ({ set }) => {
      set(dismissibleAtom, (prevItems) =>
        prevItems.includes(id) ? prevItems.filter((item) => item !== id) : [...prevItems, id]
      );
    },
});
