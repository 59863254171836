import { useRecoilValue } from 'recoil';

import { IconImage } from '@assets/icons';

import Asset from '@components/asset/Asset';

import { clipState } from '@store/selectors/EditSelectors';

function AssetImage({ id }) {
  const { ['asset:src']: src } = useRecoilValue(clipState(id));

  return (
    <Asset icon={<IconImage />}>{src ? <img src={`${src}?x-request=html`} key={src} /> : <div key={src} />}</Asset>
  );
}

export default AssetImage;
