import { useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useRecoilValueLoadable, useResetRecoilState } from 'recoil';

import CreateTemplateButton from '@feature/studio/template/TemplateCreate';
import TemplateListItem from '@feature/studio/template/list/TemplateListItem';

import Skeleton from '@components/skeletons/Skeleton';

import { bulkTemplateDeleteState } from '@store/atoms/TemplateState';
import { allTemplatesListState } from '@store/selectors/TemplateSelectors';

function TemplateListItems() {
  const { state, contents } = useRecoilValueLoadable(allTemplatesListState);
  const resetBulkTemplateDelete = useResetRecoilState(bulkTemplateDeleteState);

  useEffect(() => () => resetBulkTemplateDelete(), []);

  if (state === 'loading') {
    return <Skeleton length={32} height="200px" className="template-list" />;
  }

  if (state === 'hasError' || contents.templates.length === 0) {
    return (
      <Card>
        <Card.Body>
          <Row>
            <Col className="align-self-center text-center" style={{ padding: '5rem 0' }}>
              <h4 className="mb-4">Get started with your first template!</h4>
              <CreateTemplateButton tracking={{ Position: 'Inside Template List' }} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <div className="template-list">
        {contents.templates.map(({ id, name }, index) => (
          <TemplateListItem key={id} name={name} id={id} isFreePlan={contents.isFreePlan} index={index} />
        ))}
      </div>
      {contents.isFreePlan && (
        <Card>
          <Card.Body>
            <Row>
              <Col className="align-self-center text-center">
                <p>
                  {contents.isRestricted ? (
                    <strong>Upgrade to access all of your templates.</strong>
                  ) : (
                    <>
                      <p>
                        <strong>Your free plan includes one template. Upgrade for unlimited templates.</strong>
                      </p>
                      <Button href="/subscription">Upgrade</Button>
                    </>
                  )}
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default TemplateListItems;
