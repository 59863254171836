import { useRecoilState, useRecoilValue } from 'recoil';

import InputCheckbox from '@components/controls/InputCheckbox';

import { cacheState } from '@store/atoms/EditState';
import { idState } from '@store/atoms/TemplateState';

function SettingCache() {
  const [cache, setCache] = useRecoilState(cacheState);

  const templateId = useRecoilValue(idState);

  const tracking = {
    name: 'Check Caching',
    properties: {
      Id: templateId,
    },
  };

  const handleCacheChange = (event) => {
    setCache(event.target.checked);
  };

  return (
    <div>
      <h6>Caching</h6>

      <InputCheckbox label="Cache Assets" checked={cache} handleChange={handleCacheChange} tracking={tracking} />
    </div>
  );
}

export default SettingCache;
