import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useAuth0 } from '@context/Auth0ReactSPA';

import { useAnalytics } from '@components/analytics/Analytics';

import { authenticatedUser } from './store/atoms/AuthState';

function PrivateRoute({ children }) {
  const authUser = useRecoilValue(authenticatedUser);
  const { isAuthenticated, loading, loginWithRedirect } = useAuth0();
  const { identifyUser, trackEvent } = useAnalytics();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading || isAuthenticated) return;

    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: `${location.pathname}${location.search}` },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    if (location.pathname.includes('onboarding')) return;
    if (!authUser || authUser?.onboarded) return;

    if (authUser?.auth0_id && authUser?.onboarded === false) {
      identifyUser(authUser?.auth0_id);
      trackEvent('Sign Up', { userId: authUser?.auth0_id });
    }

    navigate(`/onboarding?redirect=${encodeURIComponent(window.location)}`);
  }, [authUser]);

  return isAuthenticated === true ? children : null;
}

export default PrivateRoute;
