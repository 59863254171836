import { Button } from 'react-bootstrap';

function OutputPresetButton({ type, icon, title, caption, selected, onClick }) {
  return (
    <Button variant="light" className={`preset-button ${type} ${selected ? 'selected' : ''}`} onClick={onClick}>
      {icon}
      <div className="preset-button__text">
        {title}
        <br />
        <small>{caption}</small>
      </div>
    </Button>
  );
}

export default OutputPresetButton;
