import { useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { IconOverlay } from '@assets/icons';

import Asset from '@components/asset/Asset';

import { clipState } from '@store/selectors/EditSelectors';

function AssetOverlay({ id }) {
  const videoRef = useRef(null);
  const { ['asset:src']: src } = useRecoilValue(clipState(id));
  const [errorLoading, setLoadingError] = useState(false);

  const handleMouseEnter = () => {
    if (errorLoading) return;
    videoRef.current.play();
  };

  const handleMouseLeave = () => {
    if (errorLoading) return;
    videoRef.current.currentTime = videoRef.current.duration;
    videoRef.current.play().catch(() => {
      videoRef.current.pause();
    });
  };

  const handleLoadError = (event) => {
    setLoadingError(true);
    if (src) {
      console.warn('Error loading overlay:', event);
    }
  };

  return (
    <Asset icon={<IconOverlay />}>
      <video
        key={src}
        ref={videoRef}
        style={{ width: '100%', height: 'auto' }}
        autoPlay
        muted
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onError={handleLoadError}
      >
        <source src={src ? `${src}?x-request=html` : ''} type="video/webm" />
      </video>
    </Asset>
  );
}

export default AssetOverlay;
